import { Stack } from '@mui/material';
import React, { ComponentProps } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

import SeeMoreButton from '../../SeeMore';

interface NotesProps extends ComponentProps<typeof Stack> {
    notes: string;
    onSeeMoreClick?: () => void;
}

const Notes = ({ notes, onSeeMoreClick, ...props }: NotesProps) => (
    <Stack {...props}>
        {/* Using a Box and parent -> child styling to get a larger clickable area */}
        <SingleLineTypography>{notes}</SingleLineTypography>
        <SeeMoreButton onClick={onSeeMoreClick} />
    </Stack>
);

export default Notes;
