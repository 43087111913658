import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NOTIFICATION_TYPE } from '@allie/notifications-common/src/messages';

import { TabKey } from '~/types/dailyTasks.d';

import { selectedTabAtom } from '../../pages/Home/atom';

type NotificationData = {
    navigateTo?: string;
    type?: NOTIFICATION_TYPE;
};

export type NotificationAction = {
    navigateTo?: string;
    additional?: () => Promise<void> | void;
};

/**
 * Must be a component to use atom hooks
 */
const NotificationReceiver = () => {
    const navigate = useNavigate();

    const setSelectedHomeTab = useSetAtom(selectedTabAtom);

    useEffect(() => {
        void addPushNotificationListeners();
    }, []);

    const defaultEcallNotificationAction: NotificationAction = {
        navigateTo: '/home',
        additional: () => setSelectedHomeTab(TabKey.LIVE_CALLS),
    };

    const getNotificationActions = (data: NotificationData) => {
        let actions: NotificationAction = {
            navigateTo: data.navigateTo,
        };

        const actionsByNotificationType: Record<NOTIFICATION_TYPE, NotificationAction> = {
            ECALL_RESIDENT_CALL: defaultEcallNotificationAction,
            ECALL_RESIDENT_CALL_WITH_WAIT_TIME: defaultEcallNotificationAction,
            ECALL_UNKNOWN_RESIDENT_CALL: defaultEcallNotificationAction,
            ECALL_UNKNOWN_RESIDENT_CALL_WITH_WAIT_TIME: defaultEcallNotificationAction,
            ECALL_CLAIM_REMINDER: defaultEcallNotificationAction,
            ECALL_UNKNOWN_RESIDENT_CLAIM_REMINDER: defaultEcallNotificationAction,
            ECALL_CLAIM_ABOVE_THRESHOLD: defaultEcallNotificationAction,
            ECALL_UNKNOWN_RESIDENT_CLAIM_ABOVE_THRESHOLD: defaultEcallNotificationAction,
        };

        if (data.type && actionsByNotificationType[data.type]) actions = actionsByNotificationType[data.type];

        return actions;
    };

    const handleNotificationReceivedForeground = async (notification: PushNotificationSchema) => {
        const { title, body } = notification;
        const data = notification.data as NotificationData;

        const actions = getNotificationActions(data);

        const hasActions = actions && (actions.navigateTo || actions.additional);

        let dialogMessage = body || '';

        if (hasActions) {
            dialogMessage += '\nView related page?';

            const { value: confirmed } = await Dialog.confirm({
                title,
                message: dialogMessage,
                okButtonTitle: 'View',
            });

            if (confirmed) {
                if (actions.navigateTo) navigate(actions.navigateTo);
                if (actions.additional) await actions.additional();
            }
            return;
        }

        await Dialog.alert({
            title,
            message: dialogMessage,
        });
    };

    const handleNotificationClick = async (action: ActionPerformed) => {
        const data = action.notification.data as NotificationData;
        const actions = getNotificationActions(data);
        if (actions.navigateTo) navigate(actions.navigateTo);
        if (actions.additional) await actions.additional();
    };

    const addPushNotificationListeners = async () => {
        // Notification received with the app in foreground
        await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            void handleNotificationReceivedForeground(notification);
        });

        // User interacts with notification in the tray
        await PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
            void handleNotificationClick(action);
        });

        // Remove notifications so they don't pile up
        await App.addListener('appStateChange', ({ isActive }) => {
            if (isActive) void PushNotifications.removeAllDeliveredNotifications();
        });
    };

    return null;
};

export default NotificationReceiver;
