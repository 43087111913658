import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import * as Sentry from '@sentry/react';
import { formatISO, isBefore, subDays } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePermissionsQuery } from '~/api/queries/permissions';
import { useUserDeviceMetadataMutation } from '~/api/queries/user';
import { PUSH_NOTIFICATION_TOKEN_LAST_UPDATED } from '~/constants/localStorage';
import ActivateNotificationsDialog from '~/pages/Signup/ActivateNotificationsDialog';
import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';

import NotificationReceiver from './NotificationReceiver';

const NotificationsHandler = () => {
    const posthog = usePostHog();

    const {
        isSignedIn,
        sessionData: { userId },
    } = useSelector((state: ReduxStore) => state.session);

    const { isSuccess: isPermitLoaded } = usePermissionsQuery();
    const hasPermission = usePermissions();

    const { mutateAsync: mutateUserDeviceMetadata } = useUserDeviceMetadataMutation();

    const [pushNotificationToken, setPushNotificationToken] = useState<string | null>(null);
    const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = useState(false);
    const [askForNotificationPermission, setAskForNotificationPermission] = useState(true);

    // always listen for push notifications so that we don't have to wait
    // for permissions to load to react to a push notification deep link
    useEffect(() => {
        void addPushNotificationListeners();
    }, []);

    useEffect(() => {
        if (isSignedIn && isPermitLoaded && hasPermission('Community', 'receive-notification')) {
            void registerNotifications();
        }
    }, [isSignedIn, isPermitLoaded, hasPermission]);

    useEffect(() => {
        void updateDeviceMetadata();
    }, [pushNotificationToken]);

    const shouldUpdateDeviceMetadata = () => {
        const lastUpdated = localStorage.getItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED);
        if (!lastUpdated) return true;

        const lastUpdatedDate = new Date(lastUpdated);
        if (isBefore(lastUpdatedDate, subDays(new Date(), 7))) {
            return true;
        }

        return false;
    };

    const updateDeviceMetadata = async () => {
        if (!shouldUpdateDeviceMetadata()) {
            return;
        }

        if (pushNotificationToken) {
            const { identifier } = await Device.getId();

            await mutateUserDeviceMetadata({ token: pushNotificationToken, deviceId: identifier });

            const now = formatISO(new Date());
            localStorage.setItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED, now);
        }
    };

    const registerNotifications = async () => {
        const permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'granted') {
            await PushNotifications.register();

            if ((await Device.getInfo()).operatingSystem === 'android') {
                await createAndroidChannels();
            }
        } else if (askForNotificationPermission && permStatus.receive === 'prompt') {
            // If the permission hasn't been accepted or denied yet, ask for it every time the app is opened
            setIsNotificationsDialogOpen(true);
        }
    };

    const addPushNotificationListeners = async () => {
        await PushNotifications.addListener('registration', (token) => {
            setPushNotificationToken(token.value);
        });

        await PushNotifications.addListener('registrationError', () => {
            Sentry.captureMessage('Push notification registration error');
        });
    };

    const createAndroidChannels = async () => {
        await PushNotifications.createChannel({
            id: 'ecall-notification',
            name: 'Urgent eCall Notifications',
            description: 'Receive resident calls and other alerts',
            sound: 'notification_1.wav',
            importance: 4, // Shows everywhere, makes noise and peeks. May use full screen intents. Should bypass DND.
            visibility: 1, // Show this notification in its entirety on all lockscreens.
            vibration: true,
        });
    };

    const toggleNotificationsDialog = () => {
        setIsNotificationsDialogOpen((prev) => !prev);
    };

    const handleActivateNotifications = async () => {
        await PushNotifications.requestPermissions();

        setTimeout(() => {
            const registerNotification = async () => {
                const newPermStatus = await PushNotifications.checkPermissions();

                if (newPermStatus.receive === 'granted') {
                    await PushNotifications.register();
                } else posthog.capture('onboarding:notification_permission_denied', { user_id: userId });
            };

            void registerNotification();
        }, 3000);
        toggleNotificationsDialog();
    };

    const handleNotificationsPermissionCancel = () => {
        toggleNotificationsDialog();
        setAskForNotificationPermission(false);
    };

    return (
        <>
            <ActivateNotificationsDialog
                isOpen={isNotificationsDialogOpen}
                onClose={toggleNotificationsDialog}
                onCancel={handleNotificationsPermissionCancel}
                handleActivateNotifications={handleActivateNotifications}
            />
            <NotificationReceiver />
        </>
    );
};

export default NotificationsHandler;
