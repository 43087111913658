import { Button, Stack, useTheme } from '@mui/material';
import React from 'react';

import { useCanHover } from '~/hooks/useCanHover';

const DataSelector = <T extends string>({
    options,
    value,
    onChange,
}: {
    options: { label: string; value: T }[];
    value: T;
    onChange: (value: T) => void;
}) => {
    const canHover = useCanHover();
    const { palette } = useTheme();

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                gap: '4px',
                width: '100%',
            }}
        >
            {options.map((option) => (
                <Button
                    key={option.value}
                    variant={value === option.value ? 'contained' : 'outlined'}
                    color="grey"
                    onClick={() => onChange(option.value)}
                    sx={{
                        flex: 1,
                        ...(!canHover && {
                            '&:hover': { backgroundColor: value === option.value ? palette.grey[900] : 'white' },
                        }),
                    }}
                >
                    {option.label}
                </Button>
            ))}
        </Stack>
    );
};

export default DataSelector;
