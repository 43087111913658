import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchId } from '~/api/common';
import { useBranchShifts } from '~/api/queries/branch';
import { useCreateShiftRatingMutation } from '~/api/queries/shiftRating/shiftRating';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

import { ShiftRatingDialogSteps } from '../shiftRatingDialogSteps';
import { additionalCommentAtom, shiftRatingAtom, shiftRatingStepAtom } from '../state/atom';

const STEPS_SEQUENCE = [
    ShiftRatingDialogSteps.SELECT_RATING,
    ShiftRatingDialogSteps.ADDITIONAL_COMMENT_BOX,
    ShiftRatingDialogSteps.CONFETTI,
];

export default function useShiftRatingDialog() {
    const [step, setStep] = useAtom(shiftRatingStepAtom);
    const [shiftRating, setShiftRating] = useAtom(shiftRatingAtom);
    const [additionalComment, setAdditionalComment] = useAtom(additionalCommentAtom);
    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { timezone } = useSelector((state: ReduxStore) => state.session);
    const branchId = useBranchId();
    const shifts = useBranchShifts(branchId);
    const { shift, shiftDayDate } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);
    const { mutateAsync: createShiftRating, isPending: isCreateShiftRatingLoading } = useCreateShiftRatingMutation({
        userId,
        shiftId: Number(shift.id),
        shiftDayDate,
    });

    const resetShiftRatingFlow = useCallback(() => {
        setStep(ShiftRatingDialogSteps.SELECT_RATING);
        setShiftRating(null);
        setAdditionalComment('');
    }, [setStep, setAdditionalComment]);

    const isFirstStep = step === STEPS_SEQUENCE[0];
    const isLastStep = step === STEPS_SEQUENCE[STEPS_SEQUENCE.length - 1];

    const getCurrentStepIndex = () => STEPS_SEQUENCE.indexOf(step);

    const goToNextStep = useCallback(() => {
        const currentStepIndex = getCurrentStepIndex();
        const nextStep = currentStepIndex === STEPS_SEQUENCE.length - 1 ? null : STEPS_SEQUENCE[currentStepIndex + 1];

        if (nextStep) {
            setStep(nextStep);
        }
    }, [step, setStep]);

    const goBackOneStep = useCallback(() => {
        const currentStepIndex = getCurrentStepIndex();
        const previousStep = currentStepIndex === 0 ? null : STEPS_SEQUENCE[currentStepIndex - 1];

        if (previousStep) {
            setStep(previousStep);
        }
    }, [step, setStep]);

    const progress = useMemo(() => {
        const currentStep = getCurrentStepIndex();
        const totalSteps = STEPS_SEQUENCE.length - 1; // removing confetti step

        return (currentStep / totalSteps) * 100 + 50; // start from 50%
    }, [step]);

    const onSubmit = useCallback(async () => {
        if (!shiftRating) {
            showToast({
                message: 'Please select a rating.',
                type: 'error',
            });
            return;
        }
        try {
            const data = {
                notes: additionalComment,
                rating: shiftRating.value,
            };

            await createShiftRating(data);

            showToast({
                message: 'Shift rating submitted successfully.',
                type: 'success',
            });

            goToNextStep();
        } catch (error) {
            Sentry.captureException(error);
            showToast({
                message: 'Failed to submit a shift rating.',
                type: 'error',
            });
        }
    }, [goToNextStep, additionalComment]);

    return {
        additionalComment,
        goBackOneStep,
        goToNextStep,
        isFirstStep,
        isLastStep,
        isSubmitPending: isCreateShiftRatingLoading,
        onSubmit,
        progress,
        resetShiftRatingFlow,
        setAdditionalComment,
        setShiftRating,
        setStep,
        shiftRating,
        step,
    };
}
