import { Button, Stack, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { PiClock } from 'react-icons/pi';

import CustomModal from '~/components/Custom/CustomModal';
import { Placeholder } from '~/components/Shared/Placeholder';

import { useShiftAboutToEnd } from '../notification/useShiftAboutToEnd';

interface EndOfShiftWarningModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ListItem = styled(Typography)<{ component?: React.ElementType }>(({ theme: { palette } }) => ({
    color: palette.grey[900],
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
}));

export const EndOfShiftWarningModal = ({ isOpen, onClose }: EndOfShiftWarningModalProps) => {
    const { endOfShiftTime } = useShiftAboutToEnd();

    const title = useMemo(() => {
        const sharedPiece = 'Go through your handoff routine';

        if (!endOfShiftTime) {
            return `Your shift is ending in 15 minutes. ${sharedPiece}`;
        }

        const diffInMilliseconds = endOfShiftTime.diffNow().milliseconds;
        const diffInMinutes = diffInMilliseconds / 60000;

        if (diffInMinutes < 1 && diffInMinutes >= 0) {
            return `Your shift is ending soon. ${sharedPiece}`;
        } else if (diffInMinutes > -1 && diffInMinutes <= 0) {
            return `Your shift just ended. ${sharedPiece}`;
        }

        if (diffInMinutes < -1) {
            return `Your shift ended ${endOfShiftTime.toRelative()}. ${sharedPiece}`;
        }

        return `Your shift is ending ${endOfShiftTime.toRelative()}. ${sharedPiece}`;
    }, [endOfShiftTime]);

    return (
        <CustomModal isOpen={isOpen} onClose={onClose} center>
            <Stack gap="24px" padding="24px">
                <Placeholder
                    title={title}
                    icon={PiClock}
                    type="secondary"
                    size="small"
                    description={
                        <ul style={{ paddingInlineStart: '20px' }}>
                            <ListItem component="li">Tasks of the day</ListItem>
                            <ListItem component="li">Documentation</ListItem>
                            <ListItem component="li">Pass off any devices</ListItem>
                            <ListItem component="li">Situate the next caregiver on shift</ListItem>
                        </ul>
                    }
                    titleProps={{ sx: { textAlign: 'center', textWrap: 'balance', lineHeight: '24px' } }}
                    descriptionProps={{ sx: { textAlign: 'start', width: '100%' } }}
                />
                <Button onClick={onClose}>Done</Button>
            </Stack>
        </CustomModal>
    );
};
