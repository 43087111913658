import { Box, alpha, styled, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import { selectedDateAtom, selectedPeriodAtom } from '~/pages/OperationsV2/atoms';
import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataLegend from '~/pages/OperationsV2/components/Module/Section/DataLegend';
import DataSelector from '~/pages/OperationsV2/components/Module/Section/DataSelector';
import DataSegmentedBarChart, {
    DataSegmentedBarChartColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/recharts/DataSegmentedBarChart';
import { formatDateXAxisLabel } from '~/pages/OperationsV2/components/Module/Section/recharts/shared';

type Data = SectionDataType<'BRANCH', 'CALL_BY_DETAIL_AND_RESIDENT', 'CALL_COUNT'>;
type Item = Data['detail']['items'][number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 0 12px',
    borderRadius: '16px',
}));

type SelectorOptionValue = 'detail' | 'resident';

const selectorOptions: { label: string; value: SelectorOptionValue }[] = [
    { label: 'Detail', value: 'detail' },
    { label: 'Resident', value: 'resident' },
];

const Data = ({ data }: { data: Data }) => {
    const { palette } = useTheme();

    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

    const [selectedOption, setSelectedOption] = useState<SelectorOptionValue>('detail');
    const selectedPeriod = useAtomValue(selectedPeriodAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const labelByDataKey = useMemo(
        () =>
            ({
                emergencyCount: 'emergency',
                regularCount: 'regular',
            }) satisfies { [key in keyof Item]?: string },
        []
    );

    const renderTooltipLabel = useCallback(
        (item: Item, dataKey: keyof Item) =>
            // e.g. '10 emergency calls' when hovering
            item[dataKey] ? `${item[dataKey]} ${labelByDataKey[dataKey]} calls` : null,
        []
    );

    const isSelected = useCallback(
        (item: Item) => DateTime.fromISO(item.date).hasSame(selectedDate, 'day'),
        [selectedDate]
    );

    const renderXAxisLabel = useCallback(
        (item: Item) => {
            const date = DateTime.fromISO(item.date) as DateTime<true>;
            return formatDateXAxisLabel(selectedPeriod, date, isSelected(item));
        },
        [isSelected]
    );

    const colorMapping: DataSegmentedBarChartColorMapping = useMemo(
        () => ({
            selected: [palette.primary[500] as string, palette.primary[300] as string],
            unselected: [alpha(palette.grey[900], 0.2), alpha(palette.grey[900], 0.1)],
        }),
        [palette]
    );

    return (
        <DataContainer>
            <DataSelector
                options={selectorOptions}
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
            />
            <ChartContainer ref={setContainerRef}>
                <DataSegmentedBarChart
                    width={containerRef?.clientWidth ?? 0}
                    height={200}
                    items={data.detail.items}
                    xAxisDataKey="date"
                    yAxisDataKeys={['emergencyCount', 'regularCount']}
                    renderXAxisLabel={renderXAxisLabel}
                    renderTooltipLabel={renderTooltipLabel}
                    colorMapping={colorMapping}
                    isSelected={isSelected}
                />
            </ChartContainer>
            <DataLegend
                rows={
                    selectedOption === 'detail'
                        ? [
                              { label: 'Emergency / Health Related', values: [data.detail.emergencyCount] },
                              { label: 'Other', values: [data.detail.regularCount] },
                          ]
                        : [
                              {
                                  label: 'Top 10 Hitters',
                                  values: [data.resident.top10],
                              },
                              { label: 'Other Residents', values: [data.resident.other] },
                          ]
                }
                colorMapping={colorMapping.selected}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
