import { atom } from 'jotai';

import { atomWithStorage, atomWithToggle } from '~/state/atomUtils';
import { ResidentResponse } from '~/types/residents';

import { ECall } from './types';

export const documentationResidentDataAtom = atom<Pick<
    ResidentResponse,
    'residentId' | 'firstName' | 'lastName' | 'photo'
> | null>(null);

export const isAssignmentsModalOpenAtom = atomWithToggle(false);
export const selectedCallIdAtom = atom<number | null>(null);

export const isOnCurrentShiftAtom = atomWithStorage('ecall_isOnCurrentShift', false);

// TODO - remove this and uncomment the line 23 after Jessie's demo
export const nextShiftVerificationTimeAtom = atom<string | null>(null);
// export const nextShiftVerificationTimeAtom = atomWithStorage<string | null>('ecall_nextShiftVerificationTime', null);
export const currentShiftEndsAtAtom = atomWithStorage<string | null>('ecall_currentShiftEndsAt', null);

export const ecallSortAtom = atomWithStorage<ECall.SortingKey>('ecall_sort', 'callOrder');

export const isSSEConnectedAtom = atom(false);
