import * as Sentry from '@sentry/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useMemo } from 'react';

import { PRN_CATEGORY_SECTIONS, PRN_TASK_CATEGORY_CODES } from '@allie/utils/src/constants/prn-tasks.constants';

import { useCreateUnscheduledTaskMutation } from '~/api/queries/unscheduledTasks/createUnscheduledTask';
import { useGetCategoriesDetailsQuery } from '~/api/queries/unscheduledTasks/getCategoriesDetails';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { selectedDateAtom } from '~/pages/Home/atom';
import { generalComment } from '~/pages/Home/components/NewUnscheduledTaskDialog/steps/additionalCommentBox/utils/generalComment';
import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';
import { ResidentResponse } from '~/types/residents';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

import { useRandomSuccessMessage } from '../../../useRandomSuccessMessage';
import {
    additionalCommentAtom,
    unscheduledTaskCategoriesAtom,
    unscheduledTaskDetailsAtom,
    unscheduledTaskIsMissingRequiredCommentAtom,
    unscheduledTaskResidentAtom,
    unscheduledTaskShiftIdAtom,
    unscheduledTaskStepAtom,
} from '../state/atom';
import { PrnTaskCategories } from '../steps/category/types/prnTaskCategories';

import { useDuplicateSteps } from './useDuplicateSteps';
import { useEmergencyFlow } from './useEmergencyFlow';

const STEPS_SEQUENCE = [
    UnscheduledTaskDialogSteps.SELECT_RESIDENT,
    UnscheduledTaskDialogSteps.SELECT_CATEGORY,
    UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS,
    UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX,
    UnscheduledTaskDialogSteps.CONFETTI,
];

export default function useUnscheduledTaskDialog() {
    const { resetSeed } = useRandomSuccessMessage('DOCUMENTATION_FLOW');
    const isFraudConfirmed = useFeatureFlagEnabled('fraud-confirmed-staff');
    const { isEmergencyFlow, isEmergencyStep } = useEmergencyFlow();
    const {
        duplicateStepCounter,
        handledDuplicatedSteps,
        setCounter,
        shouldDuplicateStep,
        resetCounter,
        isDuplicatedStep,
        setHandledDuplicatedSteps,
    } = useDuplicateSteps();

    const [step, setStep] = useAtom(unscheduledTaskStepAtom);
    const [shiftId, setShiftId] = useAtom(unscheduledTaskShiftIdAtom);
    const [resident, setResident] = useAtom(unscheduledTaskResidentAtom);
    const [categories, setCategories] = useAtom(unscheduledTaskCategoriesAtom);
    const [taskDetails, setTaskDetails] = useAtom(unscheduledTaskDetailsAtom);
    const [additionalComment, setAdditionalComment] = useAtom(additionalCommentAtom);
    const setIsMissingRequiredComment = useSetAtom(unscheduledTaskIsMissingRequiredCommentAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const categoriesIds = useMemo(() => categories.map((category) => category.id), [categories]);
    const { data: categoriesData } = useGetCategoriesDetailsQuery({ categoriesIds, residentId: resident?.residentId });
    const { mutateAsync: createUnscheduledTask, isPending: isCreateUnscheduledTaskLoading } =
        useCreateUnscheduledTaskMutation({ residentId: resident?.residentId });

    const categoriesFromSectionOther = useMemo(
        () => categories.filter((category) => category.section === PRN_CATEGORY_SECTIONS.OTHER),
        [categories]
    );

    const isValid = useCallback((): boolean => {
        if (step === UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS) {
            if (isEmergencyStep(step)) {
                const details = taskDetails.find(
                    (details) => details.categoryCode === PRN_TASK_CATEGORY_CODES.EMERGENCY
                );

                return Boolean(details?.activitiesIds.length);
            }

            const currentCategory = categories.filter((category) => category.section !== PRN_CATEGORY_SECTIONS.OTHER)[
                duplicateStepCounter
            ];
            const details = currentCategory
                ? taskDetails.find((details) => details.categoryId === currentCategory.id)
                : undefined;

            const isActivitiesValid = !details?.activitiesRequired || details.activitiesIds.length > 0;

            return Boolean(details?.assistLevelId && details.subcategoryId && isActivitiesValid);
        }

        if (step === UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX) {
            if (!categoriesFromSectionOther.length && !isFraudConfirmed && categories.length === 1) return true;

            let currentCategory: PrnTaskCategories.Category;

            if (!categoriesFromSectionOther.length && (isFraudConfirmed || categories.length > 1)) {
                currentCategory = generalComment;
            } else if (categoriesFromSectionOther.length - 1 < duplicateStepCounter) {
                // generic comment step at the flow's end when there are categories outside AND
                // from the `Other` section
                return true;
            } else {
                currentCategory = categoriesFromSectionOther[duplicateStepCounter];
            }

            const comment = additionalComment.find((comment) => comment.categoryId === currentCategory?.id)?.comment;

            return !!comment;
        }

        return Boolean(
            !isCreateUnscheduledTaskLoading &&
                ((step === UnscheduledTaskDialogSteps.SELECT_RESIDENT && resident) ||
                    (step === UnscheduledTaskDialogSteps.SELECT_CATEGORY && categories.length > 0) ||
                    step === UnscheduledTaskDialogSteps.CONFETTI)
        );
    }, [
        resident,
        taskDetails,
        step,
        additionalComment,
        isCreateUnscheduledTaskLoading,
        categories,
        categoriesFromSectionOther,
        duplicateStepCounter,
        isEmergencyStep,
    ]);

    const resetFlow = useCallback(
        (residentData?: Pick<ResidentResponse, 'residentId' | 'firstName' | 'lastName' | 'photo'>) => {
            if (residentData) {
                setStep(UnscheduledTaskDialogSteps.SELECT_CATEGORY);
                setResident(residentData);
            } else {
                setStep(UnscheduledTaskDialogSteps.SELECT_RESIDENT);
                setResident(null);
            }

            setCategories([]);
            setTaskDetails([]);
            setIsMissingRequiredComment(false);
            setAdditionalComment([]);
            resetSeed();
            resetCounter();
            setHandledDuplicatedSteps(0);
        },
        [
            setStep,
            setResident,
            setCategories,
            setTaskDetails,
            setIsMissingRequiredComment,
            setAdditionalComment,
            resetSeed,
            resetCounter,
            setHandledDuplicatedSteps,
        ]
    );

    const goToNextStep = useCallback(() => {
        if (shouldDuplicateStep('next')) {
            // The current step itself must handle the new state
            return setCounter('next');
        }

        const currentStepIndex = STEPS_SEQUENCE.indexOf(step);
        const nextStep = currentStepIndex === STEPS_SEQUENCE.length - 1 ? null : STEPS_SEQUENCE[currentStepIndex + 1];

        if (nextStep) {
            // skip comment step if is Emergency flow
            if (nextStep === UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX && isEmergencyFlow) {
                return setStep(UnscheduledTaskDialogSteps.CONFETTI);
            }

            // skip task details step if all categories are from section `Other`
            if (
                nextStep === UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS &&
                categoriesFromSectionOther.length === categories.length
            ) {
                return setStep(UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX);
            }

            setStep(nextStep);

            if (isDuplicatedStep(nextStep)) {
                resetCounter();
            }
        }
    }, [
        step,
        categoriesFromSectionOther,
        categories,
        setStep,
        shouldDuplicateStep,
        isDuplicatedStep,
        resetCounter,
        setCounter,
    ]);

    const goBackOneStep = useCallback(() => {
        if (shouldDuplicateStep('previous')) {
            // The current step itself must handle the new state
            return setCounter('previous');
        }

        const currentStepIndex = STEPS_SEQUENCE.indexOf(step);
        const previousStep = currentStepIndex === 0 ? null : STEPS_SEQUENCE[currentStepIndex - 1];

        if (previousStep) {
            // skip task details step if all categories are from section `Other`
            if (
                previousStep === UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS &&
                categoriesFromSectionOther.length === categories.length
            ) {
                return setStep(UnscheduledTaskDialogSteps.SELECT_CATEGORY);
            }

            setStep(previousStep);

            if (isDuplicatedStep(previousStep)) {
                resetCounter(previousStep, 'previous');
            }
        }
    }, [
        step,
        categoriesFromSectionOther,
        categories,
        setStep,
        shouldDuplicateStep,
        isDuplicatedStep,
        resetCounter,
        setCounter,
    ]);

    const getCurrentStepIndex = useCallback(() => {
        if (isEmergencyFlow && step !== UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX) {
            // skip comment step if is Emergency flow
            return STEPS_SEQUENCE.filter((step) => step !== UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX).indexOf(
                step
            );
        }

        if (
            categoriesFromSectionOther.length === categories.length &&
            step !== UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS
        ) {
            // skip task details step if all categories are from section `Other`
            return STEPS_SEQUENCE.filter((step) => step !== UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS).indexOf(
                step
            );
        }

        return STEPS_SEQUENCE.indexOf(step);
    }, [step, categories]);

    const progress = useMemo(() => {
        const stepIndex = getCurrentStepIndex();
        const currentStep = stepIndex + handledDuplicatedSteps;

        const singleStepsQuantity = STEPS_SEQUENCE.length - 1; // removing confetti step

        let totalSteps = 0;

        if (!categories.length) {
            totalSteps = singleStepsQuantity;
        } else if (categoriesFromSectionOther.length === categories.length) {
            // when all categories are from section `Other`
            // we need to skip the task details step
            const singleStepsQuantityWithoutAssistLevel = singleStepsQuantity - 1;
            const duplicatedCommentStepsQuantity = categoriesFromSectionOther.length - 1;
            totalSteps = singleStepsQuantityWithoutAssistLevel + duplicatedCommentStepsQuantity;
        } else if (categoriesFromSectionOther.length > 0) {
            // when there are categories from section `Other` and
            // categories with task details step, we need to consider both
            const categoriesWithAssistLevel = categories.length - categoriesFromSectionOther.length;
            const duplicatedCommentStepsQuantity = categoriesFromSectionOther.length; // including the generic comment step
            totalSteps = singleStepsQuantity + (categoriesWithAssistLevel - 1) + duplicatedCommentStepsQuantity;
        } else if (isEmergencyFlow) {
            // in this case, only the Emergency category is selected
            // so the comment step will be skipped
            totalSteps = singleStepsQuantity - 1; // removing comment step
        } else {
            // when there are no categories from section `Other`
            // the comment step will not be duplicated
            const duplicatedStepsQuantity = categories.length;

            totalSteps = singleStepsQuantity + (duplicatedStepsQuantity - 1);
        }

        return (currentStep / totalSteps) * 100;
    }, [step, categories, categoriesFromSectionOther, handledDuplicatedSteps]);

    const prnTasks = useMemo<UnscheduledTasks.CreateUnscheduledTaskParams[]>(() => {
        return categories.map((category) => {
            const details = taskDetails.find((detail) => detail.categoryId === category.id);
            const isOtherSection = category.section === PRN_CATEGORY_SECTIONS.OTHER;
            const categoryDetails = isOtherSection ? categoriesData?.find(({ id }) => id === category.id) : undefined;

            return {
                residentId: resident!.residentId,
                branchShiftId: shiftId ?? undefined,
                shiftDay: (selectedDate ?? DateTime.now()).toFormat('yyyy-MM-dd'),
                notes:
                    additionalComment.find((comment) =>
                        isOtherSection ? comment.categoryId === category.id : comment.categoryId === generalComment.id
                    )?.comment ?? '',
                prnActivitiesIds:
                    details && details.activitiesIds.length === 1 && details.activitiesIds[0] === 0
                        ? []
                        : (details?.activitiesIds ?? []),
                prnAssistLevelId: isOtherSection
                    ? categoryDetails?.subcategories[0].assistLevels[0].id
                    : details?.assistLevelId,
                prnCategoryId: category.id,
                prnSubcategoryId: isOtherSection ? categoryDetails?.subcategories[0].id : details?.subcategoryId,
            };
        });
    }, [
        categories,
        categoriesFromSectionOther,
        additionalComment,
        taskDetails,
        categoriesData,
        selectedDate,
        shiftId,
        resident,
    ]);

    const onSubmit = useCallback(
        async (callId?: number) => {
            try {
                if (!isValid()) {
                    return;
                }

                const data = callId ? prnTasks.map((param) => ({ ...param, callId })) : prnTasks;

                await createUnscheduledTask(data);

                showToast({
                    message: 'Unscheduled task submitted successfully.',
                    type: 'success',
                });
                // forward to confetti step
                goToNextStep();
            } catch (error) {
                Sentry.captureException(error, { extra: { prnTasks } });
                showToast({
                    message: 'Failed to submit unscheduled task, please try again.',
                    type: 'error',
                });
            }
        },
        [goToNextStep, showToast, createUnscheduledTask, isValid, prnTasks]
    );

    return {
        step,
        setStep,
        goBackOneStep,
        goToNextStep,
        shiftId,
        setShiftId,
        resident,
        setResident,
        isValid,
        resetFlow,
        progress,
        onSubmit,
        isSubmitPending: isCreateUnscheduledTaskLoading,
    };
}
