import AvgResponseTime from './AvgResponseTime';
import ECallDocumentationRate from './ECallDocumentationRate';
import FallDocumentationIncomplete from './FallDocumentationIncomplete';
import Incidents from './Incidents';
import LongestResponseTime from './LongestResponseTime';
import Medication from './Medication';
import OverdueAssessments from './OverdueAssessments';
import PotentialUnrecordedFalls from './PotentialUnrecordedFalls';
import ScheduledDocumentationRate from './ScheduledDocumentationRate';
import TotalCalls from './TotalCalls';
import Transfers from './Transfers';

export default {
    AvgResponseTime,
    LongestResponseTime,
    TotalCalls,
    Incidents,
    Medication,
    Transfers,
    ScheduledDocumentationRate,
    ECallDocumentationRate,
    OverdueAssessments,
    FallDocumentationIncomplete,
    PotentialUnrecordedFalls,
};
