import { DateTime } from 'luxon';
import moment from 'moment';

import { ShiftOption } from '@allie/utils/src/shifts';

import { ALL_LOCATIONS_ID } from '~/constants/filters';
import { DailyTasksShiftDetail, ResidentTasks } from '~/types/dailyTasks';

interface FilterTasksByNameParams {
    tasks: DailyTasksShiftDetail[];
    searchValue: string;
    filterBy: 'zoneId' | 'assignmentId' | 'careLocationId';
    locationIds: number[];
}

export function filterTasksByName(params: FilterTasksByNameParams): DailyTasksShiftDetail[] {
    const { searchValue, tasks, filterBy, locationIds } = params;

    if (!searchValue) return tasks;

    const searchInLowerCase = searchValue.toLowerCase();

    return tasks.filter((task) => {
        const residentFullName = `${task.resident.firstName} ${task.resident.lastName}`.toLowerCase();

        const taskIncludesNameOfResident =
            task.resident.firstName.toLowerCase().includes(searchInLowerCase) ||
            task.resident.lastName.toLowerCase().includes(searchInLowerCase) ||
            residentFullName.includes(searchInLowerCase);

        const taskIncludesRoomOfResident = task.resident.roomNumber?.toLowerCase().includes(searchInLowerCase);

        let taskIncludesLocationId = false;
        if (locationIds.includes(ALL_LOCATIONS_ID)) {
            taskIncludesLocationId = true;
        } else if (filterBy === 'zoneId') {
            taskIncludesLocationId = locationIds.includes(task.zoneId);
        } else if (filterBy === 'assignmentId') {
            taskIncludesLocationId = locationIds.includes(task.assignmentId);
        } else if (filterBy === 'careLocationId') {
            taskIncludesLocationId = true;
        }

        return (taskIncludesNameOfResident || taskIncludesRoomOfResident) && taskIncludesLocationId;
    });
}

export function groupTasksByResidentId(tasks: DailyTasksShiftDetail[]): Record<number, ResidentTasks> {
    const residentsTasks: Record<number, ResidentTasks> = {};

    tasks.forEach((task) => {
        if (!residentsTasks[task.resident.id]) {
            residentsTasks[task.resident.id] = {
                ...task.resident,
                tasks: [],
            };
        }

        residentsTasks[task.resident.id].tasks.push(task);
    });

    return residentsTasks;
}

// Using strings to not break local/demo builds with mismatched ids
const companiesWithAssignments = ['Solera', 'Sunny Hills', 'Sodalis Senior Living', 'App Store Sandbox'];
const branchesWithAssignments = [
    // Dev
    'AllieHealth',

    // Solera
    'Lake Austin',
    'Evanston',
    'Clearwater',
    // 'Cherry Creek',
    // 'Edgeworth', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1720524571455549
    // 'Kensington', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1720466514765959
    // 'Lumina', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1721412180173189
    'Hunters Woods',

    // Sunny Hills
    // 'Grossmont',

    // Sodalis
    'Sodalis - Tallahassee', // https://alliehealthworkspace.slack.com/archives/C07L3KHT7T8/p1731609269311729
    'Sodalis - Largo', // https://alliehealthworkspace.slack.com/archives/C07L3KHT7T8/p1731609269311729
];

// TODO: Move to a community settings structure
export const shouldUseAssignments = (company: string, branch: string) =>
    // Testing both company and branch names to avoid false positives
    companiesWithAssignments.some((c) => company.includes(c)) &&
    branchesWithAssignments.some((b) => branch.includes(b));

export const taskRecordSorter = (a: DailyTasksShiftDetail, b: DailyTasksShiftDetail) => {
    const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
    const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
    if (aDateTime.isBefore(bDateTime)) {
        return -1;
    }

    if (aDateTime.isAfter(bDateTime)) {
        return 1;
    }

    return 0;
};

export const isShiftInDate = (shift: ShiftOption, date: DateTime | null) => {
    if (!date) return false;

    const now = DateTime.now();

    // Parse the shift start and end times
    const shiftStart = DateTime.fromISO(`${date.toISODate()}T${shift.startTimeInclusive}`);
    let shiftEnd = DateTime.fromISO(`${date.toISODate()}T${shift.endTimeExclusive}`);

    // If the shift ends past midnight, adjust the end time
    if (shiftEnd < shiftStart) {
        // Shift crosses midnight, so adjust the end time to the next day
        shiftEnd = shiftEnd.plus({ days: 1 });
    }

    // Check if the current time falls within the shift's time window
    return now >= shiftStart && now < shiftEnd;
};
