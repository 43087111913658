import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { OperationsDetails } from '~/types/operationsV2/operationsDetails';

export const useGetDetailsData = (params?: OperationsDetails.Params) => {
    const token = useToken();

    const validateParams =
        params?.level === 'REGION'
            ? !!params?.regionId
            : params?.level === 'BRANCH'
              ? !!params?.branchId
              : !!params?.companyId;

    const queryFn = useCallback(async () => {
        const { data } = await api.get<OperationsDetails.Response>('/analytics/details', {
            headers: {
                authorization: token,
            },
            params,
        });

        return data.response;
    }, [token, params]);

    return useErrorHandledQuery({
        queryKey: ['operations-details-data', params],
        queryFn,
        enabled: !!params && validateParams,
        staleTime: 100000,
    });
};
