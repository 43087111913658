import { Box, alpha, styled } from '@mui/material';
import React, { ComponentProps } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';
import { HoverableProps } from '~/pages/OperationsV2/components/Module/shared';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

const ShowMoreContainer = styled(
    Box,
    ignoreProps<HoverableProps>('canHover')
)<HoverableProps>(({ theme: { palette }, canHover }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover p': canHover && { color: alpha(palette.grey[900], 0.8) },
    '&:active p': { color: alpha(palette.grey[900], 0.5) },
}));

const ShowMoreText = styled(SingleLineTypography)({
    // Instead of text-decoration to add some padding
    borderBottom: '1px solid',
    borderColor: 'inherit',
    transition: 'color 0.1s, border-color 0.1s',
});

interface ShowMoreButtonProps extends ComponentProps<typeof Box> {
    title: string;
}

const ShowMoreButton = ({ title, ...props }: ShowMoreButtonProps) => {
    const canHover = useCanHover();

    return (
        // Using a Box and parent -> child styling to get a larger clickable area
        <ShowMoreContainer {...props} canHover={canHover}>
            <ShowMoreText variant="body2">{title}</ShowMoreText>
        </ShowMoreContainer>
    );
};

export default ShowMoreButton;
