import { useSelector } from 'react-redux';

import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchId } from '~/api/common';
import { useBranchShifts } from '~/api/queries/branch';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

// this hook calculates the correct date to get the list of assignments/zones
// when the selected shift starts on a different day than the selected `shiftDay`
export const useShiftStartDay = (shiftDay?: string, shiftId?: number) => {
    const { timezone } = useSelector((state: ReduxStore) => state.session);
    const branchId = useBranchId();

    const shifts = useBranchShifts(branchId);

    if (!shiftId || !shiftDay) {
        // as the hook can't be called conditionally, it must accepts nullable parameters and this
        // statement ensures that the hook will be executed only when the parameters are defined
        return;
    }

    const { shift: currentShift, shiftDayDate: currentShiftDayDate } = getShiftAtDateTimeUtc(
        getDateInUtc(new Date()),
        timezone,
        shifts
    );
    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const selectedShift = nearbyShifts.find(({ shift }) => shift.id === shiftId)!;

    // if the selected shiftDay is today and the selected shift starts on a different day,
    // return the start day of the selected shift
    if (
        selectedShift &&
        shiftDay === currentShiftDayDate &&
        selectedShift.shift.id !== currentShift.id &&
        selectedShift.shiftDayDate !== shiftDay
    ) {
        return selectedShift.shiftDayDate;
    }

    return shiftDay;
};
