import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { PiBellBold, PiMapPinBold } from 'react-icons/pi';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import CustomDrawer from '~/components/Custom/CustomDrawer';
import { ALL_LOCATIONS_ID } from '~/constants/filters';
import { TabKey } from '~/types/dailyTasks.d';

import { locationIdsAtom } from '../../atom';
import { isOnCurrentShiftAtom } from '../../eCallComponents/atom';
import { useShiftAboutToEnd } from '../../eCallComponents/notification/useShiftAboutToEnd';
import { useShiftVerification } from '../../eCallComponents/notification/useShiftVerification';

import { LocationSelector } from './LocationSelector';

interface LocationSelectorDialogProps {
    isOpen: boolean;
    selectedTab: TabKey;
    onClose: () => void;
    selectedShiftId?: number;
    selectedShiftDay?: string;
}

export const LocationSelectorDialog = ({
    isOpen,
    selectedTab,
    onClose,
    selectedShiftId,
    selectedShiftDay,
}: LocationSelectorDialogProps) => {
    const { palette } = useTheme();
    const [locationIds, setLocationIds] = useAtom(locationIdsAtom);
    const { updateNextShiftVerificationTime } = useShiftVerification();
    const { updateCurrentShiftEndTime } = useShiftAboutToEnd();
    const [isOnCurrentShift, setIsOnCurrentShift] = useAtom(isOnCurrentShiftAtom);

    const { data: careLocationsIds } = useGetChosenCareLocations(selectedTab === TabKey.LIVE_CALLS);
    const { mutateAsync: saveChosenAssignments, isPending } = useSaveChosenCareLocations();

    const Icon = selectedTab === TabKey.LIVE_CALLS ? PiBellBold : PiMapPinBold;
    const defaultValue = selectedTab === TabKey.LIVE_CALLS ? careLocationsIds : locationIds;

    const handleSubmit = async (selectedIds: number[]) => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            await saveChosenAssignments(selectedIds.filter((id) => id !== ALL_LOCATIONS_ID));

            // turning on e-call notifications
            if (!isOnCurrentShift) {
                setIsOnCurrentShift(true);
                updateNextShiftVerificationTime();
                updateCurrentShiftEndTime();
            }
        } else {
            setLocationIds(selectedIds);
        }

        onClose();
    };

    return (
        <CustomDrawer
            isOpen={isOpen}
            onClose={onClose}
            closeButton
            sx={{ display: 'flex', flexDirection: 'column' }}
            header={
                <>
                    <Box padding="4px">
                        <Icon size={24} color={palette.secondary.main} />
                    </Box>
                    <Typography variant="h6" fontSize="16px" color={palette.grey[900]} letterSpacing="unset">
                        {selectedTab === TabKey.LIVE_CALLS ? 'Live Call Notifications' : 'Filter'}
                    </Typography>
                </>
            }
        >
            <Stack sx={{ paddingBottom: 'env(safe-area-inset-bottom)', height: '100%' }}>
                <LocationSelector
                    onSubmit={handleSubmit}
                    defaultValue={defaultValue}
                    isLoading={isPending}
                    selectedShiftDay={selectedShiftDay}
                    selectedShiftId={selectedShiftId}
                />
            </Stack>
        </CustomDrawer>
    );
};
