import { Skeleton, Stack, useTheme } from '@mui/material';
import { range } from 'lodash';
import React from 'react';

export const LoadingCalls = () => {
    const { palette } = useTheme();

    return range(4).map((index) => (
        <Stack
            key={index}
            direction="row"
            gap="12px"
            padding="16px"
            alignItems="center"
            borderBottom={`1px solid ${palette.grey[50]}`}
        >
            <Skeleton variant="circular" height="64px" width="64px" />
            <Stack flex={1} gap="4px">
                <Stack flex={1} gap="2px">
                    <Skeleton variant="text" height="20px" width="100px" />
                    <Skeleton variant="text" height="20px" width="50px" />
                </Stack>
                <Skeleton variant="rounded" height="35px" width="100%" />
            </Stack>
            <Skeleton variant="rectangular" height="20px" width="20px" />
        </Stack>
    ));
};
