import { Box, Divider, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import range from 'lodash/range';
import React, { useMemo } from 'react';
import { PiRssSimple } from 'react-icons/pi';

import { CALL_TASK_FLAG } from '@allie/utils/src/constants/ecall/call.constants';
import { isNotNullish } from '@allie/utils/src/utils/type-guards';

import { useGetDocumentedCallDetails } from '~/api/queries/call/getDocumentedCallDetails';
import { CustomBottomSheet } from '~/components/Custom/CustomBottomSheet';
import { DocumentedCallTask } from '~/types/call/call';

import { formatTimeDiff } from './helpers';

const LABEL_BY_TASK_FLAG: Record<CALL_TASK_FLAG, string> = {
    [CALL_TASK_FLAG.SCHEDULED]: 'Scheduled',
    [CALL_TASK_FLAG.ASSIST_LEVEL_CHANGE]: 'Assist Level Change',
    [CALL_TASK_FLAG.OTHER]: 'Other',
};

const CategoryData = ({ category }: { category: DocumentedCallTask }) => {
    const { palette } = useTheme();

    const legend = [category.subcategory, category.assistLevel, ...(category.activities ?? [])]
        .filter(isNotNullish)
        .join(', ');

    return (
        <Stack gap="6px">
            <Box
                display="flex"
                alignItems="center"
                bgcolor={palette.primary[300] as string}
                borderRadius="8px"
                padding="1px 4px"
                width="fit-content"
            >
                <Typography variant="caption" fontWeight={600} fontSize="10px">
                    {LABEL_BY_TASK_FLAG[category.flag]}
                </Typography>
            </Box>
            <Typography variant="body2" fontWeight={600}>
                {category.category}
            </Typography>
            <Typography variant="body1" fontWeight={400}>
                {legend}
            </Typography>
            {category.notes && (
                <Box marginTop="4px">
                    <Typography variant="body1" fontWeight={400} color={palette.grey[500]}>
                        {category.notes}
                    </Typography>
                </Box>
            )}
        </Stack>
    );
};

const CallDataGroup = ({ title, data }: { title: string; data: string }) => {
    return (
        <Stack gap="4px">
            <Typography variant="body2" fontWeight={600}>
                {title}
            </Typography>
            <Typography variant="body1" fontWeight={400}>
                {data}
            </Typography>
        </Stack>
    );
};

const Body = ({ callId }: { callId: number }) => {
    const { data: callDetails, isLoading: isDocumentedCallDetailsLoading } = useGetDocumentedCallDetails(callId);

    const callDuration = useMemo(() => {
        if (!callDetails) return undefined;

        const callDurationInHours = callDetails.attendedAt.diff(callDetails.triggeredAt).as('hours');

        return formatTimeDiff(callDurationInHours);
    }, [callDetails?.triggeredAt, callDetails?.attendedAt]);

    const location = useMemo(() => {
        if (!callDetails) return undefined;

        return callDetails.resident?.roomNumber
            ? `Apt ${callDetails.resident?.roomNumber}`
            : (callDetails.deviceLocation?.roomNumber ?? callDetails.deviceLocation?.name);
    }, [callDetails]);

    if (isDocumentedCallDetailsLoading || !callDetails) {
        return (
            <Stack spacing="48px">
                {range(2).map((i) => (
                    <Stack key={i} spacing="16px">
                        <Skeleton variant="rounded" animation="wave" width="60px" height="20px" />
                        {range(6).map((j) => (
                            <Stack key={j} spacing="8px">
                                <Skeleton variant="rounded" animation="wave" width="120px" height="14px" />
                                <Skeleton variant="rounded" animation="wave" width="240px" height="18px" />
                            </Stack>
                        ))}
                    </Stack>
                ))}
            </Stack>
        );
    }

    return (
        <>
            <Stack spacing="16px">
                <Typography variant="subtitle2" fontWeight={600}>
                    Details
                </Typography>
                <CallDataGroup title="Resident" data={callDetails.resident?.name ?? 'Unknown'} />
                <CallDataGroup title="Care Staff" data={callDetails.attendedByUser.name} />
                <CallDataGroup title="Location (guess/estimate)" data={location ?? 'Unknown'} />
                <CallDataGroup title="Response Time (Call to Arrival)" data={callDuration!} />
                <CallDataGroup
                    title="Call Timestamp by Resident"
                    data={callDetails.triggeredAt.toFormat('h:mm a, MMM. dd (ccc)')}
                />
                <CallDataGroup
                    title="Arrival Timestamp by Staff"
                    data={callDetails.attendedAt.toFormat('h:mm a, MMM. dd (ccc)')}
                />
            </Stack>
            <Stack marginTop="24px" spacing="16px">
                <Typography variant="subtitle2" fontWeight={600}>
                    Care
                </Typography>
                {callDetails.tasks.map((category, index) => (
                    <Stack key={index} gap="12px">
                        <CategoryData category={category} />
                        {index !== callDetails.tasks.length - 1 && (
                            <Divider
                                sx={({ palette }) => ({
                                    marginBlock: '8px',
                                    borderColor: palette.primary[300] as string,
                                })}
                            />
                        )}
                    </Stack>
                ))}
            </Stack>
        </>
    );
};

interface CallDetailsBottomSheetProps {
    open: boolean;
    callId: number;
    onClose: () => void;
}

export const CallDetailsBottomSheet = ({ open, onClose, callId }: CallDetailsBottomSheetProps) => {
    return (
        <CustomBottomSheet isOpen={open} onClose={onClose} Icon={PiRssSimple} title="Calls">
            <Body callId={callId} />
        </CustomBottomSheet>
    );
};
