import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtom, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';

import { BRANCH_CARE_LOCATION_TYPE } from '@allie/utils/src/constants/branch.constants';
import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useCareLocationType } from '~/api/common';
import { useBranchShifts } from '~/api/queries/branch';
import { useDailyTasks, useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import notificationSound from '~/assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from '~/components/Custom';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import WithHeader from '~/components/Layout/WithHeader';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import Loading from '~/components/Shared/Loading';
import { WithBranchShiftSelector } from '~/components/Shared/WithBranchShiftSelector';
import { ALL_LOCATIONS_ID, NO_RESIDENT_SELECTED_ID } from '~/constants/filters';
import { HOME_FILTERS } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';
import CheckTaskDialog from '~/pages/Home/components/CheckTaskDialog';
import TaskNotesDialog from '~/pages/Home/components/TaskNotesDialog';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import { ConfirmedTaskListV2 } from '~/pages/Home/confirmedTaskListV2';
import PageStructure from '~/pages/PageStructure';
import { usePermissions } from '~/permissions/utils';
import { setSortBy, setSortOrder } from '~/redux/actions/filters';
import { toggleResidentParty } from '~/redux/actions/residents';
import {
    CheckTaskMode,
    DailyTasksByTabStrict,
    DailyTasksShiftDetail,
    ResidentTasks,
    TabKey,
    TaskToUpdate,
} from '~/types/dailyTasks.d';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { SortBy } from '~/types/sort.d';

import {
    isLocationSelectorDialogOpenAtom,
    isShiftSelectorDialogOpenAtom,
    isSortDialogOpenAtom,
    locationIdsAtom,
    selectedDateAtom,
    selectedTabAtom,
} from './atom';
import { Header } from './components/Header';
import HeaderV2 from './components/HeaderV2';
import useHeaderV2 from './components/HeaderV2/hooks/useHeaderV2';
import { LocationSelectorDialog } from './components/LocationSelectorDialog';
import NewUnscheduledTaskDialog from './components/NewUnscheduledTaskDialog';
import useNewPrnFlow from './components/NewUnscheduledTaskDialog/hooks/useNewPrnFlow';
import { unscheduledTaskShiftIdAtom } from './components/NewUnscheduledTaskDialog/state/atom';
import ShiftRatingDialog from './components/ShiftRatingDialog';
import useShiftRatingFlow from './components/ShiftRatingDialog/hooks/useShiftRatingFlow';
import { ShiftSelectorDialog } from './components/ShiftSelectorDialog';
import { SortDialog } from './components/SortDialog';
import { LiveCalls } from './eCallComponents';
import { EndOfShiftWarning } from './eCallComponents/notification/EndOfShiftWarning';
import { useCalls } from './eCallComponents/useCalls';
import { filterTasksByName, groupTasksByResidentId, taskRecordSorter } from './helpers';
import { ResidentRows as PendingResidentRows } from './taskListComponents/ResidentRows';
import { useSortBy } from './useSortBy';

const TasksContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    width: '100%',
}));

const AddButtonStyle = styled(Button)(({ theme }) => ({
    marginBottom: 'env(safe-area-inset-bottom)',
    padding: 0,
    borderRadius: '50%',
    minWidth: 'unset',
    width: '72px',
    height: '72px',
    position: 'fixed',
    right: '12px',
    bottom: '80px',
    border: '2px solid #fff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    '& svg': {
        fontSize: '32px !important',
    },

    [theme.breakpoints.up('sm')]: {
        right: '24px',
        bottom: '24px',
    },
}));

interface ReduxDispatchProps {
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
    dispatchToggleResidentParty: () => void;
}

interface ReduxStateProps {
    timezone: string;
    sortBy?: SortBy.SortingKey;
    sortOrder?: SortBy.SortOrder;
    displayParty: boolean;
}

interface HomeProps extends ReduxStateProps, ReduxDispatchProps {
    selectedBranchId?: number | undefined;
    setSelectedBranchId: (branchId: number) => void;
}

const Home = ({
    timezone,
    sortBy,
    sortOrder,
    displayParty,
    selectedBranchId,
    setSelectedBranchId,
    dispatchSetSortBy,
    dispatchSetSortOrder,
    dispatchToggleResidentParty,
}: HomeProps) => {
    const isHeaderV2Enabled = useHeaderV2();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const { calls } = useCalls();

    const shifts = useBranchShifts(selectedBranchId);
    const { mutateAsync: updateTaskMutation } = useUpdateDailyTasks();

    const {
        shift: { id: currentShiftId },
        shiftDayDate: currentShiftDayDate,
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate) as DateTime<true>;

    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
    const datepickerDate = selectedDate ?? currentShiftDateTzDateTime;

    const setPRNShiftId = useSetAtom(unscheduledTaskShiftIdAtom);
    const [shift, setShift] = useState<number | null>(currentShiftId as number);
    // only select shift if it is a valid shift
    const isSelectedShiftValid =
        nearbyShifts.find(({ shift: { id: nearbyShiftId } }) => nearbyShiftId === shift) !== undefined;
    const selectedShiftId = shift && isSelectedShiftValid ? shift : (currentShiftId as number);

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(currentShiftDateTzDateTime);
        }
    }, [currentShiftDayDate]);

    // get shift id from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftId = queryParams.get('selected_shift_id');

        if (selectedShiftId && !isNaN(Number(selectedShiftId))) {
            setShift(Number(selectedShiftId));
            setPRNShiftId(Number(selectedShiftId));
        }
    }, [queryParams.get('selected_shift_id')]);

    // get shift day date from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftDate = queryParams.get('selected_shift_date');

        const dateTimeDate = selectedShiftDate ? DateTime.fromISO(selectedShiftDate) : undefined;

        if (dateTimeDate?.isValid) {
            setSelectedDate(dateTimeDate);
        }
    }, [queryParams.get('selected_shift_date')]);

    const { careLocationType } = useCareLocationType();
    const [locationIds, setLocationIds] = useAtom(locationIdsAtom);
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);
    const [isAddedTaskDialogOpen, setIsAddedTaskDialogOpen] = useState<boolean>(false);
    const [isCheckTaskDialogOpen, setIsCheckTaskDialogOpen] = useState<boolean>(false);
    const [checkTaskDialogType] = useState<CheckTaskMode>('Complete');
    const [isTaskNotesDialogOpen, setIsTaskNotesDialogOpen] = useState<boolean>(false);
    const [selectedTaskNotes, setSelectedTaskNotes] = useState<string>();
    const [selectedResidentId, setSelectedResidentId] = useState<number>(NO_RESIDENT_SELECTED_ID);
    const [playCoinSound] = useSound(notificationSound);
    const [addedTaskDefaultResidentId, setAddedTaskDefaultResidentId] = useState<number>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [isShiftSelectorDialogOpen, toggleShiftSelectorDialog] = useAtom(isShiftSelectorDialogOpenAtom);
    const [isLocationSelectorDialogOpen, toggleLocationSelectorDialog] = useAtom(isLocationSelectorDialogOpenAtom);
    const [isSortDialogOpen, toggleSortDialog] = useAtom(isSortDialogOpenAtom);

    const {
        isEnabled: isShiftRatingEnabled,
        handleShiftRatingDialogClose,
        handleShiftRatingDialogComplete,
        isShiftRatingDialogOpen,
    } = useShiftRatingFlow();

    const {
        isEnabled: isNewPrnFlowEnabled,
        isUnscheduledTaskDialogOpen: showNewUnscheduledTaskDialog,
        setIsUnscheduledTaskDialogOpen: setShowNewUnscheduledTaskDialog,
    } = useNewPrnFlow();

    const hasPermission = usePermissions();

    const { data: dailyTasksList, isLoading: isDailyTasksLoading } = useDailyTasks({
        branchId: selectedBranchId,
        date: datepickerDate.toFormat('yyyy-MM-dd'),
        careLocationIds:
            // TODO - remove this after setup database for all communities
            careLocationType === BRANCH_CARE_LOCATION_TYPE.CUSTOM
                ? locationIds.filter((id) => id !== ALL_LOCATIONS_ID)
                : undefined,
    });

    const { handleSort } = useSortBy(selectedBranchId);

    const permissionError = () => {
        showToast({
            message: 'Sorry, but this feature is exclusive for Directors/Caregivers',
            type: 'error',
        });
    };

    const handleFilterChange = (filter: string) => (newValue: number) => {
        if (filter === 'location') {
            // when location filter is changed unselect the currently selected resident
            setLocationIds([newValue]);
            setSelectedResidentId(NO_RESIDENT_SELECTED_ID);
        } else {
            // setting to show all assignments whenever a filter type changes to
            // prevent bugs where assignments are missed for the new setup
            setLocationIds([ALL_LOCATIONS_ID]);
        }

        if (filter === 'branch') {
            setSelectedBranchId(newValue);

            // Reset the shift id to use the current shift
            setShift(null);
            setPRNShiftId(null);
        } else if (filter === 'shift') {
            setShift(newValue);
            setPRNShiftId(newValue);
        } else if (filter === 'resident') {
            setSelectedResidentId(newValue);
        }
    };

    const handleTabChange = (tab: TabKey) => {
        setSelectedTab(tab);

        if (tab === TabKey.LIVE_CALLS) {
            setShift(currentShiftId as number);
            setPRNShiftId(currentShiftId as number);
            setSelectedDate(currentShiftDateTzDateTime);
        }
    };

    const handleAddTaskSubmit = () => {
        dispatchToggleResidentParty();
        playCoinSound();
    };

    const handleAddedTaskDialogOpen = () => {
        // If the user is not a Caregiver, throw an error and exit the function.
        if (!hasPermission('Community', 'undertake-resident-action')) {
            permissionError();
            return;
        }

        if (isNewPrnFlowEnabled) {
            setShowNewUnscheduledTaskDialog(true);
            return;
        }

        setIsAddedTaskDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsAddedTaskDialogOpen(false);
        setIsCheckTaskDialogOpen(false);
        setIsTaskNotesDialogOpen(false);
        setSelectedTaskNotes('');
    };

    const handleCompleteTaskClick = async ({ task }: { task: TaskToUpdate }) => {
        // If the user is not a allowed to perform this action, exit the function.
        if (!hasPermission('Community', 'update-resident-action')) {
            return;
        }

        // Trigger the action for updating the Task as Complete.
        await updateTaskMutation({ tasks: [task], branchId: Number(selectedBranchId) });

        if (hasPermission('Community', 'update-reward') && task.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
    };

    const handleSortOptions = (selectedSortBy?: string, selectedSortOrder?: string) => {
        if (sortBy !== selectedSortBy && selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }

        if (sortOrder !== selectedSortOrder && selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    useEffect(() => {
        // This check is necessary to ensure the correct initial tab will be selected
        if (!hasPermission('Community', 'ecall-caregiver') && selectedTab === TabKey.LIVE_CALLS) {
            setSelectedTab(TabKey.PENDING);
        }
    }, []);

    const tasksByTab = useMemo<DailyTasksByTabStrict>(() => {
        if (!selectedShiftId) {
            return [];
        }

        const newTasksToShow: DailyTasksShiftDetail[] =
            dailyTasksList && dailyTasksList[selectedShiftId]?.length
                ? cloneDeep(dailyTasksList[selectedShiftId])
                      .filter((task) => {
                          if (careLocationType === BRANCH_CARE_LOCATION_TYPE.ASSIGNMENT) {
                              return locationIds.includes(ALL_LOCATIONS_ID) || locationIds.includes(task.assignmentId);
                          }
                          if (careLocationType === BRANCH_CARE_LOCATION_TYPE.ZONE) {
                              return locationIds.includes(ALL_LOCATIONS_ID) || locationIds.includes(task.zoneId);
                          }

                          return task;
                      })
                      .filter((task) => {
                          if (selectedResidentId === NO_RESIDENT_SELECTED_ID) {
                              return true;
                          }

                          return task.resident.id === selectedResidentId;
                      })
                : [];

        const pendingTasks = newTasksToShow.filter((task) => task.taskStatus === 'Undocumented');
        const completedTasks = newTasksToShow.filter((task) => task.taskStatus !== 'Undocumented');
        pendingTasks.sort(taskRecordSorter);
        completedTasks.sort(taskRecordSorter);

        const tabs: DailyTasksByTabStrict = [
            {
                key: 1,
                tabName: 'pending',
                tasks: pendingTasks,
            },
            {
                key: 2,
                tabName: 'completed',
                tasks: completedTasks,
            },
        ];

        if (hasPermission('Community', 'ecall-caregiver')) {
            tabs.unshift({
                key: 0,
                tabName: 'calls',
                // overwriting the type here to make the type checker happy
                // obs: it's ok because the object won't be used
                tasks: calls as unknown[] as DailyTasksShiftDetail[],
            });
        }

        return tabs;
    }, [selectedShiftId, locationIds, JSON.stringify(dailyTasksList), selectedResidentId, calls]);

    useEffect(() => {
        if (selectedShiftId) {
            // Update the filters into the localStorage.
            const newFilters = {
                date: datepickerDate?.toFormat('yyyy-MM-dd HH:mm:ss'),
                shift: selectedShiftId,
            };
            localStorage.setItem(HOME_FILTERS, JSON.stringify(newFilters));
        }
    }, [datepickerDate?.toFormat('yyyy-MM-dd'), selectedShiftId]);

    // HACK ASCENT: not showing loading so the page doesn't scroll to the top again immediately
    if (!currentShiftDateTzDateTime || (hasPermission('Community', 'undertake-resident-action') && !selectedBranchId)) {
        return <Loading />;
    }

    return (
        <WithHeader
            header={
                isHeaderV2Enabled ? (
                    <HeaderV2
                        selectedTab={selectedTab}
                        tasksByTab={tasksByTab}
                        onTabChange={handleTabChange}
                        onShiftChange={(shiftId) => handleFilterChange('shift')(shiftId)}
                        selectedShiftDay={datepickerDate.toFormat('yyyy-MM-dd')}
                        currentShiftDayDate={currentShiftDayDate}
                        selectedShiftId={selectedShiftId}
                        nearbyShifts={nearbyShifts.map((shiftOptions) => shiftOptions.shift)}
                        currentShiftId={currentShiftId as number}
                        selectedBranchId={selectedBranchId}
                    />
                ) : (
                    <Header
                        tasksByTab={tasksByTab}
                        currentShiftDayDate={currentShiftDayDate}
                        currentShiftId={currentShiftId as number}
                        dailyTasksList={dailyTasksList}
                        selectedShiftId={selectedShiftId}
                        selectedBranchId={selectedBranchId}
                        selectedTab={selectedTab}
                        searchValue={searchValue}
                        shiftOptions={nearbyShifts}
                        onFilterChange={handleFilterChange}
                        onSearchValueChange={setSearchValue}
                        onTabChange={handleTabChange}
                        onSortButtonClick={toggleSortDialog}
                    />
                )
            }
        >
            <PageStructure sx={{ padding: '0 !important' }}>
                <TasksContainer>
                    {!!selectedBranchId &&
                        tasksByTab.map(({ key, tasks, tabName }) => {
                            let residentsTasksListSorted: ResidentTasks[] = [];

                            if (selectedTab !== TabKey.LIVE_CALLS && tabName !== 'calls') {
                                const filterBy =
                                    careLocationType === BRANCH_CARE_LOCATION_TYPE.ASSIGNMENT
                                        ? 'assignmentId'
                                        : careLocationType === BRANCH_CARE_LOCATION_TYPE.ZONE
                                          ? 'zoneId'
                                          : 'careLocationId';

                                const filteredTasks = filterTasksByName({
                                    tasks,
                                    searchValue,
                                    locationIds,
                                    filterBy,
                                });

                                const residentsTasks = groupTasksByResidentId(filteredTasks);

                                const residentsTasksList = Object.values(residentsTasks);

                                residentsTasksListSorted = handleSort(residentsTasksList, sortBy, sortOrder === 'asc');
                            }

                            return (
                                <TabPanel
                                    key={key}
                                    value={selectedTab}
                                    index={key}
                                    sx={{
                                        display: selectedTab === TabKey.LIVE_CALLS ? 'flex' : undefined,
                                        flex: key === TabKey.LIVE_CALLS ? 1 : undefined,
                                    }}
                                    TabPanelProps={{ padding: 0, width: '100%' }}
                                >
                                    {selectedTab === TabKey.LIVE_CALLS && (
                                        <LiveCalls
                                            shiftDay={datepickerDate.toFormat('yyyy-MM-dd')}
                                            shiftId={selectedShiftId}
                                        />
                                    )}
                                    {selectedTab === TabKey.PENDING && (
                                        <PendingResidentRows
                                            residentTasksList={residentsTasksListSorted}
                                            date={datepickerDate.toFormat('yyyy-MM-dd')}
                                            shift={selectedShiftId}
                                            branchId={selectedBranchId}
                                            setSelectedResidentId={setAddedTaskDefaultResidentId}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                            isLoading={isDailyTasksLoading}
                                        />
                                    )}
                                    {selectedTab === TabKey.COMPLETED && (
                                        <ConfirmedTaskListV2
                                            shiftDay={datepickerDate}
                                            shiftId={selectedShiftId}
                                            residentTasksList={residentsTasksListSorted}
                                            branchId={selectedBranchId}
                                            isLoading={isDailyTasksLoading}
                                        />
                                    )}
                                </TabPanel>
                            );
                        })}
                </TasksContainer>
                {hasPermission('Community', 'undertake-resident-action') && selectedTab !== TabKey.LIVE_CALLS && (
                    <AddButtonStyle variant="contained" color="primary" onClick={handleAddedTaskDialogOpen}>
                        <AddIcon />
                    </AddButtonStyle>
                )}
                <UnscheduledTaskDialog
                    isOpen={isAddedTaskDialogOpen}
                    defaultResidentId={addedTaskDefaultResidentId}
                    timezone={timezone}
                    currentShift={selectedShiftId}
                    onSubmit={handleAddTaskSubmit}
                    onClose={handleCloseDialog}
                    canChangeResident
                />
                <NewUnscheduledTaskDialog
                    isOpen={showNewUnscheduledTaskDialog}
                    onClose={() => setShowNewUnscheduledTaskDialog(false)}
                />
                <CheckTaskDialog
                    isOpen={isCheckTaskDialogOpen}
                    dialogType={checkTaskDialogType}
                    onSubmit={handleCompleteTaskClick}
                    onClose={handleCloseDialog}
                />
                <TaskNotesDialog
                    isOpen={isTaskNotesDialogOpen}
                    taskNote={selectedTaskNotes}
                    onClose={handleCloseDialog}
                />
                <SortDialog
                    selectedTab={selectedTab}
                    isOpen={isSortDialogOpen}
                    onClose={toggleSortDialog}
                    onSort={handleSortOptions}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <ShiftSelectorDialog
                    isOpen={isShiftSelectorDialogOpen}
                    onClose={toggleShiftSelectorDialog}
                    selectedBranchId={selectedBranchId}
                    selectedShiftId={selectedShiftId}
                    onShiftChange={(newShiftId) => {
                        handleFilterChange('shift')(newShiftId);
                        toggleShiftSelectorDialog();
                    }}
                    shiftOptions={nearbyShifts}
                />
                <LocationSelectorDialog
                    isOpen={isLocationSelectorDialogOpen}
                    onClose={toggleLocationSelectorDialog}
                    selectedTab={selectedTab}
                    selectedShiftDay={datepickerDate.toFormat('yyyy-MM-dd')}
                    selectedShiftId={selectedShiftId}
                />
                <ShiftRatingDialog
                    isOpen={isShiftRatingEnabled && isShiftRatingDialogOpen}
                    onClose={handleShiftRatingDialogClose}
                    onComplete={handleShiftRatingDialogComplete}
                />
                <CustomConfetti activate={displayParty} stop={dispatchToggleResidentParty} />
                <EndOfShiftWarning />
            </PageStructure>
        </WithHeader>
    );
};

const mapStateToProps = ({ session, filters, residents }: ReduxStore) => {
    const { timezone } = session;
    const { displayParty } = residents;
    const {
        caregiverApp: { sortBy, sortOrder },
    } = filters;

    return {
        timezone,
        sortBy: sortBy as SortBy.SortingKey,
        sortOrder: sortOrder as SortBy.SortOrder,
        displayParty,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): ReduxDispatchProps => ({
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithBranchShiftSelector(Home));
