import { Divider, Stack, Typography } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { CustomBottomSheet } from '~/components/Custom/CustomBottomSheet';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { ManagerNotesDetails } from '~/pages/OperationsV2/components/Module/Details/Notes/ManagerNotes';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_DETAIL_AND_RESIDENT', 'ALL', 'ALL_DETAIL'>[number];

const StaffNotesDetails = ({ item }: { item?: Item['staffNotes'] | null }) => {
    if (!item?.allNotes) {
        return null;
    }

    return (
        <>
            <Stack gap="12px">
                <Typography variant="body1" lineHeight="12px">
                    {item.residentName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    {item.staffName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    Response Time: {formatMsToMinutes(item.responseTimeMs)}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    {DateTime.fromISO(item.timestamp).toFormat('h:mm a, MMM d (EEE)')}
                </Typography>
            </Stack>
            {item.allNotes.length > 0 &&
                item.allNotes.map((note) => (
                    <>
                        <Divider />
                        <Stack gap="12px">
                            <Typography variant="body2" lineHeight="12px">
                                {note.tasks.join(', ')}
                            </Typography>
                            <Typography variant="body1">{note.notes}</Typography>
                        </Stack>
                    </>
                ))}
        </>
    );
};

const Data = ({ items }: { items: Item[] }) => {
    const [isManagerNotesBottomSheetOpen, setIsManagerNotesBottomSheetOpen] = useState(false);
    const [isStaffNotesBottomSheetOpen, setIsStaffNotesBottomSheetOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);

    const toggleManagerNotesBottomSheet = (row: Item | null) => {
        setSelectedItem(row);
        setIsManagerNotesBottomSheetOpen(!isManagerNotesBottomSheetOpen);
    };

    const toggleStaffNotesBottomSheet = (row: Item | null) => {
        setSelectedItem(row);
        setIsStaffNotesBottomSheetOpen(!isStaffNotesBottomSheetOpen);
    };

    return (
        <DataContainer>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                    },
                    notableEvents: {
                        label: 'Notable Events',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                    },
                    staffName: {
                        label: 'Care Staff',
                    },
                    responseTimeMs: {
                        label: 'Response Time',
                        renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                    },
                    timestamp: {
                        label: 'Timestamp',
                        renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('h:mm a, MMM d (EEE)') : null), // e.g. 1:23 PM, Jan 1 (Fri)
                    },
                    staffNotes: {
                        label: 'Staff Notes',
                        allowSort: false,
                        renderCell: (value, { row }) =>
                            value?.allNotes?.length ? (
                                <Notes
                                    notes={value.allNotes.map((note) => note.notes).join('\n')}
                                    maxWidth="200px"
                                    onSeeMoreClick={() => toggleStaffNotesBottomSheet(row)}
                                />
                            ) : (
                                '—'
                            ),
                    },
                    managerNotes: {
                        label: 'Manager Notes',
                        allowSort: false,
                        renderCell: (value, { row }) =>
                            value?.allNotes?.length ? (
                                <Notes
                                    notes={value.allNotes.map((note) => note.text).join('\n')}
                                    maxWidth="200px"
                                    onSeeMoreClick={() => toggleManagerNotesBottomSheet(row)}
                                />
                            ) : (
                                <AddNotesButton onAddNote={() => toggleManagerNotesBottomSheet(row)} />
                            ),
                    },
                    potentialPattern: {
                        label: 'Potential Pattern',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                    },
                    type: {
                        label: 'Type',
                    },
                }}
                rows={items}
            />
            <CustomBottomSheet
                isOpen={isManagerNotesBottomSheetOpen}
                onClose={() => toggleManagerNotesBottomSheet(null)}
                title="Manager Notes"
                Icon={Note}
            >
                <ManagerNotesDetails item={selectedItem?.managerNotes} />
            </CustomBottomSheet>
            <CustomBottomSheet
                isOpen={isStaffNotesBottomSheetOpen}
                onClose={() => toggleStaffNotesBottomSheet(null)}
                title="Staff Notes"
                Icon={Note}
            >
                <StaffNotesDetails item={selectedItem?.staffNotes} />
            </CustomBottomSheet>
        </DataContainer>
    );
};

export default Data;
