import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchId } from '~/api/common';
import { useBranchQuery } from '~/api/queries/branch';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

import { nextShiftVerificationTimeAtom } from '../atom';

import { getCurrentShiftBarrier } from './helpers';

export const useShiftVerification = () => {
    const { timezone } = useSelector((state: ReduxStore) => state.session);
    const branchId = useBranchId();
    const { data: branchData } = useBranchQuery(branchId);
    const hasNotificationFlowStarted = useRef(false);

    const [nextShiftVerificationTime, setNextShiftVerificationTime] = useAtom(nextShiftVerificationTimeAtom);
    const [isOnShiftConfirmModalOpen, setIsOnShiftConfirmModalOpen] = useState(false);

    const currentShift = branchData?.shifts
        ? getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchData.shifts)
        : undefined;

    const getNextShiftValidationInMillis = useCallback(() => {
        const currentTimeInMilliseconds = DateTime.now().toMillis();
        const nextShiftValidationTimeInMilliseconds = nextShiftVerificationTime
            ? DateTime.fromISO(nextShiftVerificationTime).toMillis()
            : undefined;

        return nextShiftValidationTimeInMilliseconds
            ? nextShiftValidationTimeInMilliseconds - currentTimeInMilliseconds
            : 0;
    }, [nextShiftVerificationTime]);

    const updateNextShiftVerificationTime = useCallback(() => {
        if (!currentShift) return;

        // setting the next verification time to the end of the current shift
        const endShiftDateTime = getCurrentShiftBarrier(currentShift.shift);

        setNextShiftVerificationTime(endShiftDateTime.toISO());
    }, [currentShift, setNextShiftVerificationTime]);

    const toggleOnShiftConfirmModal = useCallback(() => {
        setIsOnShiftConfirmModalOpen((prev) => !prev);
    }, [setIsOnShiftConfirmModalOpen]);

    useEffect(() => {
        const nextShiftVerificationTime = getNextShiftValidationInMillis();

        const startNotificationFlow = () => {
            if (!hasNotificationFlowStarted.current) {
                // if the shift that the user is on ended, we need to ask them if
                // they're on the next one. So we need to open the confirmation modal
                hasNotificationFlowStarted.current = true;
                toggleOnShiftConfirmModal();
            }
        };

        // run when the component is mounted
        if (nextShiftVerificationTime <= 0) {
            return startNotificationFlow();
        }

        const timeoutId = setTimeout(startNotificationFlow, nextShiftVerificationTime);

        return () => clearTimeout(timeoutId);
    }, [getNextShiftValidationInMillis, toggleOnShiftConfirmModal]);

    const stopNotificationFlow = () => {
        hasNotificationFlowStarted.current = false;
        updateNextShiftVerificationTime();
    };

    // used to reset the next shift verification time when user logs out
    const resetVerificationTime = useCallback(() => {
        hasNotificationFlowStarted.current = false;
        setNextShiftVerificationTime(null);
    }, [setNextShiftVerificationTime]);

    return {
        isOnShiftConfirmModalOpen,
        stopNotificationFlow,
        toggleOnShiftConfirmModal,
        updateNextShiftVerificationTime,
        resetVerificationTime,
    };
};
