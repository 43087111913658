import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';

import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = {
    allNotes: {
        timestamp: string;
        text: string;
    }[];
    residentName: string;
    staffName: string;
    responseTimeMs: number;
};

export const ManagerNotesDetails = ({ item }: { item?: Item | null }) => {
    if (!item?.allNotes) {
        return null;
    }

    return (
        <>
            <Stack gap="12px">
                <Typography variant="body1" lineHeight="12px">
                    {item.residentName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    {item.staffName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    Response Time: {formatMsToMinutes(item.responseTimeMs)}
                </Typography>
            </Stack>
            {/* TODO: implement option to add notes */}
            {item.allNotes.length > 0 && (
                <>
                    <Divider />
                    <Stack gap="12px">
                        <Typography variant="body2" lineHeight="12px">
                            {item.allNotes[0].timestamp}
                        </Typography>
                        <Typography variant="body1">{item.allNotes[0].text}</Typography>
                    </Stack>
                </>
            )}
        </>
    );
};
