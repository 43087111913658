import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { Icon } from '@phosphor-icons/react';
import React from 'react';
import { IconType } from 'react-icons';

import CustomDrawer from '~/components/Custom/CustomDrawer';

const BottomSheetBody = styled(Stack)(() => ({
    padding: '24px',
    gap: '24px',
    maxHeight: `calc(100% - 65px)`,
    overflow: 'auto',
    paddingBottom: `calc(env(safe-area-inset-bottom) + 24px)`,
}));

interface HeaderProps {
    title: string;
    caption?: string;
    Icon?: Icon | IconType;
}

const Header = ({ title, caption, Icon }: HeaderProps) => {
    const { palette } = useTheme();

    return (
        <Box gap="8px" alignItems="center" display="flex" marginTop="24px" padding="24px 24px 16px">
            {Icon && <Icon size={24} color={palette.primary[500] as string} />}
            <Stack>
                <Typography variant="subtitle2" fontWeight={700}>
                    {title}
                </Typography>
                {caption && <Typography variant="body1">{caption}</Typography>}
            </Stack>
        </Box>
    );
};

interface CustomBottomSheetProps extends HeaderProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const CustomBottomSheet = ({ isOpen, title, caption, Icon, onClose, children }: CustomBottomSheetProps) => {
    return (
        <CustomDrawer isOpen={isOpen} onClose={onClose} closeButton>
            <Header title={title} caption={caption} Icon={Icon} />
            <BottomSheetBody>{children}</BottomSheetBody>
        </CustomDrawer>
    );
};
