import { Box, Button, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PiCoinBold, PiSmileyWink } from 'react-icons/pi';

import CustomConfetti from '~/components/Custom/CustomConfetti';
import CustomDrawer from '~/components/Custom/CustomDrawer';
import { Placeholder } from '~/components/Shared/Placeholder';
import CustomModal from '~/scheduling/components/CustomModal';

interface FinalMessageProps {
    isUnderFiveMinutes: boolean;
    isOpen: boolean;
    onClose: () => void;
}

export const FinalMessage = ({ isUnderFiveMinutes, isOpen, onClose }: FinalMessageProps) => {
    const [startParty, setStartParty] = useState(false);
    const { palette } = useTheme();

    useEffect(() => {
        if (isUnderFiveMinutes && isOpen) {
            setStartParty(true);
        }
    }, [isUnderFiveMinutes, isOpen]);

    return isUnderFiveMinutes ? (
        <CustomDrawer isOpen={isOpen} onClose={onClose}>
            <Box display="flex" flexDirection="column" height="100%">
                <Box height="100%">
                    <Placeholder
                        description={
                            <>
                                {"Thank you for making your Resident's day brighter"}{' '}
                                <span style={{ fontWeight: 700 }}>and</span> documenting under 5 min. since completing
                                their request.
                            </>
                        }
                        title="You are on a roll!"
                        icon={PiCoinBold}
                        type="secondary"
                    />
                    <CustomConfetti activate={startParty} stop={() => setStartParty(false)} />
                </Box>
                <Box width="100%" padding="16px" borderTop={`1px solid ${palette.grey[100]}`}>
                    <Button fullWidth onClick={onClose}>
                        Woohoo!
                    </Button>
                </Box>
            </Box>
        </CustomDrawer>
    ) : (
        <CustomModal isOpen={isOpen} onClose={onClose} center>
            <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                alignItems="center"
                padding="24px"
                borderRadius="12px"
            >
                <Placeholder
                    icon={PiSmileyWink}
                    type="secondary"
                    size="small"
                    title="Did you know?"
                    description="You can earn points for documenting under 5 min. since completing your Resident's request."
                />
                <Button variant="outlined" fullWidth onClick={onClose}>
                    Cool tip
                </Button>
            </Box>
        </CustomModal>
    );
};
