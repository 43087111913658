import { Box, Stack, keyframes } from '@mui/material';
import { CaretUp } from '@phosphor-icons/react';
import React, { ReactNode, useEffect, useState } from 'react';

const OpenChevron = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
`;

const CloseChevron = keyframes`
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const AnimatedChevron = ({ isOpen }: { isOpen: boolean }) => {
    const animation = isOpen ? OpenChevron : CloseChevron;

    return (
        <Box sx={{ display: 'flex', animation: `${animation} 0.5s ease-in-out forwards` }}>
            <CaretUp size={24} />
        </Box>
    );
};

// This is a hack to make the row grow and shrink.
// It's not a good solution, but it's the only way I can think of to get the animation to work.
const maxHeight = 3000; // px

const ExpandAnimation = keyframes`
    0% {
        opacity: 0;
        max-height: 0;
    }
    100% {
        opacity: 1;
        max-height: ${maxHeight}px;
    }
`;

const CollapseAnimation = keyframes`
    0% {
        opacity: 1;
        max-height: ${maxHeight}px;
    }
    100% {
        opacity: 0;
        max-height: 0;
    }
`;

export const AnimatedExpandableBox = ({ isOpen, children }: { isOpen: boolean; children: ReactNode }) => {
    const [showChildren, setShowChildren] = useState(false);

    const animation = isOpen ? ExpandAnimation : CollapseAnimation;

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (isOpen) {
            setShowChildren(true);
        } else {
            // delay the collapse to allow the animation to play
            timeout = setTimeout(() => {
                setShowChildren(false);
            }, 500);
        }

        return () => clearTimeout(timeout);
    }, [isOpen]);

    return (
        <Stack overflow="hidden" sx={{ animation: `${animation} 0.5s ease-out forwards` }}>
            {showChildren && children}
        </Stack>
    );
};
