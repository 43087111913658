import { Box } from '@mui/material';
import { addMinutes } from 'date-fns';
import { useAtom } from 'jotai';
import { isEmpty, omit } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { PiWarning } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';

import { TASK_STATUS, TASK_STATUS_ID_TO_TASK_STATUS } from '@allie/utils/src/constants/task-records.constants';
import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { UndocumentedCalls } from '~/api/queries/call/getUndocumentedCalls';
import { useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import { useDocumentationActionsQuery } from '~/api/queries/tasks/documentationActions';
import notificationSound from '~/assets/notification-sound.mp3';
import AssistLevelChangeDialog from '~/components/Shared/AssistLevelChangeDialog';
import EditTaskStatusDialog from '~/components/Shared/EditTaskStatusDialog';
import NurseCallsDialog from '~/components/Shared/NurseCallsDialog';
import { Placeholder } from '~/components/Shared/Placeholder';
import RecentUnscheduledTasksDialog from '~/components/Shared/RecentUnscheduledTasksDialog';
import { AddTaskNotesDialog } from '~/components/Shared/Task/AddTaskNotesDialog';
import { TaskNotesViewDialog } from '~/components/Shared/Task/TaskNotesViewDialog';
import TasksCompletionCountDialog from '~/components/Shared/TasksCompletionCountDialog';
import { EARLY_IN_SHIFT_MINUTES } from '~/constants/home';
import { ONBOARDING } from '~/constants/onboarding';
import { DialogType } from '~/constants/shared';
import {
    checkIfEarlyInShift,
    getCurrentTimeAndShiftStartTime,
    setEarlyInShiftConfirmationExpireTime,
} from '~/lib/common';
import { getDateInUtc } from '~/lib/date';
import EarlyInShiftConfirmationDialog from '~/pages/Residents/Details/components/EarlyInShiftConfirmationDialog';
import { PUBLIC_LOCATION_TYPE } from '~/types/call/call';
import {
    DailyTasksShiftDetail,
    ResidentTasks,
    TaskLoadingState,
    TaskToUpdate,
    onConfirmMultipleTasksListInput,
} from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';
import { SortBy } from '~/types/sort';

import { LoadingTasks } from '../LoadingTasks';
import { selectedCallIdAtom } from '../eCallComponents/atom';
import { PublicLocationCallCard } from '../eCallComponents/call/PublicLocationCall';
import { ResidentUndocumentedCallTaskCard } from '../eCallComponents/call/ResidentUndocumentedCallTaskCard';
import { DocumentationFlow } from '../eCallComponents/documentation/DocumentationFlow';
import { ECall } from '../eCallComponents/types';
import { useCalls } from '../eCallComponents/useCalls';
import { useSortBy } from '../useSortBy';

import { MultiSelectProvider } from './MultiSelectProvider';
import { ResidentRow } from './ResidentRow';

type Props = {
    branchId: number;
    date: string;
    shift: number;
    residentTasksList: ResidentTasks[];
    setSelectedResidentId: (id?: number) => void;
    sortBy?: ECall.SortingKey | SortBy.SortingKey;
    sortOrder?: SortBy.SortOrder;
    isLoading: boolean;
};

export const ResidentRows = (props: Props) => {
    const { residentTasksList, branchId, date, shift, isLoading, setSelectedResidentId } = props;

    const [selectedCallId, setSelectedCallId] = useAtom(selectedCallIdAtom);
    const [loadingTaskStates, setLoadingTaskStates] = useState<{ [key: number]: TaskLoadingState }>({});
    const [loadingBulkState, setLoadingBulkState] = useState<TaskLoadingState>(null);

    const [viewTaskIdNotes, setViewTaskIdNotes] = useState<number | null>(null);

    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<number | null>(null);
    const [taskIdNotes, setTaskIdNotes] = useState<{ [key: number]: string }>({});

    const [showTaskIdRejectDialog, setShowTaskIdRejectDialog] = useState<number | null>(null);

    const [isNurseCallsDialogOpen, setIsNurseCallsDialogOpen] = useState<boolean>(false);
    const [showRecentUnscheduledTasksDialog, setShowRecentUnscheduledTasksDialog] = useState<boolean>(false);

    const [isTasksCompletionCountDialogOpen, setIsTasksCompletionCountDialogOpen] = useState(false);

    const [isAssistLevelChangeDialogOpen, setIsAssistLevelChangeDialogOpen] = useState(false);

    const [lastCompletedTaskResidentId, setLastCompletedTaskResidentId] = useState<number | null>(null);

    const [nurseCallsResidentId, setNurseCallsResidentId] = useState<number | null>(null);

    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});

    const [documentationActionsTaskIds, setDocumentationActionsTaskIds] = useState<number[]>([]);

    const [isEarlyInShiftConfirmationDialogOpen, setIsEarlyInShiftConfirmationDialogOpen] = useState<boolean>(false);
    const [confirmTaskParams, setConfirmTaskParams] = useState<{
        taskId: number;
        taskStatusId: number;
    } | null>(null);
    const [confirmSelectedTasksParams, setConfirmSelectedTasksParams] = useState<number | null>(null);
    const [rejectTaskDialogParams, setRejectTaskDialogParams] = useState<number | null>(null);

    const [playCoinSound] = useSound(notificationSound);

    const updateTaskMutation = useUpdateDailyTasks();

    const branchShifts = useBranchShifts(branchId);

    const { timezone } = useSelector((state: ReduxStore) => state.session);

    const {
        shift: { name: currentShiftName },
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const { undocumentedCalls, isUndocumentedCallsLoading } = useCalls({
        shiftDay: DateTime.fromISO(date),
        shiftId: shift,
    });

    const taskIdToTask: Record<number, DailyTasksShiftDetail> = useMemo(
        () =>
            residentTasksList.reduce((acc, { tasks }) => {
                tasks.forEach((task) => {
                    acc[task.dailyTaskRecordId] = task;
                });

                return acc;
            }, {}),
        [residentTasksList]
    );

    const { handleSort } = useSortBy(branchId);

    const onToggleTaskNotes = (taskId = null) => {
        setViewTaskIdNotes(taskId);
    };

    const onToggleEditAddTaskNotes = (taskId = null) => {
        setShowTaskIdAddNotesDialog(taskId);
    };

    const onSubmitEditAddTaskNotes = (taskNotes: string) => {
        // To make TS happy that it isn't null
        if (!showTaskIdAddNotesDialog) {
            return;
        }

        const newTaskNotes = {
            ...taskIdNotes,
            [showTaskIdAddNotesDialog]: taskNotes,
        };

        setTaskIdNotes(newTaskNotes);
        setShowTaskIdAddNotesDialog(null);
    };

    const onToggleRejectDialog = (taskId = null) => {
        setShowTaskIdRejectDialog(taskId);
    };

    const toggleNurseCallsDialog = () => {
        setIsNurseCallsDialogOpen((prevState) => !prevState);
    };

    const toggleRecentUnscheduledTasksDialog = () => {
        setShowRecentUnscheduledTasksDialog((prevState) => !prevState);
    };

    const toggleTasksCompletionCountDialog = () => {
        setIsTasksCompletionCountDialogOpen((prevState) => !prevState);
    };

    const toggleAssistLevelChangeDialog = () => {
        setIsAssistLevelChangeDialogOpen((prevState) => !prevState);
    };

    const toggleEarlyInShiftConfirmationDialog = () => {
        setIsEarlyInShiftConfirmationDialogOpen((prevState) => !prevState);
    };

    const onCompletionOptionsChange = (taskId: number, completionValue: number) => {
        setTaskIdCompletionOptions({
            ...taskIdCompletionOptions,
            [taskId]: completionValue,
        });
    };

    const { data: documentationActionsData } = useDocumentationActionsQuery({
        taskIds: [...documentationActionsTaskIds],
    });

    const checkAssistLevelChangeDialog = () => {
        if (documentationActionsData && !isEmpty(documentationActionsData.showAssistLevelChange)) {
            setIsAssistLevelChangeDialogOpen(true);
        }
    };

    const checkTaskCompletionCountDialog = () => {
        if (documentationActionsData && documentationActionsData.showTaskCompletionCount.length > 0) {
            setIsTasksCompletionCountDialogOpen(true);
        } else {
            checkAssistLevelChangeDialog();
        }
    };

    useEffect(() => {
        if (!documentationActionsData) return;
        if (isEarlyInShiftConfirmationDialogOpen) return;

        if (documentationActionsData.recentUnscheduledTasks.length > 0) {
            setShowRecentUnscheduledTasksDialog(true);
            return;
        }

        if (documentationActionsData.showNurseCallsActions) {
            setIsNurseCallsDialogOpen(true);
        } else {
            checkTaskCompletionCountDialog();
        }
    }, [documentationActionsTaskIds, documentationActionsData, isEarlyInShiftConfirmationDialogOpen]);

    const onConfirmTask = async (taskId: number, taskStatusId: number, notes = '') => {
        const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[taskStatusId] as keyof typeof TASK_STATUS;
        const tasksToUpdate: TaskToUpdate[] = [];

        tasksToUpdate.push({
            taskId,
            taskStatusId,
            caregiverNotes: notes || taskIdNotes[taskId],
        });

        setLoadingTaskStates((prevState) => ({
            ...prevState,
            [taskId]: taskStatus === TASK_STATUS.COMPLETED ? 'confirm' : 'reject',
        }));

        await updateTaskMutation.mutateAsync({ tasks: tasksToUpdate, branchId });

        playCoinSound();

        setDocumentationActionsTaskIds([taskId]);

        setLoadingTaskStates((prevState) => omit(prevState, taskId));
    };

    const onConfirmMultipleTasks = async (onConfirmMultipleTasksList: onConfirmMultipleTasksListInput, notes = '') => {
        const taskIds = onConfirmMultipleTasksList.map((task) => task.taskId);
        const tasksToUpdate: TaskToUpdate[] = [];

        onConfirmMultipleTasksList.forEach(({ taskId, taskStatusId }) => {
            let notesToUse = notes;
            if (taskIdNotes[taskId]) {
                notesToUse = taskIdNotes[taskId];
            }

            tasksToUpdate.push({
                taskId,
                taskStatusId,
                caregiverNotes: notesToUse,
            });
        });

        setLoadingTaskStates((prevState) => ({
            ...prevState,
            ...Object.fromEntries(taskIds.map((taskId) => [taskId, 'disable'])),
        }));

        // Assuming every task has the same status
        const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[tasksToUpdate[0].taskStatusId] as keyof typeof TASK_STATUS;
        setLoadingBulkState(taskStatus === TASK_STATUS.COMPLETED ? 'confirm' : 'reject');

        await updateTaskMutation.mutateAsync({ tasks: tasksToUpdate, branchId });

        playCoinSound();

        setDocumentationActionsTaskIds(onConfirmMultipleTasksList.map((task) => task.taskId));

        setLoadingTaskStates((prevState) => omit(prevState, taskIds));
        setLoadingBulkState(null);
    };

    const residentTasksAndCalls = useMemo<Map<number, { tasks?: ResidentTasks; calls: UndocumentedCalls }>>(() => {
        const residentIds = new Set([
            ...residentTasksList.map((resident) => resident.id),
            ...Object.keys(undocumentedCalls.byResidentIds).map(Number),
        ]);

        return new Map(
            Array.from(residentIds).map((residentId) => {
                const tasks = residentTasksList.find((resident) => resident.id === Number(residentId));
                const calls = undocumentedCalls.byResidentIds[residentId];

                return [
                    residentId,
                    {
                        tasks,
                        calls: calls ?? [],
                    },
                ];
            })
        );
    }, [residentTasksList, undocumentedCalls.byResidentIds]);

    const sortedResidentsId = useMemo(() => {
        const residentIds = new Set([
            ...residentTasksList.map((resident) => resident.id),
            ...Object.keys(undocumentedCalls.byResidentIds).map(Number),
        ]);

        const residentTasksAndUndocumentedCalls = Array.from(residentIds).map<ResidentTasks>((residentId) => {
            const residentTasks = residentTasksList.find((resident) => resident.id === Number(residentId));

            if (residentTasks) return residentTasks;

            const residentsUndocumentedCalls = undocumentedCalls.byResidentIds[residentId][0];
            // this object represent the resents with only undocumented calls and it has the same shape as `residentTasksList`
            // to be able to use the `handleSort` function above.
            return {
                ...residentsUndocumentedCalls.resident!,
                photo: residentsUndocumentedCalls.resident!.photo ?? '',
                isOnHospice: false,
                tasks: [],
            };
        });

        const sortedResidents = handleSort(residentTasksAndUndocumentedCalls, props.sortBy, props.sortOrder === 'asc');
        // using only the resident id because the `sortedResidents` objects don't represent the correct data
        return sortedResidents.map((resident) => resident.id);
    }, [residentTasksList, undocumentedCalls, props.sortBy, props.sortOrder]);

    const ecallCompletedAt = useMemo(() => {
        if (!selectedCallId) return;

        const documentedCallDetails = undocumentedCalls.calls.find((call) => call.id === selectedCallId);

        return documentedCallDetails?.completedAt.toISO();
    }, [selectedCallId, undocumentedCalls.calls]);

    const closeDocumentationFlow = () => {
        setSelectedCallId(null);
    };

    return (
        <>
            {isLoading || isUndocumentedCallsLoading ? (
                <LoadingTasks />
            ) : !residentTasksList.length && !undocumentedCalls.calls.length ? (
                <Box marginTop="80px" paddingInline="16px">
                    <Placeholder
                        title="No tasks found"
                        icon={PiWarning}
                        type="secondary"
                        description="No pending tasks found for the selected date, shift and location"
                    />
                </Box>
            ) : (
                <MultiSelectProvider
                    setSelectedResidentIdForAddedTaskDialog={setSelectedResidentId}
                    residentTasksList={residentTasksList}
                    render={(
                        openedResidentId,
                        selectedResidentId,
                        residentSelectedTaskIds,
                        onResidentToggle,
                        onResidentSelect,
                        onResidentTaskSelect
                    ) => {
                        const confirmSelectedTasks = (taskStatusId: number, notes = '') => {
                            const tasks = Array.from(residentSelectedTaskIds).map((taskId) => ({
                                taskId,
                                taskStatusId,
                            }));
                            void onConfirmMultipleTasks(tasks, notes);
                        };

                        const onRejectDialogSubmit = (taskStatusId: number, notes = '') => {
                            if (showTaskIdRejectDialog === -1) {
                                confirmSelectedTasks(taskStatusId, notes);
                            } else if (showTaskIdRejectDialog) {
                                void onConfirmTask(showTaskIdRejectDialog, taskStatusId, notes);
                            }
                            setShowTaskIdRejectDialog(null);
                        };

                        const handleAcceptEarlyInShift = async () => {
                            toggleEarlyInShiftConfirmationDialog();
                            const { shiftStartTime } = getCurrentTimeAndShiftStartTime(timezone, branchShifts);
                            setEarlyInShiftConfirmationExpireTime(
                                addMinutes(shiftStartTime, EARLY_IN_SHIFT_MINUTES).getTime()
                            );
                            if (confirmTaskParams) {
                                await onConfirmTask(confirmTaskParams.taskId, confirmTaskParams.taskStatusId);
                            } else if (confirmSelectedTasksParams) {
                                confirmSelectedTasks(confirmSelectedTasksParams);
                            } else if (rejectTaskDialogParams) {
                                setShowTaskIdRejectDialog(rejectTaskDialogParams);
                            }
                            setConfirmTaskParams(null);
                            setConfirmSelectedTasksParams(null);
                            setRejectTaskDialogParams(null);
                        };

                        return (
                            <>
                                {Object.entries(undocumentedCalls.byPublicLocationId).map(
                                    ([deviceLocationId, calls]) => {
                                        const deviceLocation = calls.find(
                                            (call) => call.deviceLocation!.id === +deviceLocationId
                                        )!.deviceLocation!;
                                        return (
                                            <PublicLocationCallCard
                                                key={deviceLocationId}
                                                deviceLocation={deviceLocation}
                                                calls={calls}
                                                type={deviceLocation.type as PUBLIC_LOCATION_TYPE}
                                            />
                                        );
                                    }
                                )}
                                {sortedResidentsId.map((residentId) => {
                                    const residentTasks = residentTasksAndCalls.get(residentId)!;
                                    const residentUndocumentdCalls = residentTasks.calls;

                                    if (!residentTasks.tasks) {
                                        // calls from residents with no task should shown in anyways, so in these cases
                                        // we will need to create the card component for them
                                        return (
                                            <ResidentUndocumentedCallTaskCard
                                                key={residentId}
                                                calls={residentUndocumentdCalls}
                                                resident={residentUndocumentdCalls[0].resident!}
                                            />
                                        );
                                    }

                                    return (
                                        <ResidentRow
                                            key={residentId}
                                            isSelected={selectedResidentId === residentId}
                                            isOpen={openedResidentId === residentId}
                                            residentTasks={residentTasks.tasks}
                                            selectedTaskIds={residentSelectedTaskIds}
                                            loadingTaskStates={loadingTaskStates}
                                            loadingBulkState={loadingBulkState}
                                            undocumentedCalls={openedResidentId ? residentUndocumentdCalls : []}
                                            onResidentToggle={onResidentToggle}
                                            onResidentSelectToggle={onResidentSelect}
                                            onResidentTaskSelectToggle={onResidentTaskSelect}
                                            onToggleTaskNotes={onToggleTaskNotes}
                                            onToggleEditAddTaskNotes={onToggleEditAddTaskNotes}
                                            onToggleRejectDialog={(taskId: number) => {
                                                setNurseCallsResidentId(residentId);
                                                if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                    toggleEarlyInShiftConfirmationDialog();
                                                    setRejectTaskDialogParams(taskId);
                                                    return;
                                                }
                                                setShowTaskIdRejectDialog(taskId);
                                            }}
                                            onCompletionOptionsChange={onCompletionOptionsChange}
                                            taskIdCompletionOptions={taskIdCompletionOptions}
                                            taskIdNotes={taskIdNotes}
                                            onConfirmTask={(taskId, taskStatusId) => {
                                                setNurseCallsResidentId(residentId);
                                                setLastCompletedTaskResidentId(residentId);

                                                if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                    toggleEarlyInShiftConfirmationDialog();
                                                    setConfirmTaskParams({
                                                        taskId,
                                                        taskStatusId,
                                                    });
                                                    return;
                                                }

                                                void onConfirmTask(taskId, taskStatusId);
                                            }}
                                            confirmSelectedTasks={(taskStatusId) => {
                                                setNurseCallsResidentId(residentId);
                                                setLastCompletedTaskResidentId(residentId);
                                                if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                    toggleEarlyInShiftConfirmationDialog();
                                                    setConfirmSelectedTasksParams(taskStatusId);
                                                    return;
                                                }
                                                confirmSelectedTasks(taskStatusId);
                                            }}
                                            date={date}
                                            shift={shift}
                                            branchId={branchId}
                                        />
                                    );
                                })}
                                <TaskNotesViewDialog
                                    isOpen={viewTaskIdNotes !== null}
                                    taskNote={viewTaskIdNotes ? taskIdToTask[viewTaskIdNotes].taskNotes : ''}
                                    onClose={() => onToggleTaskNotes()}
                                />
                                <AddTaskNotesDialog
                                    isOpen={showTaskIdAddNotesDialog !== null}
                                    onClose={() => onToggleEditAddTaskNotes()}
                                    onSubmit={onSubmitEditAddTaskNotes}
                                    taskNote={showTaskIdAddNotesDialog ? taskIdNotes[showTaskIdAddNotesDialog] : ''}
                                />
                                <EditTaskStatusDialog
                                    onboardingId={ONBOARDING.REFUSE_TASK_REASON.TARGET}
                                    isOpen={showTaskIdRejectDialog !== null}
                                    onClose={onToggleRejectDialog}
                                    onSubmit={(taskStatusId, notes) => {
                                        onRejectDialogSubmit(taskStatusId, notes);
                                    }}
                                    taskNotes={showTaskIdRejectDialog ? taskIdNotes[showTaskIdRejectDialog] : undefined}
                                    dialogType={DialogType.Reject}
                                />
                                <EarlyInShiftConfirmationDialog
                                    isOpen={isEarlyInShiftConfirmationDialogOpen}
                                    onClose={() => {
                                        setShowTaskIdRejectDialog(null);
                                        setNurseCallsResidentId(null);
                                        setLastCompletedTaskResidentId(null);
                                        setConfirmTaskParams(null);
                                        setConfirmSelectedTasksParams(null);
                                        setRejectTaskDialogParams(null);
                                        toggleEarlyInShiftConfirmationDialog();
                                    }}
                                    shiftName={currentShiftName}
                                    onAccept={handleAcceptEarlyInShift}
                                />
                                <DocumentationFlow
                                    isOpen={!!selectedCallId}
                                    onClose={closeDocumentationFlow}
                                    callId={selectedCallId}
                                    completedAt={ecallCompletedAt}
                                />
                            </>
                        );
                    }}
                />
            )}
            {nurseCallsResidentId && documentationActionsData?.showNurseCallsActions && (
                <NurseCallsDialog
                    isOpen={isNurseCallsDialogOpen}
                    onClose={() => {
                        toggleNurseCallsDialog();
                        setNurseCallsResidentId(null);
                        checkTaskCompletionCountDialog();
                    }}
                    residentId={nurseCallsResidentId}
                    date={date}
                    shift={shift}
                    branchId={branchId}
                />
            )}
            {nurseCallsResidentId && (
                <RecentUnscheduledTasksDialog
                    isOpen={showRecentUnscheduledTasksDialog}
                    tasks={documentationActionsData?.recentUnscheduledTasks ?? []}
                    onClose={() => {
                        toggleRecentUnscheduledTasksDialog();
                        setNurseCallsResidentId(null);
                        checkTaskCompletionCountDialog();
                    }}
                    residentId={nurseCallsResidentId}
                    date={date}
                    shift={shift}
                    branchId={branchId}
                />
            )}
            {documentationActionsData?.showTaskCompletionCount && lastCompletedTaskResidentId && (
                <TasksCompletionCountDialog
                    isOpen={isTasksCompletionCountDialogOpen}
                    onClose={() => {
                        toggleTasksCompletionCountDialog();
                        checkAssistLevelChangeDialog();
                    }}
                    tasksCompletionCountData={documentationActionsData.showTaskCompletionCount}
                    residentId={lastCompletedTaskResidentId}
                    date={date}
                    shift={shift}
                />
            )}
            {documentationActionsData?.showAssistLevelChange && lastCompletedTaskResidentId && (
                <AssistLevelChangeDialog
                    isOpen={isAssistLevelChangeDialogOpen}
                    onClose={() => {
                        toggleAssistLevelChangeDialog();
                        setLastCompletedTaskResidentId(null);
                    }}
                    assistLevelChangeData={documentationActionsData.showAssistLevelChange}
                    residentId={lastCompletedTaskResidentId}
                />
            )}
        </>
    );
};
