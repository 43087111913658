import { Box, Button, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
import { ArrowLineDown, CaretLeft, MagnifyingGlass } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { ComponentProps, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AnyModuleType, DetailsPayload } from '@allie/operations-common/src/types/module';

import { useGetDetailsData } from '~/api/queries/operations/getDetailsData';
import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';
import {
    detailsPayloadAtom,
    isDetailsOpenAtom,
    selectedBranchIdAtom,
    selectedDateAtom,
    selectedPeriodAtom,
    selectedRegionIdAtom,
} from '~/pages/OperationsV2/atoms';
import {
    AnyDetailsDefinition,
    AnyDetailsGroupingDefinition,
    AnyDetailsGroupingDefinitionTextOverride,
    AnyModuleDefinition,
    definitionsByLevel,
} from '~/pages/OperationsV2/modules';
import { ReduxStore } from '~/types/redux';

import { LoadingDataTableSkeleton } from '../Loading';

const DetailsContainer = styled(Stack)({
    height: '100%',
});

const DetailsHeaderContainer = styled(Stack)({
    gap: '16px',
    padding: '24px',
});

const ActionButtonsRow = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const toggleDetails = useSetAtom(isDetailsOpenAtom);

    if (!detailsPayload) return null;

    const { level, moduleType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType] as AnyModuleDefinition;

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Button color="secondary" onClick={toggleDetails} startIcon={<CaretLeft />}>
                {moduleDefinition.label}
            </Button>
            <Button color="secondary" startIcon={<ArrowLineDown />}>
                Download
            </Button>
        </Stack>
    );
};

const SectionCaption = styled(SingleLineTypography)(({ theme: { palette } }) => ({
    color: alpha(palette.grey[900], 0.7),
}));

const LabelRow = () => {
    const { palette } = useTheme();

    const { companyId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const period = useAtomValue(selectedPeriodAtom);
    const date = useAtomValue(selectedDateAtom);
    const branchId = useAtomValue(selectedBranchIdAtom);
    const regionId = useAtomValue(selectedRegionIdAtom);

    const { data, isLoading } = useGetDetailsData(
        detailsPayload
            ? {
                  level: detailsPayload.level,
                  module: detailsPayload.moduleType as AnyModuleType,
                  details: detailsPayload.detailsType,
                  grouping: detailsPayload.detailsGroupingType,
                  period,
                  date: date.toFormat('yyyy-MM-dd'),
                  branchId,
                  regionId,
                  companyId,
                  sortBy: detailsPayload.sortBy?.key ?? null,
                  sortDirection: detailsPayload.sortBy?.direction ?? null,
              }
            : undefined
    );

    if (isLoading) {
        // return skeleton
    }

    const overrideText = useCallback(
        (override: AnyDetailsGroupingDefinitionTextOverride | undefined, fallback: string | undefined) => {
            // Try to override, fall back to default if no overrides are set
            // or if data is needed and hasn't been fetched yet
            switch (typeof override) {
                case 'function':
                    return data ? override(data, { palette }) : fallback;
                case 'string':
                    return override;
                default:
                    return fallback;
            }
        },
        [data]
    );

    if (!detailsPayload) return null;

    const { level, moduleType, detailsType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType] as AnyModuleDefinition;

    const { label, caption, groupings } = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const { overrideDetailsLabel, overrideDetailsCaption } = groupings[
        detailsPayload.detailsGroupingType
    ] as AnyDetailsGroupingDefinition;

    const overriddenLabel = overrideText(overrideDetailsLabel, label);
    const overriddenCaption = overrideText(overrideDetailsCaption, caption);

    return (
        <Stack>
            <Typography variant="subtitle2" fontWeight={700} sx={{ textWrap: 'balance' }}>
                {overriddenLabel}
            </Typography>
            {overriddenCaption && <SectionCaption variant="caption">{overriddenCaption}</SectionCaption>}
        </Stack>
    );
};

const GroupingButtonsContainer = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    overflowX: 'auto',
});

const GroupingButton = ({
    groupingDefinition,
    sx,
    ...props
}: { groupingDefinition: AnyDetailsGroupingDefinition } & ComponentProps<typeof Button>) => {
    const [detailsPayload, setDetailsPayload] = useAtom(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { detailsGroupingType } = detailsPayload;
    const isSelected = groupingDefinition.type === detailsGroupingType;

    const handleClick = () => {
        setDetailsPayload(
            (prev) =>
                ({
                    ...prev,
                    detailsGroupingType: groupingDefinition.type,
                }) as DetailsPayload
        );
    };

    return (
        <Button
            {...props}
            variant={isSelected ? 'contained' : 'outlined'}
            color="grey"
            onClick={handleClick}
            sx={{
                flex: '1 0 auto',
                minWidth: 0,
                whiteSpace: 'nowrap',
                ...sx,
            }}
        >
            {groupingDefinition.label}
        </Button>
    );
};

const FiltersRow = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType] as AnyModuleDefinition;
    const detailsDefinition = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const groupingDefinitions = Object.values(detailsDefinition.groupings) as AnyDetailsGroupingDefinition[];
    const groupingDefinition = detailsDefinition.groupings[detailsGroupingType] as AnyDetailsGroupingDefinition;

    if (groupingDefinitions.length < 2 && !groupingDefinition.allowSearch) return null;

    return (
        <Stack spacing="12px">
            <GroupingButtonsRow groupingDefinitions={groupingDefinitions} />
            <SearchRow groupingDefinition={groupingDefinition} />
        </Stack>
    );
};

const GroupingButtonsRow = ({ groupingDefinitions }: { groupingDefinitions: AnyDetailsGroupingDefinition[] }) => {
    if (groupingDefinitions.length < 2) return null;

    return (
        <GroupingButtonsContainer>
            {groupingDefinitions.map((groupingDefinition) => (
                <GroupingButton key={groupingDefinition.type} groupingDefinition={groupingDefinition} />
            ))}
        </GroupingButtonsContainer>
    );
};

const SearchRow = ({ groupingDefinition }: { groupingDefinition: AnyDetailsGroupingDefinition }) => {
    const canHover = useCanHover();

    if (!groupingDefinition.allowSearch) return null;

    return (
        // Mock implementation, demo only
        <Stack
            sx={({ palette }) => ({
                color: palette.grey[900],
                bgcolor: palette.grey[100],
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                px: '12px',
                height: '48px',
                borderRadius: '24px',
                userSelect: 'none',
                transition: 'background-color 0.1s',
                '&:hover': canHover && { bgcolor: alpha(palette.grey[900], 0.15) },
                '&:active': { bgcolor: alpha(palette.grey[900], 0.2) },
            })}
        >
            <MagnifyingGlass size="24px" />
            <SingleLineTypography
                variant="body2"
                sx={({ palette }) => ({
                    color: palette.grey[500],
                    fontWeight: 400,
                    cursor: 'text',
                })}
            >
                {groupingDefinition.searchPlaceholder ?? 'Search'}
            </SingleLineTypography>
        </Stack>
    );
};

const DetailsBodyContainer = styled(Box)({
    flex: 1,
    overflow: 'hidden',
});

const DetailsBody = () => {
    const { palette } = useTheme();

    const { companyId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const period = useAtomValue(selectedPeriodAtom);
    const date = useAtomValue(selectedDateAtom);
    const branchId = useAtomValue(selectedBranchIdAtom);
    const regionId = useAtomValue(selectedRegionIdAtom);

    const { data, isLoading } = useGetDetailsData(
        detailsPayload
            ? {
                  level: detailsPayload.level,
                  module: detailsPayload.moduleType as AnyModuleType,
                  details: detailsPayload.detailsType,
                  grouping: detailsPayload.detailsGroupingType,
                  period,
                  date: date.toFormat('yyyy-MM-dd'),
                  branchId,
                  regionId,
                  companyId,
                  sortBy: detailsPayload.sortBy?.key ?? null,
                  sortDirection: detailsPayload.sortBy?.direction ?? null,
              }
            : undefined
    );

    if (isLoading) {
        return <LoadingDataTableSkeleton />;
    }

    if (!data || !detailsPayload) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType] as AnyModuleDefinition;
    const detailsDefinition = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const groupingDefinition = detailsDefinition.groupings[detailsGroupingType] as AnyDetailsGroupingDefinition;

    return groupingDefinition.renderData(data, { palette });
};

const Details = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);

    if (!detailsPayload) return null;

    return (
        <DetailsContainer>
            <DetailsHeaderContainer>
                <ActionButtonsRow />
                <LabelRow />
                <FiltersRow />
            </DetailsHeaderContainer>
            <DetailsBodyContainer>
                <DetailsBody />
            </DetailsBodyContainer>
        </DetailsContainer>
    );
};

export default Details;
