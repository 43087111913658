import { Box, Button, CircularProgress, Skeleton, Stack, Switch, Typography, styled, useTheme } from '@mui/material';
import { range } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ALL_LOCATIONS_ID } from '~/constants/filters';
import { useLocations } from '~/hooks/useLocations';

import { LocationItem } from './LocationItem';

const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    gap: '12px',
}));

const Content = styled(Stack)(() => ({
    padding: '24px',
    flexGrow: 1,
    overflowY: 'auto',
    height: '100%',
    gap: '12px',
}));

type FormData = { locations: number[] };

interface LocationSelectorProps {
    defaultValue?: number[];
    isLoading: boolean;
    onSubmit: (assignmentIds: number[]) => void;
    selectedShiftId?: number;
    selectedShiftDay?: string;
}

export const LocationSelector = ({
    defaultValue,
    isLoading,
    onSubmit,
    selectedShiftId,
    selectedShiftDay,
}: LocationSelectorProps) => {
    const { palette } = useTheme();

    const { locations, isLoading: isLocationsLoading } = useLocations({
        shiftId: selectedShiftId,
        shiftDay: selectedShiftDay,
    });

    const form = useForm<FormData>({ defaultValues: { locations: [] } });
    const selectedLocations = form.watch('locations');

    useEffect(() => {
        if (defaultValue && locations) {
            const selectedLocations =
                defaultValue.includes(ALL_LOCATIONS_ID) || locations.length === defaultValue.length
                    ? [ALL_LOCATIONS_ID, ...locations.map(({ id }) => id)]
                    : defaultValue;
            form.reset({ locations: selectedLocations });
        }
    }, [defaultValue, locations]);

    const handleSubmit = ({ locations }: FormData) => {
        onSubmit(locations);
    };

    const handleSelection = (id: number, prevValue: number[]): number[] => {
        const options = locations?.map(({ id }) => id) ?? [];

        // unselecting `All` when another option is unselected
        if (prevValue.includes(ALL_LOCATIONS_ID)) {
            return prevValue.filter((e) => e !== ALL_LOCATIONS_ID && e !== id);
        }

        // unselect an option
        if (prevValue.includes(id)) {
            return prevValue.filter((e) => e !== id);
        }

        const selectedAll = options && [...prevValue, id].length === options.length;

        // includes `All` option
        if (selectedAll) {
            return [...prevValue, id, ALL_LOCATIONS_ID];
        }

        return [...prevValue, id];
    };

    const handleToggleAll = (selectAll: boolean) => {
        const options = locations?.map(({ id }) => id) ?? [];

        return selectAll ? [...options, ALL_LOCATIONS_ID] : [];
    };

    return (
        <form
            style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            <Controller
                name="locations"
                control={form.control}
                render={({ field }) => (
                    <Content>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                variant="label"
                                component="p"
                                fontSize="14px"
                                color={palette.grey[400]}
                                fontWeight={600}
                            >
                                Locations
                            </Typography>
                            {!isLocationsLoading && (
                                <Box display="flex" gap="8px" alignSelf="flex-end">
                                    <Typography variant="label" component="p" fontSize="14px" color={palette.grey[400]}>
                                        Select All
                                    </Typography>
                                    <Switch
                                        checked={field.value?.includes(ALL_LOCATIONS_ID)}
                                        onChange={(_, checked) => field.onChange(handleToggleAll(checked))}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Stack gap="8px">
                            {isLocationsLoading
                                ? range(5).map((i) => (
                                      <Skeleton
                                          key={i}
                                          variant="rectangular"
                                          height="45px"
                                          sx={{ borderRadius: '8px' }}
                                      />
                                  ))
                                : locations?.map(({ id, name }) => (
                                      <LocationItem
                                          selected={field.value?.includes(id)}
                                          key={id}
                                          label={name}
                                          onClick={() => field.onChange(handleSelection(id, field.value))}
                                          onOnlyClick={() => field.onChange([id])}
                                      />
                                  ))}
                        </Stack>
                    </Content>
                )}
            />
            <FooterContainer>
                <Button fullWidth type="submit" disabled={!selectedLocations.length || isLoading}>
                    {isLoading ? <CircularProgress size={20} sx={{ color: palette.primary[500] as string }} /> : 'Save'}
                </Button>
            </FooterContainer>
        </form>
    );
};
