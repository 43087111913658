import { Box } from '@mui/material';
import React, { ComponentProps } from 'react';

import ShowMoreButton from './ShowMore';

interface SeeMoreButtonProps extends ComponentProps<typeof Box> {
    see?: 'more' | 'less';
}

const SeeMoreButton = ({ see = 'more', ...props }: SeeMoreButtonProps) => {
    return <ShowMoreButton title={`See ${see}`} {...props} />;
};

export default SeeMoreButton;
