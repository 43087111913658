import { Skeleton as MUISkeleton, Stack, alpha, styled, useTheme } from '@mui/material';
import { range } from 'lodash';
import React from 'react';

const Skeleton = styled(MUISkeleton)({
    transform: 'unset',
    marginBlock: '2px', // simulate increase due to line-height
});

export const LoadingSectionSkeleton = ({ seed }: { seed: number }) => {
    const { palette } = useTheme();

    return (
        <Stack
            padding="20px 24px"
            gap="4px"
            borderBottom="1px solid"
            borderColor={alpha(palette.grey[900], 0.1)}
            direction="row"
            justifyContent="space-between"
        >
            <Stack gap="4px">
                <Skeleton variant="text" height="16px" width="240px" />
                {seed % 2 === 0 && <Skeleton variant="text" height="12px" width="180px" />}
            </Stack>
            {seed % 4 === 0 && (
                <Stack alignSelf="center">
                    <Skeleton variant="text" height="16px" width="50px" />
                </Stack>
            )}
        </Stack>
    );
};

export const LoadingModuleSkeleton = () => {
    return (
        <Stack
            padding="20px 24px"
            bgcolor="#E0F0F1"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="72px"
        >
            <Skeleton variant="text" height="20px" width="200px" />
            <Skeleton variant="rounded" height="24px" width="24px" />
        </Stack>
    );
};

export const LoadingDataTableSkeleton = () => {
    const { palette } = useTheme();

    return (
        <Stack direction="row" paddingInline="24px">
            {range(3).map((i) => (
                <Stack key={i + 'column'} direction="column" gap="8px">
                    {/* Header */}
                    <Stack direction="row" gap="20px" sx={{ marginRight: '16px' }} alignItems="end">
                        <Skeleton variant="text" height="16px" width="60px" />
                        <Skeleton variant="rounded" height="20px" width="20px" />
                    </Stack>
                    {/* Rows */}
                    {range(6).map((j) => (
                        <Stack
                            key={j + 'row-value'}
                            direction="row"
                            height="64px"
                            alignItems="center"
                            borderBottom="1px solid"
                            borderColor={palette.primary[300] as string}
                        >
                            <Skeleton variant="text" height="14px" width="100px" />
                        </Stack>
                    ))}
                </Stack>
            ))}
        </Stack>
    );
};
