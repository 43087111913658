import Module from '.';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { range } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { AnyModuleData, AnySectionData } from '@allie/operations-common/src/types/module';

import { useGetOperationsOverall } from '~/api/queries/operations/getOperationsOverall';
import { ReduxStore } from '~/types/redux';

import {
    selectedBranchIdAtom,
    selectedDateAtom,
    selectedLevelAtom,
    selectedPeriodAtom,
    selectedRegionIdAtom,
} from '../../atoms';
import { AnyModuleDefinition, definitionsByLevel } from '../../modules';

import { LoadingModuleSkeleton, LoadingSectionSkeleton } from './Loading';

const Modules = () => {
    const { companyId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const level = useAtomValue(selectedLevelAtom);
    const date = useAtomValue(selectedDateAtom);
    const period = useAtomValue(selectedPeriodAtom);
    const regionId = useAtomValue(selectedRegionIdAtom);
    const branchId = useAtomValue(selectedBranchIdAtom);

    const { data, isLoading } = useGetOperationsOverall({
        level,
        date: date.toFormat('yyyy-MM-dd'),
        regionId,
        branchId,
        period,
        companyId,
    });

    if (isLoading) {
        return (
            <Stack sx={{ height: '100%', overflowY: 'auto' }}>
                <LoadingModuleSkeleton />
                {range(6).map((i) => (
                    <LoadingSectionSkeleton key={i} seed={i} />
                ))}
            </Stack>
        );
    }

    const modules = Object.values(data?.modules ?? {});
    const moduleDefinitions = definitionsByLevel[level].modules;

    return (
        <Stack sx={{ height: '100%', overflowY: 'auto' }}>
            {modules.map((module: AnyModuleData) => (
                <Module
                    key={module.type}
                    sectionsData={Object.values(module.sections) as AnySectionData[]}
                    moduleDefinition={moduleDefinitions[module.type] as AnyModuleDefinition}
                />
            ))}
        </Stack>
    );
};

export default Modules;
