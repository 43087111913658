import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { UndocumentedCall, UndocumentedCallsResponse } from '~/types/call/call';

export type UndocumentedCalls = NonNullable<ReturnType<typeof useGetUndocumentedCalls>['data']>;

interface UseGetUndocumentedCallsParams {
    enabled?: boolean;
    shiftDay?: DateTime;
    shiftId?: number;
    careLocationIds?: number[];
}

export const useGetUndocumentedCalls = ({
    enabled,
    shiftDay,
    shiftId,
    careLocationIds,
}: UseGetUndocumentedCallsParams) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                shiftId,
                shiftDay: shiftDay?.toFormat('yyyy-MM-dd'),
                careLocationIds: careLocationIds?.join(',') || undefined,
            },
        };

        const { data } = await api.get<UndocumentedCallsResponse>(
            `/e-call/${branchId}/care/undocumented-calls`,
            options
        );

        return data.response.map(remapCalls);
    }, [branchId, token, shiftDay, shiftId, careLocationIds]);

    return useErrorHandledQuery({
        queryKey: ['undocumented-calls', branchId, shiftDay, shiftId, careLocationIds],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && enabled && !!shiftDay && !!shiftId,
    });
};

const remapCalls = (call: UndocumentedCall) => {
    return {
        ...call,
        triggeredAt: fromISO(call.triggeredAt) as DateTime<true>,
        completedAt: fromISO(call.completedAt) as DateTime<true>,
    };
};

const fromISO = (isoDate: string) => DateTime.fromISO(isoDate, { setZone: true });
