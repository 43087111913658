import CareChanges from './CareChanges';
import CareChargeGaps from './CareChargeGaps';
import ChangeOfCondition from './ChangeOfCondition';
import DistributionOfLevels from './DistributionOfLevels';
import OnDemandCare from './OnDemandCare';

export default {
    DistributionOfLevels,
    CareChanges,
    CareChargeGaps,
    OnDemandCare,
    ChangeOfCondition,
};
