import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

const seedAtom = atom<number>(0);

type MessageType = 'DOCUMENTATION_FLOW' | 'NOTIFICATION_FLOW';

type Message = {
    title: string;
    description: string;
    answer: string;
};

const randomMessages: Record<MessageType, Message[]> = {
    NOTIFICATION_FLOW: [
        {
            title: 'Have a nice day!',
            description: 'Your hard work and care makes all the difference.',
            answer: 'I will',
        },
        {
            title: 'You got this!',
            description: 'Your hard work and care makes all the difference.',
            answer: 'I know',
        },
    ],
    DOCUMENTATION_FLOW: [
        {
            title: "You're amazing!",
            description: "Thank you for making resident's day brighter.",
            answer: "I know I'm amazing!",
        },
        {
            title: 'You rock!',
            description: 'Thank you for caring for the resident and sharing critical insights.',
            answer: "You're welcome",
        },
        {
            title: 'That was so kind!',
            description: "Thank you for spreading love and brightening resident's day.",
            answer: "It's what I do",
        },
    ],
};

export const useRandomSuccessMessage = (messageType: MessageType, messageKey?: number) => {
    const [seed, setSeed] = useAtom(seedAtom);

    const messages = useMemo(() => randomMessages[messageType], [messageType]);

    const message = useMemo(
        () => (messageKey ? messages[messageKey] : messages[seed % messages.length]),
        [messageKey, messages, seed]
    );

    const resetSeed = useCallback(() => {
        const randomSeed = Math.floor(Math.random() * messages.length);

        setSeed(randomSeed);
    }, [setSeed]);

    return { message, resetSeed };
};
