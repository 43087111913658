import { useMemo } from 'react';

import { BRANCH_CARE_LOCATION_TYPE } from '@allie/utils/src/constants/branch.constants';

import { useCareLocationType } from '~/api/common';
import { useGetAssignments } from '~/api/queries/assignments/getAssignments';
import { useGetCareLocations } from '~/api/queries/assignments/getCareLocations';
import { useGetZones } from '~/api/queries/zones/getZones';
import { useShiftStartDay } from '~/pages/Home/useShiftStartDay';

interface UseLocationsProps {
    shiftId?: number;
    shiftDay?: string;
    branchId?: number;
}

export const useLocations = (props: UseLocationsProps) => {
    const { careLocationType } = useCareLocationType();
    const shiftDay = useShiftStartDay(props.shiftDay, props.shiftId);

    const { data: zones, isLoading: isGetZonesLoading } = useGetZones({
        shiftId: props.shiftId,
        shiftDay,
        branchId: props.branchId,
    });
    const { data: assignments, isLoading: isGetAssignmentsLoading } = useGetAssignments({
        enabled: !!props.shiftId && !!shiftDay,
        shiftId: props.shiftId,
        shiftDay,
        branchId: props.branchId,
    });
    const { data: careLocations, isLoading: isGetCareLocationsLoading } = useGetCareLocations();

    const locations = useMemo(() => {
        switch (careLocationType) {
            case BRANCH_CARE_LOCATION_TYPE.ZONE:
                return zones?.map((zone) => ({ id: zone.zoneId, name: zone.zoneName })) ?? [];
            case BRANCH_CARE_LOCATION_TYPE.ASSIGNMENT:
                return (
                    assignments?.map((assignment) => ({
                        id: assignment.assignmentId,
                        name: assignment.assignmentName,
                    })) ?? []
                );
            case BRANCH_CARE_LOCATION_TYPE.CUSTOM:
                return careLocations ?? [];
            default:
                return [];
        }
    }, [zones, assignments, careLocations, careLocationType]);

    const isLoading = isGetZonesLoading || isGetAssignmentsLoading || isGetCareLocationsLoading;

    return { locations, isLoading };
};
