import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import NewResidentChip from '~/pages/OperationsV2/components/Module/Section/NewResidentChip';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'BRANCH', 'CALL_BY_DETAIL_AND_RESIDENT', 'CARE_CHANGES'>['items'][number];

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    return (
        <DataContainer>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                        weight: 2,
                        renderCell: (value, { row }) => (
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <Typography>{value}</Typography>
                                {row.isNewResident && <NewResidentChip />}
                            </Stack>
                        ),
                    },
                    callCount: {
                        label: 'Call Count',
                        alignment: 'right',
                    },
                    changePercentage: {
                        label: 'Change',
                        alignment: 'right',
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                }}
                rows={items}
                takeFirst={3}
                seeMore={() => {}}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
