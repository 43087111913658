import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { PiClipboard } from 'react-icons/pi';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import CustomDrawer from '~/components/Custom/CustomDrawer';
import WithSafePadding from '~/components/Layout/WithSafePadding';
import { ALL_LOCATIONS_ID } from '~/constants/filters';

import { LocationSelector } from '../../components/LocationSelectorDialog/LocationSelector';

const IconWrapper = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    padding: '32px',
    backgroundColor: theme.palette.secondary[50] as string,
    borderRadius: '50%',
    marginBottom: '48px',
    marginInline: 'auto',
}));

const Content = styled(Box)(() => ({
    padding: '24px',
    overflowY: 'auto',
}));

interface AssignmentsModalProps {
    isOpen: boolean;
    shiftDay: string;
    shiftId: number;
    onClose: () => void;
    onSubmit: () => void;
}

export const AssignmentsModal = ({ isOpen, onClose, onSubmit, shiftDay, shiftId }: AssignmentsModalProps) => {
    const { palette } = useTheme();

    const { data: careLocationsIds } = useGetChosenCareLocations();
    const { mutateAsync: saveChosenLocations, isPending } = useSaveChosenCareLocations();

    const handleSubmit = async (selectedIds: number[]) => {
        await saveChosenLocations(selectedIds.filter((id) => id !== ALL_LOCATIONS_ID));

        onSubmit();
    };

    return (
        <CustomDrawer fullScreen isOpen={isOpen} onClose={onClose}>
            <WithSafePadding sx={{ display: 'flex', flexDirection: 'column' }}>
                <Content>
                    <IconWrapper>
                        <PiClipboard size={32} color={palette.secondary[500] as string} />
                    </IconWrapper>

                    <Typography
                        variant="h1"
                        fontWeight={700}
                        fontSize="24px"
                        lineHeight="33px"
                        color={palette.grey[900]}
                    >
                        What are your assignments for this shift?
                    </Typography>
                </Content>

                <LocationSelector
                    onSubmit={handleSubmit}
                    defaultValue={careLocationsIds}
                    isLoading={isPending}
                    selectedShiftDay={shiftDay}
                    selectedShiftId={shiftId}
                />
            </WithSafePadding>
        </CustomDrawer>
    );
};
