import { Box, Button, CircularProgress, Skeleton, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { PiClipboard } from 'react-icons/pi';

import CustomModal from '~/scheduling/components/CustomModal';

const ButtonContainer = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
}));

interface OnShiftConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onLogout: () => void;
    endOfShift?: boolean;
    isLoading: boolean;
    isClosing: boolean;
    isSaving: boolean;
    isSignoutLoading: boolean;
}

export const OnShiftConfirmationModal = ({
    isOpen,
    endOfShift,
    isLoading,
    isClosing,
    isSignoutLoading,
    isSaving,
    onClose,
    onConfirm,
    onLogout,
}: OnShiftConfirmationModalProps) => {
    const { palette } = useTheme();

    const disabled = isLoading || isClosing || isSignoutLoading || isSaving;

    return (
        <CustomModal isOpen={isOpen} onClose={onClose} center>
            <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                alignItems="center"
                padding="24px"
                borderRadius="12px"
            >
                <Box padding="32px" bgcolor={palette.secondary[50] as string} borderRadius="50%">
                    <PiClipboard size={36} color={palette.secondary[500] as string} />
                </Box>
                {isLoading ? (
                    <>
                        <Skeleton variant="text" width="210px" height="24px" sx={{ transform: 'none' }} />
                        <Box display="flex" width="100%" flexDirection="column" gap="8px">
                            <Skeleton variant="rounded" width="100%" height="46px" />
                            <Skeleton variant="rounded" width="100%" height="46px" />
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="h1" fontWeight={700} fontSize="20px" lineHeight="24px" textAlign="center">
                            {endOfShift ? 'Your shift is over! Are you on the next shift?' : 'Are you on your shift?'}
                        </Typography>
                        <ButtonContainer>
                            <Button variant="outlined" fullWidth onClick={onConfirm} disabled={disabled}>
                                {isSaving ? <CircularProgress size={24} /> : 'Yes'}
                            </Button>
                            <Button variant="outlined" fullWidth onClick={onClose} disabled={disabled}>
                                {isClosing ? <CircularProgress size={24} /> : 'No'}
                            </Button>
                            {endOfShift && (
                                <Button variant="outlined" fullWidth onClick={onLogout} disabled={disabled}>
                                    {isSignoutLoading ? <CircularProgress size={24} /> : 'Not your account? Sign out'}
                                </Button>
                            )}
                        </ButtonContainer>
                    </>
                )}
            </Box>
        </CustomModal>
    );
};
