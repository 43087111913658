import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { ReactNode, useCallback } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { selectedDateAtom } from '~/pages/OperationsV2/atoms';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_RESPONSE_TIME', 'AVG_RESPONSE_TIME_BY', 'PERIOD'>[number];

const Data = ({ items }: { items: Item[] }) => {
    const selectedDate = useAtomValue(selectedDateAtom);

    const isDateSelected = useCallback((value: DateTime) => value.hasSame(selectedDate, 'day'), [selectedDate]);

    const renderSelected = useCallback(
        (value: ReactNode, { date }: Item) => (
            <Typography fontWeight={isDateSelected(DateTime.fromISO(date)) ? 700 : undefined}>{value}</Typography>
        ),
        [isDateSelected]
    );

    return (
        <DataContainer>
            <DataTable
                columns={{
                    date: {
                        label: 'Day',
                        renderCell: (value, { row }) => renderSelected(DateTime.fromISO(value).toFormat('MMM d'), row), // e.g. Jan 1
                    },
                    responseTimeMs: {
                        label: 'Response Time',
                        renderCell: (value, { row }) => renderSelected(formatMsToMinutes(value), row),
                    },
                    callCount: {
                        label: 'Call Count',
                        renderCell: (value, { row }) => renderSelected(value, row),
                    },
                }}
                rows={items}
            />
        </DataContainer>
    );
};

export default Data;
