import CareStaff from './CareStaff';
import Period from './Period';
import Resident from './Resident';
import Shift from './Shift';

export default {
    groupings: {
        Shift,
        Period,
        CareStaff,
        Resident,
    },
};
