import { Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_DETAIL_AND_RESIDENT', 'ALL', 'CARE_CHANGES'>[number];

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    return (
        <DataContainer>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                    },
                    changePercentage: {
                        label: 'Change %\n(call count)',
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                    callCount: {
                        label: 'Call Count\n(current)',
                    },
                    prevCallCount: {
                        label: 'Call Count\n(previous 7 days)',
                    },
                    careLevel: {
                        label: 'Care Level/\nAssessment Points',
                    },
                    assessmentDate: {
                        label: 'Most Recent\nAssessment Date',
                        renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('MMM d') : null),
                    },
                    allieCocScore: {
                        label: 'AllieHealth CoC\nScore/Alert',
                    },
                    allieNotes: {
                        label: 'AllieHealth Notes',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                    },
                }}
                rows={items}
            />
        </DataContainer>
    );
};

export default Data;
