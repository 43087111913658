import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNextShift, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchId } from '~/api/common';
import { useBranchQuery } from '~/api/queries/branch';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

import { currentShiftEndsAtAtom, isOnCurrentShiftAtom } from '../atom';

import { getCurrentShiftBarrier } from './helpers';

export const MINUTES_BEFORE_SHIFT_ENDS = 15;
export const MINUTES_AFTER_SHIFT_ENDS = 15;

export const useShiftAboutToEnd = () => {
    const { timezone } = useSelector((state: ReduxStore) => state.session);
    const branchId = useBranchId();
    const { data: branchData } = useBranchQuery(branchId);

    const currentShift = branchData?.shifts
        ? getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchData.shifts)
        : undefined;

    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const [currentShiftEndsAt, setCurrentShiftEndsAt] = useAtom(currentShiftEndsAtAtom);
    const [isEndOfShiftWarningModalOpen, setIsEndOfShiftWarningModalOpen] = useState(false);
    // this ref is used to prevent the modal from being opened multiple times
    const isModalOpen = useRef(false);

    const parsedCurrentShiftEndsAt = useMemo(() => {
        if (currentShiftEndsAt) {
            return DateTime.fromISO(currentShiftEndsAt);
        }

        // this will be fulfilled when the user have just logged in
        if (currentShift) {
            return getCurrentShiftBarrier(currentShift.shift).minus({ minutes: MINUTES_BEFORE_SHIFT_ENDS });
        }

        return undefined;
    }, [currentShift, currentShiftEndsAt]);

    // there is a range of time that the modal will be shown
    const checkIfCurrentShiftIsAboutToEnd = useCallback(() => {
        if (!parsedCurrentShiftEndsAt) {
            return 0;
        }

        return parsedCurrentShiftEndsAt.diffNow().toMillis();
    }, [parsedCurrentShiftEndsAt, isOnCurrentShift]);

    // this function should be used to push the next modal opening to end of the next shift
    // thus, it should be called when the modal have just been closed
    const updateNextShiftEndTime = useCallback(() => {
        if (!currentShift || !branchData) return;

        const nextShift = getNextShift(currentShift.shift.id, branchData.shifts, currentShift.shiftDayDate);

        const nextShiftBarrier = getCurrentShiftBarrier(nextShift.shiftOption);

        const nextShiftEndTime = nextShiftBarrier.minus({ minutes: MINUTES_BEFORE_SHIFT_ENDS });

        setCurrentShiftEndsAt(nextShiftEndTime.toISO());
    }, [parsedCurrentShiftEndsAt, isOnCurrentShift]);

    // this function should be used to set the next modal opening to the end of the current shift
    // thus, it should be called when the user have just opened the app and the set that they are on the current shift
    const updateCurrentShiftEndTime = useCallback(() => {
        if (!currentShift) return;

        const endShiftDateTime = getCurrentShiftBarrier(currentShift.shift);

        const nextVerificationTime = endShiftDateTime.minus({ minutes: MINUTES_BEFORE_SHIFT_ENDS });

        setCurrentShiftEndsAt(nextVerificationTime.toISO());
    }, [currentShift, setCurrentShiftEndsAt]);

    const toggleEndOfShiftWarningModal = useCallback(() => {
        setIsEndOfShiftWarningModalOpen((prev) => !prev);
    }, [setIsEndOfShiftWarningModalOpen]);

    useEffect(() => {
        // skip if the user is not on the current shift
        // or if the current shift end time is not set - prevents the modal from being
        // opened while notification flow is still running
        if (!isOnCurrentShift || !currentShiftEndsAt) {
            return;
        }

        const nextVerificationTime = checkIfCurrentShiftIsAboutToEnd();

        const showModal = () => {
            // if the end of the shift is in the past, the `nextVerificationTime` will be negative
            const timePassedEdge = nextVerificationTime + MINUTES_BEFORE_SHIFT_ENDS * 60 * 1000;
            const isWithinRange = Math.abs(timePassedEdge) < MINUTES_AFTER_SHIFT_ENDS * 60 * 1000;

            if (!isModalOpen.current && isOnCurrentShift && isWithinRange) {
                toggleEndOfShiftWarningModal();
                isModalOpen.current = true;
            } else if (timePassedEdge < 0 && !isWithinRange) {
                // if the user opens the app after after 15 minutes of the shift ending,
                // we need to update the next shift end time
                updateNextShiftEndTime();
            }
        };

        // run when the component is mounted
        if (nextVerificationTime <= 0) {
            return showModal();
        }

        const timeoutId = setTimeout(showModal, nextVerificationTime);

        return () => clearTimeout(timeoutId);
    }, [isOnCurrentShift, checkIfCurrentShiftIsAboutToEnd, toggleEndOfShiftWarningModal]);

    const closeModal = () => {
        isModalOpen.current = false;
        updateNextShiftEndTime();
        toggleEndOfShiftWarningModal();
    };

    const stopEndOfShiftWarning = () => {
        isModalOpen.current = false;
        setCurrentShiftEndsAt(null);
    };

    return {
        isEndOfShiftWarningModalOpen,
        endOfShiftTime: parsedCurrentShiftEndsAt?.plus({ minutes: MINUTES_BEFORE_SHIFT_ENDS }),
        closeModal,
        updateCurrentShiftEndTime,
        stopEndOfShiftWarning,
    };
};
