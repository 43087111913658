import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { CustomBottomSheet } from '~/components/Custom/CustomBottomSheet';

type Data = DetailsGroupingDataType<
    'REGION',
    'RISK_AND_COMPLIANCE',
    'POTENTIAL_UNRECORDED_FALLS',
    'ALL'
>['communityItems'][number];
type FallNote = Data['details'][number]['fallRelatedNotes'][number];

interface FallNoteBottomSheetProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    note: FallNote | null;
    caption?: string;
}

export const FallNoteBottomSheet = ({ isOpen, onClose, title, note, caption }: FallNoteBottomSheetProps) => {
    return (
        <CustomBottomSheet isOpen={isOpen} onClose={onClose} title={title} caption={caption}>
            <Stack direction="row" justifyContent="space-between">
                {/* Dec 28, 2024 (Mon) */}
                <Typography variant="body1" sx={({ palette }) => ({ color: palette.grey[500] })}>
                    {note ? DateTime.fromISO(note.timestamp).toFormat('MMM d, yyyy (ccc)') : ''}
                </Typography>
                {/* 3:15 am */}
                <Typography variant="body1" sx={({ palette }) => ({ color: palette.grey[500] })}>
                    {note ? DateTime.fromISO(note.timestamp).toFormat('h:mm a') : ''}
                </Typography>
            </Stack>
            <Typography variant="body1">{note?.note}</Typography>
            <Typography variant="body1" sx={({ palette }) => ({ color: palette.grey[500] })}>
                {note?.staffName}
            </Typography>
        </CustomBottomSheet>
    );
};
