import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { documentationResidentDataAtom } from '~/pages/Home/eCallComponents/atom';

import { useRandomSuccessMessage } from '../../../useRandomSuccessMessage';
import { useDuplicateSteps } from '../hooks/useDuplicateSteps';
import { useEmergencyFlow } from '../hooks/useEmergencyFlow';
import { UnscheduledTaskDialogSteps } from '../types/unscheduledTaskDialogSteps';

type FooterProps = {
    step: UnscheduledTaskDialogSteps;
    onAddPRN: () => void;
    onNextStep: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    onClose: () => void;
    onBack?: () => void;
};

const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    gap: '12px',
}));

export default function Footer({ step, onAddPRN, onNextStep, onClose, onBack, isDisabled, isLoading }: FooterProps) {
    const { shouldDuplicateStep } = useDuplicateSteps();
    const { isEmergencyStep, isEmergencyFlow, hasEmergencyCategory } = useEmergencyFlow();
    const { message } = useRandomSuccessMessage('NOTIFICATION_FLOW', hasEmergencyCategory ? 1 : undefined);
    const residentData = useAtomValue(documentationResidentDataAtom);

    const isSelectResidentStep = step === UnscheduledTaskDialogSteps.SELECT_RESIDENT;
    const isTheLastStep = step === UnscheduledTaskDialogSteps.CONFETTI;
    const isSelectCategoryStep = step === UnscheduledTaskDialogSteps.SELECT_CATEGORY;

    const shouldComeback = !isTheLastStep && !isSelectResidentStep && !(residentData && isSelectCategoryStep);

    const renderButtonContent = (text: string) =>
        isLoading ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            <Typography fontWeight="bold" fontSize="16px" color="inherit">
                {text}
            </Typography>
        );

    const handleClick = () => {
        if (isEmergencyStep(step) && isEmergencyFlow) {
            return onAddPRN();
        }

        if (step === UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX && !shouldDuplicateStep('next')) {
            return onAddPRN();
        }

        if (step === UnscheduledTaskDialogSteps.CONFETTI) {
            return onClose();
        }

        onNextStep();
    };

    return (
        <FooterContainer>
            {isEmergencyStep(step) && (
                <Typography variant="body2" color={({ palette }) => palette.grey[900]} alignSelf="center">
                    {"Please go through your community's EHR process."}
                </Typography>
            )}

            <Box display="flex" justifyContent="flex-end" gap="8px" width="100%">
                {shouldComeback && (
                    <Button fullWidth onClick={onBack} variant="outlined" disabled={isLoading}>
                        Back
                    </Button>
                )}

                <Button onClick={handleClick} color="primary" variant="contained" fullWidth disabled={isDisabled}>
                    {renderButtonContent(step === UnscheduledTaskDialogSteps.CONFETTI ? message.answer : 'Next')}
                </Button>
            </Box>
        </FooterContainer>
    );
}
