import AvgResponseTime from './AvgResponseTime';
import DocumentationRate from './DocumentationRate';
import Incidents from './Incidents';
import LongestResponseTime from './LongestResponseTime';
import Medication from './Medication';
import OverdueAssessmentsAndIncompleteFalls from './OverdueAssessmentsAndIncompleteFalls';
import PotentialUnrecordedFalls from './PotentialUnrecordedFalls';
import TotalCalls from './TotalCalls';
import Transfers from './Transfers';

export default {
    AvgResponseTime,
    LongestResponseTime,
    TotalCalls,
    Incidents,
    Medication,
    Transfers,
    DocumentationRate,
    OverdueAssessmentsAndIncompleteFalls,
    PotentialUnrecordedFalls,
};
