import {
    Box,
    IconButton,
    LinearProgress,
    IconButton as MuiIconButton,
    Stack,
    Typography,
    linearProgressClasses,
    styled,
    useTheme,
} from '@mui/material';
import { ArrowLeft, X } from '@phosphor-icons/react';
import React, { ComponentProps } from 'react';

type HeaderProps = {
    isFirstStep: boolean;
    onBack: () => void;
    onClose: () => void;
    progress: number;
};

const StepProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: '2px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.primary[500] as string,
    },
}));

const HeaderContainerBox = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    justifyContent: 'space-between',
    maxHeight: '70px',
    padding: '24px',
}));

const CloseButton = ({ ...props }: ComponentProps<typeof MuiIconButton>) => {
    const { palette } = useTheme();

    return (
        <IconButton {...props}>
            <X color={palette.grey[900]} weight="bold" size={14} />
        </IconButton>
    );
};

const Header = ({ progress, onBack, onClose, isFirstStep }: Readonly<HeaderProps>) => {
    const { palette } = useTheme();

    return (
        <>
            <HeaderContainerBox>
                <Box display="flex" flexDirection="row" gap="12px" maxHeight="22px">
                    {!isFirstStep && (
                        <IconButton size="small" onClick={onBack}>
                            <ArrowLeft weight="bold" color={palette.grey[400]} />
                        </IconButton>
                    )}
                    <Typography
                        color={palette.grey[900]}
                        fontSize="16px"
                        fontWeight={700}
                        lineHeight="22.4px"
                        variant="body1"
                    >
                        How was your shift?
                    </Typography>
                </Box>
                <CloseButton onClick={onClose} />
            </HeaderContainerBox>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <StepProgressBar variant="determinate" value={progress} />
            </Stack>
        </>
    );
};

export default Header;
