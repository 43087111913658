import AmazingEmoji from '~/assets/emoji/amazing.png';
import BadEmoji from '~/assets/emoji/bad.png';
import GoodEmoji from '~/assets/emoji/good.png';
import NeutralEmoji from '~/assets/emoji/neutral.png';
import NotGreatEmoji from '~/assets/emoji/notGreat.png';

export type RatesLabels = 'Amazing' | 'Good' | 'Neutral' | 'Not great' | 'Bad';

export type ShiftRating = {
    id: number;
    label: RatesLabels;
    value: number;
    icon: string;
};

export const shiftRatingOptions: ShiftRating[] = [
    {
        id: 1,
        value: 10,
        label: 'Amazing',
        icon: AmazingEmoji,
    },
    {
        id: 2,
        value: 8,
        label: 'Good',
        icon: GoodEmoji,
    },
    {
        id: 3,
        value: 6,
        label: 'Neutral',
        icon: NeutralEmoji,
    },
    {
        id: 4,
        value: 4,
        label: 'Not great',
        icon: NotGreatEmoji,
    },
    {
        id: 5,
        value: 2,
        label: 'Bad',
        icon: BadEmoji,
    },
];
