import { Box, styled } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import React from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

import SeeMoreButton from '../SeeMore';

const ROW_HEIGHT_PX = 44;

export type Item = Record<string, string | number | boolean | null>;

type DataTableColumn<T extends Item, K extends keyof T> = {
    label: string;
    alignment?: 'left' | 'center' | 'right';
    weight?: number;
    renderCell?: (cell: T[K], { row, rowIndex }: { row: T; rowIndex: number }) => ReactNode;
};

const DataTableContainer = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    padding: '24px 24px 8px',
    borderRadius: '16px',
}));

const DataTableHeader = <T extends Item>({ columns }: Pick<DataTableProps<T>, 'columns'>) => {
    const columnEntries = Object.entries(columns) as [keyof T, DataTableColumn<T, keyof T>][];

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            {columnEntries.map(([, { label, alignment, weight }], columnIndex) => (
                <Typography
                    key={columnIndex}
                    variant="body2"
                    fontWeight={700}
                    pb="8px"
                    flex={weight ?? 1}
                    textAlign={alignment ?? 'left'}
                    sx={({ palette }) => ({
                        color: palette.grey[500],
                    })}
                >
                    {label}
                </Typography>
            ))}
        </Stack>
    );
};

const DataTableRow = <T extends Item>({
    columns,
    row,
    rowIndex,
}: Pick<DataTableProps<T>, 'columns'> & { row: T; rowIndex: number }) => {
    const columnEntries = Object.entries(columns) as [keyof T, DataTableColumn<T, keyof T>][];

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                gap: '8px',
            }}
        >
            {columnEntries.map(([key, column], keyIndex) => {
                const { alignment = 'left', weight = 1, renderCell = (value) => value } = column;
                const cell = renderCell(row[key], { row, rowIndex });
                return (
                    <Box
                        key={keyIndex}
                        sx={{
                            flex: weight,
                            display: 'flex',
                            justifyContent: alignment,
                            alignItems: 'center',
                            minHeight: `${ROW_HEIGHT_PX}px`,
                        }}
                    >
                        {typeof cell !== 'object' ? (
                            <SingleLineTypography sx={{ textAlign: alignment }}>{cell}</SingleLineTypography>
                        ) : (
                            cell
                        )}
                    </Box>
                );
            })}
        </Stack>
    );
};

const DataTableSeparator = styled(Box)(({ theme: { palette } }) => ({
    borderBottom: '1px dashed',
    borderColor: palette.primary[300] as string,
}));

type DataTableProps<T extends Item> = {
    columns: { [K in keyof T]?: DataTableColumn<T, K> };
    rows: T[];
    takeFirst?: number;
    takeLast?: number;
    seeMore?: () => void;
    containerProps?: ComponentProps<typeof Stack>;
};

const DataTable = <T extends Item>({
    columns,
    rows,
    takeFirst = 0,
    takeLast = 0,
    seeMore,
    containerProps,
}: DataTableProps<T>) => {
    const shouldSplit = !!takeFirst && !!takeLast && takeFirst + takeLast < rows.length; // Do not split if there aren't enough items

    return (
        <DataTableContainer {...containerProps}>
            <DataTableHeader columns={columns} />

            {/* Render takeFirst rows or all rows if not splitting (either because
            takeLast wasn't specified or because there aren't enough items) */}
            {(!!takeFirst || !takeLast) &&
                rows
                    .slice(0, takeFirst && (!takeLast || shouldSplit) ? takeFirst : rows.length)
                    .map((row, rowIndex) => (
                        <DataTableRow key={rowIndex} columns={columns} row={row} rowIndex={rowIndex} />
                    ))}

            {shouldSplit && <DataTableSeparator />}

            {/* Render takeLast rows if specified and there's no takeFirst (only
            render last items) or there are enough items for splitting */}
            {!!takeLast && (!takeFirst || shouldSplit) && (
                <>
                    {rows.slice(-takeLast).map((row, rowIndex) => (
                        <DataTableRow
                            key={rowIndex}
                            columns={columns}
                            row={row}
                            rowIndex={rowIndex + rows.length - takeLast}
                        />
                    ))}
                </>
            )}

            {seeMore && <SeeMoreButton onClick={seeMore} sx={{ minHeight: `${ROW_HEIGHT_PX}px` }} />}
        </DataTableContainer>
    );
};

export default DataTable;
