import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';

import { ShiftOption } from '@allie/utils/src/shifts';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { ALL_LOCATIONS_ID } from '~/constants/filters';
import { useLocations } from '~/hooks/useLocations';
import { ignoreProps } from '~/lib/styled';
import { usePermissions } from '~/permissions/utils';
import { DailyTasksByTabStrict, TabKey } from '~/types/dailyTasks.d';

import {
    isFiltersExpandedAtom,
    isLocationSelectorDialogOpenAtom,
    isShiftSelectorDialogOpenAtom,
    isSortDialogOpenAtom,
    locationIdsAtom,
    selectedDateAtom,
} from '../../atom';
import { isShiftInDate } from '../../helpers';
import { TabSelector } from '../TabSelector';

import HeaderChip from './components/HeaderChip';
import { SortButton } from './components/SortButton';
import DatePicker from './components/filters/date/DatePicker';

type FiltersContainerStyledProps = {
    showFilters: boolean;
};

const MAX_DAYS_TO_SHOW = 3;

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.common.white,
    },
}));

const Content = styled(Box)(({ theme }) => ({
    padding: '12px 16px 8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
    },
}));

const HeaderRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
}));

const FiltersContainer = styled(
    Box,
    ignoreProps<FiltersContainerStyledProps>('showFilters')
)<FiltersContainerStyledProps>(({ theme, showFilters }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    maxHeight: showFilters ? '124px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 300ms ease-in-out',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
    },
}));

type HeaderV2Props = {
    selectedTab: TabKey;
    tasksByTab: DailyTasksByTabStrict;
    onTabChange: (tab: TabKey) => void;
    onShiftChange: (shiftId: number) => void;
    selectedShiftDay: string;
    currentShiftDayDate: string;
    selectedShiftId: number;
    nearbyShifts: ShiftOption[];
    currentShiftId: number;
    selectedBranchId?: number;
};

export default function HeaderV2({
    selectedTab,
    tasksByTab,
    onTabChange,
    onShiftChange,
    selectedShiftDay,
    currentShiftDayDate,
    nearbyShifts,
    selectedShiftId,
    currentShiftId,
    selectedBranchId,
}: HeaderV2Props) {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));
    const hasPermission = usePermissions();

    const { locations } = useLocations({
        shiftId: selectedShiftId,
        shiftDay: selectedShiftDay,
        branchId: selectedBranchId,
    });

    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
    const [locationIds, setLocationIds] = useAtom(locationIdsAtom);
    const toggleShiftSelectorDialog = useSetAtom(isShiftSelectorDialogOpenAtom);
    const toggleLocationSelectorDialog = useSetAtom(isLocationSelectorDialogOpenAtom);
    const toggleSortDialog = useSetAtom(isSortDialogOpenAtom);
    const isFiltersExpanded = useAtomValue(isFiltersExpandedAtom);
    const showFilters = !isMobile || isFiltersExpanded;

    const { data: chosenCareLocations } = useGetChosenCareLocations(selectedTab === TabKey.LIVE_CALLS);

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate);

    useEffect(() => {
        // this ensures that if the storage locationIds are not in the locations,
        // we reset them setting to ALL_LOCATIONS_ID
        const allLocationsIds = locations?.map(({ id }) => id) ?? [];
        if (locations && locationIds?.some((id) => !allLocationsIds.includes(id))) {
            setLocationIds([ALL_LOCATIONS_ID]);
        }
    }, [locations]);

    const handleDateChange = (newDate: DateTime<true> | null) => {
        if (newDate) {
            setSelectedDate(newDate);

            // changing to the current shift when the date changes to
            // today to avoid selecting shifts too far in the future
            if (newDate.hasSame(DateTime.now(), 'day')) {
                onShiftChange(currentShiftId);
            }
        }
    };

    const selectedShiftsLabel = useMemo(() => {
        const selectedShiftName = nearbyShifts.find((shift) => shift.id === selectedShiftId)?.name;
        const [previousShift, currentShift, nextShift] = nearbyShifts;

        let selectedShiftTab = '';

        if (isShiftInDate(currentShift, selectedDate)) {
            if (selectedShiftId === currentShift.id) selectedShiftTab = '(current)';
            if (selectedShiftId === previousShift.id) selectedShiftTab = '(previous)';
            if (selectedShiftId === nextShift.id) selectedShiftTab = '(next)';
        }

        return selectedShiftName ? `${selectedShiftName} ${selectedShiftTab}` : '';
    }, [currentShiftId, nearbyShifts, selectedShiftId]);

    const selectedLocationsLabel = useMemo(() => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            return (
                locations
                    .filter(({ id }) => chosenCareLocations?.includes(id))
                    .map(({ name }) => name)
                    .join(', ') || 'No locations'
            );
        }

        if (locationIds.includes(ALL_LOCATIONS_ID)) {
            return locations.map(({ name }) => name).join(', ') || 'No locations';
        }

        const selectedLocations = locations.filter(({ id }) => locationIds.includes(id));

        return selectedLocations.map(({ name }) => name).join(', ') || 'No locations';
    }, [locations, locationIds, selectedTab, chosenCareLocations]);

    return (
        <Container>
            <Content>
                <HeaderRow>
                    <DatePicker
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                        minDate={
                            hasPermission('Community', 'read-all-resident-actions')
                                ? undefined
                                : DateTime.now().minus({ days: MAX_DAYS_TO_SHOW })
                        }
                        maxDate={currentShiftDateTzDateTime.isValid ? currentShiftDateTzDateTime : DateTime.now()}
                        hideArrows={selectedTab === TabKey.LIVE_CALLS}
                        enabled={selectedTab !== TabKey.LIVE_CALLS}
                    />
                </HeaderRow>
                <FiltersContainer showFilters={showFilters}>
                    <HeaderRow order={1}>
                        <HeaderChip
                            title="Shift"
                            label={selectedShiftsLabel}
                            isDisabled={selectedTab === TabKey.LIVE_CALLS}
                            onClick={toggleShiftSelectorDialog}
                        />
                    </HeaderRow>
                    <Box
                        order={isMobile ? 3 : 2}
                        sx={{ width: { xs: '100%' }, maxWidth: { sm: '210px', md: '350px' } }}
                    >
                        <HeaderRow width="fit-content">
                            <HeaderChip
                                title={selectedTab === TabKey.LIVE_CALLS ? 'Call Location' : 'Filter'}
                                isDisabled={selectedTab === TabKey.LIVE_CALLS}
                                label={selectedLocationsLabel}
                                onClick={toggleLocationSelectorDialog}
                            />
                        </HeaderRow>
                    </Box>
                    <HeaderRow order={isMobile ? 2 : 3} marginLeft="auto">
                        <SortButton onClick={toggleSortDialog} />
                    </HeaderRow>
                </FiltersContainer>
            </Content>
            <TabSelector
                tabs={tasksByTab}
                selectedTab={selectedTab}
                onTabChange={onTabChange}
                shiftDay={selectedDate ?? undefined}
                shiftId={selectedShiftId}
            />
        </Container>
    );
}
