import { DateTime } from 'luxon';

import { ShiftOption } from '@allie/utils/src/shifts';

const parseDate = (date: string) => DateTime.fromFormat(date, 'HH:mm:ss', { setZone: true });
const toMillis = (time: string) => parseDate(time).toMillis();

export const getCurrentShiftBarrier = (shift: ShiftOption) => {
    const endShiftDateTime = parseDate(shift.endTimeExclusive);

    const shiftStartInMilliseconds = toMillis(shift.startTimeInclusive);
    const shiftEndInMilliseconds = toMillis(shift.endTimeExclusive);
    const currentTimeInMilliseconds = DateTime.now().toMillis();

    // current shift starts today and ends tomorrow
    if (shiftStartInMilliseconds > shiftEndInMilliseconds) {
        // checking if it have already passed midnight to calculate the end of the shift
        const isAfterMidnight = DateTime.now().hour > 0 && currentTimeInMilliseconds < shiftEndInMilliseconds;

        return isAfterMidnight ? endShiftDateTime : endShiftDateTime.plus({ days: 1 });
    } else {
        // current shift starts and ends in the same day
        return endShiftDateTime;
    }
};
