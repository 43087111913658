import { Box, styled } from '@mui/material';

import { OngoingCalls } from '~/api/queries/call/getOngoingCalls';
import { ignoreProps } from '~/lib/styled';

type OngoingCall = OngoingCalls[number];

export interface CallCardProps extends OngoingCall {
    claimCall: (call: { callId: number }) => Promise<void>;
    isLoading: boolean;
}

export const CallCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        flexDirection: 'row',
    },
}));

export const AnimatedCallCardContainer = styled(CallCardContainer)(() => ({
    '@keyframes slideDown': {
        from: {
            opacity: 0,
            maxHeight: 0,
        },
        to: {
            opacity: 1,
            maxHeight: '380px', // max card height
        },
    },
    animation: 'slideDown 0.5s ease-out forwards',
}));

export const CallCardContentContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    borderBottom: 1,
    borderBottomColor: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        padding: '16px',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
}));

type CallCardContentProps = {
    showDetails: boolean;
};

export const CallCardContent = styled(
    Box,
    ignoreProps<CallCardContentProps>('showDetails')
)<CallCardContentProps>(({ theme, showDetails }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    padding: '16px',
    boxShadow: showDetails ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none',

    [theme.breakpoints.up('sm')]: {
        flex: 1,
        padding: '0px',
        boxShadow: 'none',
    },
}));

type StatusBadgeContainerProps = {
    claimedByUser: boolean;
};

export const StatusBadgeContainer = styled(
    Box,
    ignoreProps<StatusBadgeContainerProps>('claimedByUser')
)<StatusBadgeContainerProps>(({ theme, claimedByUser }) => ({
    display: 'flex',
    padding: '8px',
    borderRadius: '8px',
    justifyContent: 'space-between',
    gap: '16px',
    backgroundColor: claimedByUser ? (theme.palette.secondary[200] as string) : theme.palette.grey[100],
    marginTop: '8px',

    [theme.breakpoints.up('sm')]: {
        marginTop: '0px',
        width: claimedByUser ? 'fit-content' : '245px',
    },
}));
