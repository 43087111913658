import { Divider, Stack, Typography } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { CustomBottomSheet } from '~/components/Custom/CustomBottomSheet';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_RESPONSE_TIME', 'LONGEST_RESPONSE_TIME', 'ALL'>[number];

const NotesDetails = ({ item }: { item?: Item['managerNotes'] | null }) => {
    if (!item) {
        return null;
    }

    return (
        <>
            <Stack gap="12px">
                <Typography variant="body1" lineHeight="12px">
                    {item.residentName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    {item.staffName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    Response Time: {formatMsToMinutes(item.responseTimeMs)}
                </Typography>
            </Stack>
            {/* TODO: implement option to add notes */}
            {item.allNotes.length > 0 && (
                <>
                    <Divider />
                    <Stack gap="12px">
                        {/* TODO: add task name */}
                        <Typography variant="body2" lineHeight="12px">
                            {item.allNotes[0].timestamp}
                        </Typography>
                        <Typography variant="body1">{item.allNotes[0].text}</Typography>
                    </Stack>
                </>
            )}
        </>
    );
};

const Data = ({ items }: { items: Item[] }) => {
    const [isNotesBottomSheetOpen, setIsNotesBottomSheetOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);

    const toggleNotesBottomSheet = (row: Item | null) => {
        setSelectedItem(row);
        setIsNotesBottomSheetOpen(!isNotesBottomSheetOpen);
    };

    return (
        <DataContainer>
            <DataTable
                columns={{
                    responseTimeMs: {
                        label: 'Response Time',
                        renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                    },
                    residentName: {
                        label: 'Resident',
                    },
                    staffName: {
                        label: 'Care Staff',
                    },
                    timestamp: {
                        label: 'Timestamp',
                        renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('h:mm a, MMM d (EEE)') : null), // e.g. 1:23 PM, Jan 1 (Fri)
                    },
                    managerNotes: {
                        label: 'Manager Notes',
                        renderCell: (value, { row }) =>
                            value?.allNotes?.length ? (
                                <Notes
                                    notes={value.allNotes.map((note) => note.text).join('\n')}
                                    maxWidth="200px"
                                    onSeeMoreClick={() => toggleNotesBottomSheet(row)}
                                />
                            ) : (
                                <AddNotesButton onAddNote={() => toggleNotesBottomSheet(row)} />
                            ),
                    },
                }}
                rows={items}
            />
            <CustomBottomSheet
                isOpen={isNotesBottomSheetOpen}
                onClose={() => toggleNotesBottomSheet(null)}
                title="Manager Notes"
                Icon={Note}
            >
                <NotesDetails item={selectedItem?.managerNotes} />
            </CustomBottomSheet>
        </DataContainer>
    );
};

export default Data;
