import { Skeleton, Stack, useTheme } from '@mui/material';
import { range } from 'lodash';
import React from 'react';

export const LoadingTasks = () => {
    const { palette } = useTheme();

    return range(4).map((index) => (
        <Stack
            key={index}
            direction="row"
            spacing="12px"
            padding="16px"
            alignItems="center"
            borderBottom={`1px solid ${palette.grey[50]}`}
        >
            <Skeleton variant="circular" height="24px" width="24px" />
            <Skeleton variant="circular" height="64px" width="64px" />
            <Stack flex={1} spacing="2px">
                <Skeleton variant="rectangular" height="20px" width="100px" />
                <Skeleton variant="rectangular" height="20px" width="55px" />
            </Stack>
            <Skeleton variant="circular" height="32px" width="32px" />
        </Stack>
    ));
};
