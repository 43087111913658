import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import React from 'react';

import { ShiftRatingDialogSteps } from '../shiftRatingDialogSteps';

type FooterProps = {
    isDisabled?: boolean;
    isFirstStep: boolean;
    isLastStep: boolean;
    isLoading?: boolean;
    onAddShiftRating: () => void;
    onBack: () => void;
    onComplete: () => void;
    onNextStep: () => void;
    step: ShiftRatingDialogSteps;
};

const FooterContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'space-between',
    padding: '16px',
}));

export default function Footer({
    isDisabled,
    isFirstStep,
    isLastStep,
    isLoading,
    onAddShiftRating,
    onBack,
    onComplete,
    onNextStep,
    step,
}: FooterProps) {
    const renderButtonContent = (text: string) =>
        isLoading ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            <Typography fontWeight="bold" fontSize="16px" color="inherit">
                {text}
            </Typography>
        );

    const handleClick = () => {
        if (isFirstStep) {
            onNextStep();
        }
        if (step === ShiftRatingDialogSteps.ADDITIONAL_COMMENT_BOX) {
            return onAddShiftRating();
        }
        if (isLastStep) {
            return onComplete();
        }
    };

    return (
        <FooterContainer>
            <Box display="flex" justifyContent="flex-end" gap="8px" width="100%">
                {!isFirstStep && !isLastStep ? (
                    <Button fullWidth onClick={onBack} variant="outlined">
                        Back
                    </Button>
                ) : null}
                <Button onClick={handleClick} color="primary" variant="contained" fullWidth disabled={isDisabled}>
                    {renderButtonContent(isLastStep ? 'Close' : 'Next')}
                </Button>
            </Box>
        </FooterContainer>
    );
}
