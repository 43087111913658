import { App as CapacitorApp } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useAtomValue } from 'jotai';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';

import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import { queryClient } from '~/api/common';
import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useGetDocumentedCalls } from '~/api/queries/call/getDocumentedCalls';
import { useGetOngoingCalls } from '~/api/queries/call/getOngoingCalls';
import { UndocumentedCalls, useGetUndocumentedCalls } from '~/api/queries/call/getUndocumentedCalls';
import { usePermissions } from '~/permissions/utils';

import { isOnCurrentShiftAtom } from './atom';
import { useSortCalls } from './useSortCalls';

export const useCalls = (params?: { shiftId: number; shiftDay: DateTime }) => {
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const hasPermission = usePermissions();
    const { sortCalls } = useSortCalls();

    const hasECallAccess = !!hasPermission('Community', 'ecall-caregiver');

    const { data: careLocationIds, isLoading: isGetCareLocationsLoading } = useGetChosenCareLocations(hasECallAccess);
    const { data: calls, isLoading: isOngoingCallsLoading } = useGetOngoingCalls({
        enabled: hasECallAccess && !isGetCareLocationsLoading,
        careLocationIds,
    });
    const { data: undocumentedCalls, isLoading: isUndocumentedCallsLoading } = useGetUndocumentedCalls({
        enabled: hasECallAccess && !isGetCareLocationsLoading,
        careLocationIds,
        shiftDay: params?.shiftDay,
        shiftId: params?.shiftId,
    });
    const { data: documentedCalls, isLoading: isDocumentedCallsLoading } = useGetDocumentedCalls({
        enabled: hasECallAccess && !isGetCareLocationsLoading,
        careLocationIds,
        shiftDay: params?.shiftDay,
        shiftId: params?.shiftId,
    });

    useEffect(() => {
        let listener: PluginListenerHandle;

        const exec = async () => {
            const refetchCalls = async () => {
                if (!isOnCurrentShift || !hasECallAccess) return;

                await queryClient.invalidateQueries({
                    queryKey: ['ongoing-calls'],
                });
                await queryClient.invalidateQueries({
                    queryKey: ['undocumented-calls'],
                });
                await queryClient.invalidateQueries({
                    queryKey: ['documented-calls'],
                });
            };

            listener = await CapacitorApp.addListener('resume', () => void refetchCalls());
        };

        void exec();

        return () => void listener?.remove();
    }, [isOnCurrentShift]);

    const undocumentedPublicLocationCalls = useMemo<Record<string, UndocumentedCalls>>(() => {
        const callsFromPublicLocations = undocumentedCalls?.filter(isPublicLocationCall);
        // to group calls from the same location and displays them under the same card
        // we need to group by device location ID.
        // Bringing `deviceLocationId` closer to the call object will make it easier to group
        return groupBy(callsFromPublicLocations, 'deviceLocation.id');
    }, [undocumentedCalls]);

    const undocumentedResidentsCalls = useMemo<Record<string, UndocumentedCalls>>(() => {
        const callsFromResidents = undocumentedCalls?.filter(isResidentCall);
        // doing the same thing as `undocumentedPublicLocationCalls` but grouping by resident
        // using the resident ID.
        return groupBy(callsFromResidents, 'resident.id');
    }, [undocumentedCalls]);

    return useMemo(
        () => ({
            calls: careLocationIds?.length && isOnCurrentShift && calls ? sortCalls(calls) : [],
            documentedCalls: documentedCalls ?? [],
            isDocumentedCallsLoading,
            isUndocumentedCallsLoading,
            isOngoingCallsLoading,
            undocumentedCalls: {
                calls: undocumentedCalls ?? [],
                byResidentIds: undocumentedResidentsCalls,
                byPublicLocationId: undocumentedPublicLocationCalls,
            },
        }),
        [
            calls,
            careLocationIds,
            isOnCurrentShift,
            undocumentedCalls,
            undocumentedPublicLocationCalls,
            undocumentedResidentsCalls,
            documentedCalls,
            isDocumentedCallsLoading,
            isUndocumentedCallsLoading,
            isOngoingCallsLoading,
            sortCalls,
        ]
    );
};

const isPublicLocationCall = <T extends { deviceLocation?: { type: DEVICE_LOCATION_TYPE } }>(call: T): boolean => {
    return !!call.deviceLocation && call.deviceLocation.type !== DEVICE_LOCATION_TYPE.PRIVATE_ROOM;
};

const isResidentCall = <T extends { resident?: { id: number } }>(call: T): boolean => {
    return !!call.resident?.id;
};
