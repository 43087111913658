import { useCallback, useMemo } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { BaseResponse } from '~/types/shared';
import { ZoneResponse } from '~/types/zones';

type GetZonesParams = {
    enabled?: boolean;
    shiftId?: number;
    shiftDay?: string;
    branchId?: number;
};
export const useGetZones = ({ shiftId, shiftDay, branchId: selectedBranchId }: GetZonesParams) => {
    const token = useToken();
    const storedBranchId = useBranchId();

    const branchId = useMemo(() => selectedBranchId || storedBranchId, [selectedBranchId, storedBranchId]);

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<ZoneResponse[]>>('/zones', {
            headers: { Authorization: token },
            params: { branch_id: branchId, shift_id: shiftId, shift_day: shiftDay },
        });

        return data.response ?? [];
    }, [branchId, token, shiftId, shiftDay]);

    return useErrorHandledQuery({
        queryKey: ['zones', branchId, shiftId, shiftDay],
        queryFn,
        enabled: !!branchId && !!shiftId && !!shiftDay,
        staleTime: 300000,
    });
};
