import CallCount from './CallCount';
import CareChanges from './CareChanges';
import EmergencyCalls from './EmergencyCalls';
import NotFeelingWellCalls from './NotFeelingWellCalls';

export default {
    CareChanges,
    CallCount,
    EmergencyCalls,
    NotFeelingWellCalls,
};
