import AllDetail from './AllDetail';
import AllResidents from './AllResidents';
import CareChanges from './CareChanges';
import Emergency from './Emergency';
import NotFeelingWell from './NotFeelingWell';
import Top10 from './Top10';

export default {
    groupings: {
        Emergency,
        NotFeelingWell,
        AllDetail,
        AllResidents,
        CareChanges,
        Top10,
    },
};
