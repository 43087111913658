import { atom, useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { PRN_CATEGORY_SECTIONS } from '@allie/utils/src/constants/prn-tasks.constants';

import { unscheduledTaskCategoriesAtom, unscheduledTaskStepAtom } from '../state/atom';
import { UnscheduledTaskDialogSteps } from '../types/unscheduledTaskDialogSteps';

const counterAtom = atom(0);
const handledDuplicatedStepsAtom = atom(0);

export const useDuplicateSteps = () => {
    // this state must be declared here to expose this information to the step components
    const [counter, _setCounter] = useAtom(counterAtom);

    // this will keep tracking how many duplicated steps have been handled
    // and will be used in the progress bar
    const [handledDuplicatedSteps, setHandledDuplicatedSteps] = useAtom(handledDuplicatedStepsAtom);

    const step = useAtomValue(unscheduledTaskStepAtom);
    const categories = useAtomValue(unscheduledTaskCategoriesAtom);

    const categoriesFromSectionOther = useMemo(
        () => categories.filter((category) => category.section === PRN_CATEGORY_SECTIONS.OTHER),
        [categories]
    );

    const isDuplicatedStep = useCallback(
        (step: UnscheduledTaskDialogSteps) =>
            [
                UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS,
                UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX,
            ].includes(step),
        []
    );

    const shouldDuplicateStep = useCallback(
        (action: 'next' | 'previous') => {
            if (categories.length <= 1) {
                return false;
            }

            if (action === 'next') {
                const categoriesWithAssistLevel = categories.length - categoriesFromSectionOther.length || 1;

                if (step === UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS) {
                    return counter < categoriesWithAssistLevel - 1;
                }

                if (step === UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX) {
                    // When categories exist outside of the 'Other' section,
                    // an additional generic comment step must be added at the flow's end
                    const hasECallOnly =
                        categories.length === categoriesFromSectionOther.length &&
                        categoriesFromSectionOther.length !== 0;

                    return (
                        counter <
                        (hasECallOnly ? categoriesFromSectionOther.length - 1 : categoriesFromSectionOther.length)
                    );
                }

                return false;
            }

            return isDuplicatedStep(step) && counter !== 0;
        },
        [step, categories, counter, categoriesFromSectionOther, isDuplicatedStep]
    );

    const resetCounter = useCallback(
        (followingStep?: UnscheduledTaskDialogSteps, action?: 'next' | 'previous') => {
            if (!followingStep || !action || action === 'next') {
                return _setCounter(0);
            }

            if (followingStep === UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS) {
                const categoriesWithAssistLevel = categories.length - categoriesFromSectionOther.length || 1;

                _setCounter(categoriesWithAssistLevel - 1);
            }

            if (followingStep === UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX) {
                const categoriesWithAssistLevel = categories.length - categoriesFromSectionOther.length;

                _setCounter(
                    categoriesWithAssistLevel
                        ? categoriesFromSectionOther.length + 1
                        : categoriesFromSectionOther.length
                );
            }
        },
        [categories, categoriesFromSectionOther, _setCounter]
    );

    const setCounter = useCallback(
        (action: 'next' | 'previous') => {
            if (action === 'next') {
                _setCounter((prev) => prev + 1);
                return setHandledDuplicatedSteps((prev) => prev + 1);
            }

            _setCounter((prev) => prev - 1);
            setHandledDuplicatedSteps((prev) => prev - 1);
        },
        [_setCounter, setHandledDuplicatedSteps]
    );

    return {
        duplicateStepCounter: counter,
        handledDuplicatedSteps,
        setCounter,
        shouldDuplicateStep,
        resetCounter,
        isDuplicatedStep,
        setHandledDuplicatedSteps,
    };
};
