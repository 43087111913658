import { Box, Typography, styled } from '@mui/material';
import React from 'react';

import { ignoreProps } from '~/lib/styled';

import useShiftRatingDialog from '../../hooks/useShiftRatingDialog';
import { ShiftRating, shiftRatingOptions } from '../../shiftRatings';

type RatingOptionContainerProps = {
    selected: boolean;
};

const RatingOptionContainer = styled(
    Box,
    ignoreProps<RatingOptionContainerProps>('selected')
)<RatingOptionContainerProps>(({ theme, selected }) => ({
    alignItems: 'center',
    border: `1px solid ${selected ? theme.palette.primary[500] : theme.palette.grey[300]}`,
    borderRadius: '8px',
    color: '#2C2D3B',
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    height: '62px',
    padding: '16px',
}));

const SelectRatingStep = () => {
    const { setShiftRating, shiftRating, setAdditionalComment } = useShiftRatingDialog();

    const handleSelectOption = (option: ShiftRating) => {
        setAdditionalComment('');
        if (shiftRating && shiftRating.id === option.id) {
            return setShiftRating(null);
        }
        setShiftRating(option);
    };

    return (
        <Box flexDirection="column" display="flex" sx={{ height: '100%', gap: '16px' }}>
            {shiftRatingOptions.map((ratingOption) => (
                <RatingOptionContainer
                    key={ratingOption.id}
                    onClick={() => handleSelectOption(ratingOption)}
                    selected={shiftRating?.id === ratingOption.id}
                >
                    <img src={ratingOption.icon} alt="rating-option-icon" />
                    <Typography fontWeight={400} fontSize={'14px'} lineHeight="16.8px">
                        {ratingOption.label}
                    </Typography>
                </RatingOptionContainer>
            ))}
        </Box>
    );
};

export default SelectRatingStep;
