import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';

type Item = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'POTENTIAL_UNRECORDED_FALLS'>['items'][number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                branchName: {
                    label: 'Community',
                    weight: 2,
                },
                count: {
                    label: 'Resident\nCount',
                    alignment: 'right',
                },
            }}
            rows={items}
            takeFirst={3}
            seeMore={() => {}}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
