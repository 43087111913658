import { useAtom } from 'jotai';
import { uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import WithHeader from '~/components/Layout/WithHeader';
import Loading from '~/components/Shared/Loading';
import { BranchesByRegionResponseBranch, BranchesByRegionResponseRegion, CareTypeResponse } from '~/types/companyInfo';
import { SelectStructure } from '~/types/inputs';
import { ReduxStore } from '~/types/redux';

import { branchAtom, careTypeAtom, mobileTabSelectedAtom, operationsPeriodAtom, regionAtom } from './atoms';
import { OperationsDashboardHeader } from './components/OperationsDashboardHeader';
import { OperationsDashboardMain } from './components/OperationsDashboardMain';

export const OperationsDashboard = () => {
    const { pathname } = useLocation();
    // for the scheduling version, some things like day view should not be shown
    const isSchedulingVersion = pathname.includes('scheduling');

    const {
        sessionData: { companyId },
    } = useSelector((state: ReduxStore) => state.session);

    const [region, setRegion] = useAtom(regionAtom);
    const [branch, setBranch] = useAtom(branchAtom);
    const [careType, setCareType] = useAtom(careTypeAtom);
    const [operationsPeriod, setOperationsPeriod] = useAtom(operationsPeriodAtom);
    const [mobileTabSelected, setMobileTabSelected] = useAtom(mobileTabSelectedAtom);

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionDataIsLoading,
        isError: branchesByRegionDataIsError,
    } = useBranchesByRegionQuery({
        companyId,
        regionId: region === 'all' ? undefined : region,
        branchId: branch === 'all' ? undefined : branch,
    });

    useEffect(() => {
        // While scheduling version has no day option, we need to force the period to week
        if (isSchedulingVersion && operationsPeriod === 'day') {
            setOperationsPeriod('week');
        }
    }, [isSchedulingVersion, operationsPeriod, setOperationsPeriod]);

    const accessibleRegions: BranchesByRegionResponseRegion[] | [] =
        branchesByRegionData?.regions.filter(({ hasAccess }) => hasAccess) ?? [];

    const accessibleBranches: BranchesByRegionResponseBranch[] =
        branchesByRegionData?.branches.filter(({ hasAccess }) => hasAccess) ?? [];

    useEffect(() => {
        if (!branchesByRegionData || branchesByRegionDataIsLoading) {
            return;
        }
        if (branchesByRegionData?.hasCompanyAccess && accessibleRegions.length > 0) {
            setBranch('all');
            setRegion('all');
        } else if (accessibleRegions.length > 0) {
            setBranch('all');
            setRegion(Number(accessibleRegions[0].regionId));
        } else {
            setBranch(Number(accessibleBranches[0].branchId));
            if (accessibleBranches[0].regionId) {
                setRegion(Number(accessibleBranches[0].regionId));
            }
        }
    }, [branchesByRegionData]);

    if (branchesByRegionDataIsLoading) {
        return <Loading />;
    }

    if (branchesByRegionDataIsError || !branchesByRegionData) {
        return null;
    }

    const { hasCompanyAccess } = branchesByRegionData;

    let regionOptions: SelectStructure[] =
        hasCompanyAccess && accessibleRegions.length > 0 ? [{ label: 'All', value: 'all' }] : [];
    if (accessibleRegions.length > 0) {
        regionOptions = [
            ...regionOptions,
            ...accessibleRegions.map((item: BranchesByRegionResponseRegion) => ({
                label: item.regionShortName,
                value: item.regionId.toString(),
            })),
        ];
    }

    let branchesOptions: SelectStructure[] = accessibleBranches.length > 0 ? [{ label: 'All', value: 'all' }] : [];
    if (region !== 'all') {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches
                .filter((item) => item.regionId === Number(region))
                .map((item) => ({
                    label: item.branchShortName,
                    value: item.branchId.toString(),
                })),
        ];
    } else {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches.map((item) => ({
                label: item.branchShortName,
                value: item.branchId.toString(),
            })),
        ];
    }

    const allCompanyAvailableCareTypes = uniqBy(
        [...Object.values(branchesByRegionData.availableCareTypesByBranchId)].flat(),
        'code'
    );

    let careTypeOptions: SelectStructure[] = [];
    if (branch === 'all') {
        careTypeOptions = allCompanyAvailableCareTypes.length > 1 ? [{ label: 'All', value: 'all' }] : [];
        careTypeOptions = [
            ...careTypeOptions,
            ...Array.from(allCompanyAvailableCareTypes).map((item: CareTypeResponse) => ({
                label: item.name,
                value: item.code,
            })),
        ];
    } else {
        careTypeOptions =
            branchesByRegionData.availableCareTypesByBranchId[branch]?.length > 1
                ? [{ label: 'All', value: 'all' }]
                : [];
        careTypeOptions = [
            ...careTypeOptions,
            ...branchesByRegionData.availableCareTypesByBranchId[branch].map((item: CareTypeResponse) => ({
                label: item.name,
                value: item.code,
            })),
        ];
    }

    const handleTabChange = (newValue: 0 | 1) => {
        setMobileTabSelected(newValue);
    };

    return (
        <WithHeader
            header={
                <OperationsDashboardHeader
                    region={region}
                    branch={branch}
                    careType={careType}
                    showDay={!isSchedulingVersion}
                    showBackButton={!isSchedulingVersion}
                    showCareType={!isSchedulingVersion}
                    operationsPeriod={operationsPeriod}
                    onRegionChange={setRegion}
                    onBranchChange={setBranch}
                    onCareTypeChange={setCareType}
                    onPeriodChange={setOperationsPeriod}
                    mobileTabSelected={mobileTabSelected}
                    onMobileTabChange={handleTabChange}
                    regionOptions={regionOptions}
                    branchesOptions={branchesOptions}
                    careTypeOptions={careTypeOptions}
                    hasCompanyAccess={hasCompanyAccess}
                />
            }
        >
            <OperationsDashboardMain
                mobileTabSelected={mobileTabSelected}
                onMobileTabChange={handleTabChange}
                region={region}
                branch={branch}
                careType={careType}
                operationsPeriod={operationsPeriod}
                hasCompanyAccess={hasCompanyAccess}
                hasRegions={accessibleRegions.length > 0}
                hasBranches={accessibleBranches.length > 1} // There'll always be at least one branch
            />
        </WithHeader>
    );
};

export default OperationsDashboard;
