import { Box, Stack, Typography, styled } from '@mui/material';
import React, { ComponentProps, ReactNode, useState } from 'react';

import { StringKeyOf } from '@allie/operations-common/src/types/utils';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

import { AnimatedChevron, AnimatedExpandableBox } from './DataCollapsibleTableAnimations';

type Item = Record<string, string | number | boolean | object | object[] | null>;

type DataCollapsibleTableColumnDefinition<T extends Item, K extends StringKeyOf<T>> = {
    label: string;
    alignment?: 'left' | 'center' | 'right';
    weight?: number;
    allowSort?: boolean;
    renderHeaderStartIcon?: ReactNode | ((rows: T[]) => ReactNode);
    renderCell?: (value: T[K], { row, rowIndex }: { row: T; rowIndex: number }) => ReactNode;
};

const DataTableContainer = styled(Stack)({
    flexDirection: 'column',
    width: 'max-content',
    overflow: 'hidden',
    gap: '24px',
});

interface DataTableHeaderProps<T extends Item> extends Pick<DataCollapsibleTableProps<T>, 'columns'> {}

const DataTableHeader = <T extends Item>({ columns }: DataTableHeaderProps<T>) => {
    const columnEntries = Object.entries(columns) as [
        StringKeyOf<T>,
        DataCollapsibleTableColumnDefinition<T, StringKeyOf<T>>,
    ][];

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            {columnEntries.map(([, { label, alignment, weight }], columnIndex) => {
                return (
                    <Typography
                        key={columnIndex}
                        variant="body2"
                        fontWeight={700}
                        flex={weight ?? 1}
                        textAlign={alignment ?? 'left'}
                        sx={({ palette }) => ({
                            color: palette.grey[500],
                        })}
                    >
                        {label}
                    </Typography>
                );
            })}
        </Stack>
    );
};

interface DataTableRowProps<T extends Item> extends Pick<DataCollapsibleTableProps<T>, 'columns'> {
    row: T;
    rowIndex: number;
    selectedRow: number | null;
    onRowClick: (rowIndex: number) => void;
    renderCollapsedItem: (data: T) => ReactNode;
}

const DataTableRow = <T extends Item>({
    columns,
    row,
    rowIndex,
    selectedRow,
    renderCollapsedItem,
    onRowClick,
}: DataTableRowProps<T>) => {
    const columnEntries = Object.entries(columns) as [
        StringKeyOf<T>,
        DataCollapsibleTableColumnDefinition<T, StringKeyOf<T>>,
    ][];

    const isSelected = rowIndex === selectedRow;

    return (
        <Stack
            flexDirection="column"
            sx={({ palette }) => ({
                borderBottom: '2px solid',
                borderColor: palette.grey[900],
                paddingBottom: '24px',
            })}
        >
            <Stack flexDirection="row" gap="8px" sx={{ cursor: 'pointer' }}>
                {columnEntries.map(([key, column], keyIndex) => {
                    const { alignment = 'left', weight = 1, renderCell = (value) => value } = column;
                    const cell = renderCell(row[key], { row, rowIndex });

                    const renderChevron = () => {
                        if (keyIndex !== columnEntries.length - 1) {
                            return null;
                        }

                        return <AnimatedChevron isOpen={isSelected} />;
                    };

                    return (
                        <Box
                            onClick={() => onRowClick(rowIndex)}
                            key={keyIndex}
                            sx={{
                                flex: weight,
                                display: 'flex',
                                justifyContent: alignment,
                                alignItems: 'center',
                                gap: '16px',
                            }}
                        >
                            {typeof cell !== 'object' ? (
                                <SingleLineTypography variant="body1" fontWeight={700} sx={{ textAlign: alignment }}>
                                    {cell}
                                </SingleLineTypography>
                            ) : (
                                (cell as unknown as ReactNode)
                            )}
                            {renderChevron()}
                        </Box>
                    );
                })}
            </Stack>
            {renderCollapsedItem && (
                <AnimatedExpandableBox isOpen={isSelected}>{renderCollapsedItem(row)}</AnimatedExpandableBox>
            )}
        </Stack>
    );
};

type DataCollapsibleTableProps<T extends Item> = {
    columns: { [K in StringKeyOf<T>]?: DataCollapsibleTableColumnDefinition<T, K> };
    rows: T[];
    containerProps?: ComponentProps<typeof Stack>;
    renderCollapsedItem: (data: T) => ReactNode;
};

const DataCollapsibleTable = <T extends Item>({
    columns,
    rows,
    containerProps,
    renderCollapsedItem,
}: DataCollapsibleTableProps<T>) => {
    const [selectedRow, setSelectedRow] = useState<number | null>(null);

    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
    };

    return (
        <DataTableContainer {...containerProps}>
            <DataTableHeader columns={columns} />

            {rows.map((row, rowIndex) => {
                return (
                    <DataTableRow
                        key={rowIndex}
                        columns={columns}
                        row={row}
                        rowIndex={rowIndex}
                        selectedRow={selectedRow}
                        onRowClick={handleRowClick}
                        renderCollapsedItem={renderCollapsedItem}
                    />
                );
            })}
        </DataTableContainer>
    );
};

export default DataCollapsibleTable;
