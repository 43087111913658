import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { FAST_CALL_DOCUMENTATION_TIME_THRESHOLD_MIN } from '@allie/hardcoded-constants/src/rewards';

import NewUnscheduledTaskDialog from '../../components/NewUnscheduledTaskDialog';

import { FinalMessage } from './FinalMessage';

interface DocumentationFlowProps {
    isOpen: boolean;
    onClose: () => void;
    callId: number | null;
    completedAt?: string;
}

export const DocumentationFlow = ({ isOpen, callId, completedAt, onClose }: DocumentationFlowProps) => {
    const [isFinalMessageOpen, setIsFinalMessageOpen] = useState(false);
    const [isUnderFiveMinutes, setIsUnderFiveMinutes] = useState(false);

    const handleDocumentationFlowFinish = () => {
        onClose();
        setIsFinalMessageOpen(true);

        if (completedAt) {
            const completedAtDate = DateTime.fromISO(completedAt);
            const diffInMinutes = DateTime.now().diff(completedAtDate, 'minutes').minutes;

            setIsUnderFiveMinutes(diffInMinutes < FAST_CALL_DOCUMENTATION_TIME_THRESHOLD_MIN);
        } else {
            setIsUnderFiveMinutes(false);
        }
    };

    const toggleFinalMessage = () => {
        setIsFinalMessageOpen(false);
        setIsUnderFiveMinutes(false);
    };

    return (
        <>
            <NewUnscheduledTaskDialog
                isOpen={isOpen}
                onClose={handleDocumentationFlowFinish}
                callId={callId ?? undefined}
            />
            <FinalMessage
                isOpen={isFinalMessageOpen}
                onClose={toggleFinalMessage}
                isUnderFiveMinutes={isUnderFiveMinutes}
            />
        </>
    );
};
