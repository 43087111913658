import { Box, Button, Stack, Typography, alpha, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { PiCircleFill } from 'react-icons/pi';

import { RemappedDocumentedCall } from '~/api/queries/call/getDocumentedCalls';
import { usePermissions } from '~/permissions/utils';

import { formatTimeDiff } from './helpers';
import { CallType } from './types';

interface CardHeaderProps extends RemappedDocumentedCall {
    selectedCallType: CallType | null;
}

const CardHeader = ({ resident, deviceLocation, triggeredAt, attendedAt, selectedCallType }: CardHeaderProps) => {
    const { palette } = useTheme();
    const hasPermission = usePermissions();
    // TODO - create manager permission and use it here
    const isCaregiver = !hasPermission('Community', 'ecall-caregiver');

    const callDuration = useMemo(() => {
        return formatTimeDiff(attendedAt.diff(triggeredAt).as('hours'));
    }, [triggeredAt, attendedAt]);

    return (
        <Box display="flex" gap="8px" alignItems="center">
            <PiCircleFill size={24} color={palette.grey[100]} />
            <Typography
                flex={isCaregiver ? 1 : undefined}
                variant="body2"
                fontWeight={selectedCallType === 'resident' ? 700 : 400}
                lineHeight="170%"
                sx={{ textOverflow: 'ellipsis', textWrap: 'nowrap', overflow: 'hidden' }}
            >
                {resident?.name ?? deviceLocation?.name}
            </Typography>
            <Typography variant="body2" fontWeight={400} lineHeight="170%" sx={{ textWrap: 'nowrap' }}>
                {resident?.roomNumber
                    ? `Apt ${resident.roomNumber}`
                    : (deviceLocation?.roomNumber ?? deviceLocation?.zoneName)}
            </Typography>
            {!isCaregiver && (
                <Typography
                    flex={1}
                    variant="body2"
                    textAlign="end"
                    sx={{ textWrap: 'nowrap' }}
                    fontWeight={400}
                    lineHeight="170%"
                >
                    {callDuration}
                </Typography>
            )}
        </Box>
    );
};

interface CallCardProps extends RemappedDocumentedCall {
    onClick: (id: number) => void;
    selectedCallType: CallType | null;
}

export const CallCard = (props: CallCardProps) => {
    const { attendedByUser, attendedAt, taskCategories, selectedCallType, onClick } = props;
    const { palette } = useTheme();

    const handleClick = () => {
        onClick(props.id);
    };

    return (
        <Stack
            padding="16px"
            borderRadius="8px"
            gap="8px"
            border={`1px solid ${palette.primary[300]}`}
            bgcolor={palette.success[100] as string}
        >
            <CardHeader {...props} />
            <Typography variant="body2" fontWeight={selectedCallType === 'staff' ? 700 : 400} lineHeight="170%">
                {attendedByUser.name} (Staff)
            </Typography>
            <Typography variant="body2" fontWeight={400} lineHeight="170%">
                {/* e.g. 11:20 AM, Nov. 11 (Thu) */}
                {attendedAt.toFormat('h:mm a, MMM. dd (ccc)')}
            </Typography>
            <Typography variant="body2" fontWeight={400} lineHeight="170%" color={alpha(palette.grey[900], 0.5)}>
                {taskCategories.join(', ')}
            </Typography>
            <Button variant="outlined" sx={{ borderRadius: '8px', borderWidth: '1px' }} fullWidth onClick={handleClick}>
                See details
            </Button>
        </Stack>
    );
};
