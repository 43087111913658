export const PROFILE = 'profile';
export const SIGNUP = 'signup';

export const DASHBOARD_FILTERS = 'dashboard-filters';
export const HOME_FILTERS = 'home-filters';
export const RESIDENTS_FILTERS = 'residents-filters';
export const TEMP_USER_DATA = 'temp-user';

export const CAREGIVER_FILTERS = 'caregiver-filters-v1';

export const NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL = 'no-early-in-shift-confirmation-until';

export const PUSH_NOTIFICATION_TOKEN_LAST_UPDATED = 'push-notification-token-last-updated';

export const SHIFT_RATING_DIALOG_CLOSED = 'shift-rating-dialog-closed';
