import { Capacitor } from '@capacitor/core';
import { Box, Button, Typography, styled } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { PiConfetti } from 'react-icons/pi';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 0',
    gap: '24px',
    maxWidth: '80%',
    height: '100%',
    margin: '0 auto',
});

const ConfettiIcon = styled(PiConfetti)(({ theme }) => ({
    color: theme.palette.secondary[500],
    fontSize: '48px',
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary[50],
    padding: '32px',
    borderRadius: '50%',
}));

const CloseButton = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[900],
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
}));

const OpenStoreButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: theme.palette.primary[500],
    fontWeight: 'bold',
    fontSize: 16,
}));

const NativeUpdateAvailable = ({ onClose }: { onClose: () => void }) => {
    const posthog = usePostHog();

    const openStorePage = () => {
        if (Capacitor.getPlatform() === 'ios') {
            window.open('https://apps.apple.com/us/app/allie-health/id6468182196', '_blank');
        } else {
            window.open('https://play.google.com/store/apps/details?id=com.alliehealth.app', '_blank');
        }
    };

    return (
        <Container>
            <IconContainer>
                <ConfettiIcon />
            </IconContainer>

            <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: 22, color: '#2C2D3B' }}>
                A new version is here!
            </Typography>

            <Typography
                variant="body1"
                sx={({ palette }) => ({
                    fontSize: 18,
                    fontWeight: '300',
                    color: palette.grey[500],
                    textAlign: 'center',
                    lineHeight: 1.5,
                })}
            >
                Update your app to the latest version of AllieHealth
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                <CloseButton
                    variant="outlined"
                    size="large"
                    onClick={() => {
                        posthog.capture('app-outdated-notice:close');
                        onClose();
                    }}
                    fullWidth
                >
                    Ask me again later
                </CloseButton>
                <OpenStoreButton
                    variant="outlined"
                    size="large"
                    onClick={() => {
                        posthog.capture('app-outdated-notice:go-to-store');
                        openStorePage();
                    }}
                    fullWidth
                >
                    Go to app store
                </OpenStoreButton>
            </Box>
        </Container>
    );
};

export default NativeUpdateAvailable;
