import React from 'react';

import { usePermissions } from '~/permissions/utils';

import { EndOfShiftWarningModal } from '../modal/EndOfShiftWarningModal';

import { useShiftAboutToEnd } from './useShiftAboutToEnd';

export const EndOfShiftWarning = () => {
    const { isEndOfShiftWarningModalOpen, closeModal } = useShiftAboutToEnd();
    const hasPermissions = usePermissions();

    const hasECallAccess = hasPermissions('Community', 'ecall-caregiver');

    if (!hasECallAccess) {
        return null;
    }

    return <EndOfShiftWarningModal isOpen={isEndOfShiftWarningModalOpen} onClose={closeModal} />;
};
