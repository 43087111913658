import { Box } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { PiBellSlash, PiConfetti } from 'react-icons/pi';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';

import { useServerSentEvents } from '~/api/events/useServerSentEvents';
import { Placeholder } from '~/components/Shared/Placeholder';

import { LoadingCalls } from './LoadingCalls';
import { isOnCurrentShiftAtom, selectedCallIdAtom } from './atom';
import { CallCard } from './call/card';
import { AnimatedCallCardContainer, CallCardContainer } from './call/card/shared';
import { DocumentationFlow } from './documentation/DocumentationFlow';
import { NotificationFlow } from './notification/NotificationFlow';
import { useCalls } from './useCalls';

interface LiveCallsProps {
    shiftDay: string;
    shiftId: number;
}

export const LiveCalls = (props: LiveCallsProps) => {
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const [selectedCallId, setSelectedCallId] = useAtom(selectedCallIdAtom);
    const [previousCalls, setPreviousCalls] = useState<string[]>([]);

    const closeDocumentationFlow = () => {
        setSelectedCallId(null);
    };

    const { calls, isOngoingCallsLoading } = useCalls();
    useServerSentEvents();

    const ecallCompletedAt = useMemo(() => {
        if (!selectedCallId) return;

        // we can use now because the documentation flow is triggered
        // immediately after the user completes the call
        return DateTime.now().toISO();
    }, [selectedCallId]);

    const newCallIds = useMemo(() => {
        const currentCallIds = calls.map((call) => call.id.toString());
        const newIds = currentCallIds.filter((id) => !previousCalls.includes(id));

        // Update previous calls after identifying new ones
        if (newIds.length > 0) {
            setPreviousCalls(currentCallIds);
        }

        return newIds;
    }, [calls]);

    return (
        <>
            {!isOnCurrentShift ? (
                <Placeholder
                    icon={PiBellSlash}
                    type="secondary"
                    title="Notifications are off"
                    description="Looks like you're off shift. We've silenced your live calls."
                />
            ) : isOngoingCallsLoading ? (
                <LoadingCalls />
            ) : (
                <React.Fragment>
                    {!calls.length && (
                        <Placeholder
                            icon={PiConfetti}
                            type="secondary"
                            title="No calls"
                            description="Everyone's taken care of, amazing! This team is so on top of things."
                        />
                    )}

                    {calls.map((call) => {
                        const isNewCall = newCallIds.includes(call.id.toString()) && call.status === CALL_STATUS.OPEN;

                        return (
                            <Box key={call.id} component={isNewCall ? AnimatedCallCardContainer : CallCardContainer}>
                                <CallCard {...call} />
                            </Box>
                        );
                    })}
                </React.Fragment>
            )}

            <DocumentationFlow
                isOpen={!!selectedCallId}
                onClose={closeDocumentationFlow}
                callId={selectedCallId}
                completedAt={ecallCompletedAt}
            />
            <NotificationFlow shiftDay={props.shiftDay} shiftId={props.shiftId} />
        </>
    );
};
