import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import {
    OperationsBranchStatsParams,
    OperationsBranchStatsResponse,
    OperationsOverallStatsParams,
    OperationsOverallStatsResponse,
} from '~/types/operationsDashboard';

export const useOperationsOverallStatsQuery = (jsonParams?: OperationsOverallStatsParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                company_id: jsonParams?.companyId,
                region_id: jsonParams?.regionId,
                branch_id: jsonParams?.branchId,
                group_by: jsonParams?.groupBy,
                care_type: jsonParams?.careType,
                type: jsonParams?.type,
            },
        };

        const { data } = await api.get<{ response: OperationsOverallStatsResponse }>(
            '/analytics/operations/overall-stats',
            options
        );

        return data.response;
    }, [jsonParams, token]);

    return useErrorHandledQuery({
        queryKey: ['operations-overall-stats', jsonParams],
        queryFn,
        enabled: !!jsonParams,
        staleTime: 300000,
    });
};

export const useOperationsBranchStatsQuery = (jsonParams?: OperationsBranchStatsParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                branch_id: jsonParams?.branchId,
                group_by: jsonParams?.groupBy,
                care_type: jsonParams?.careType,
                type: jsonParams?.type,
            },
        };

        const { data } = await api.get<{ response: OperationsBranchStatsResponse }>(
            '/analytics/operations/branch-stats',
            options
        );

        return data.response;
    }, [jsonParams, token]);

    return useErrorHandledQuery({
        queryKey: ['operations-community-stats', jsonParams],
        queryFn,
        enabled: !!jsonParams,
        staleTime: 300000,
    });
};
