import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { PiSmileyWink } from 'react-icons/pi';

import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import { useSignOut } from '~/api/queries/auth/signout';
import { useLocations } from '~/hooks/useLocations';
import { useRandomSuccessMessage } from '~/pages/Home/useRandomSuccessMessage';

import { isAssignmentsModalOpenAtom, isOnCurrentShiftAtom } from '../atom';
import { AssignmentsModal } from '../modal/AssignmentsModal';
import { MotivationMessageModal } from '../modal/MotivationMessageModal';
import { OnShiftConfirmationModal } from '../modal/OnShiftConfirmationModal';

import { useShiftAboutToEnd } from './useShiftAboutToEnd';
import { useShiftVerification } from './useShiftVerification';

interface NotificationFlowProps {
    shiftDay: string;
    shiftId: number;
}

export const NotificationFlow = (props: NotificationFlowProps) => {
    const { isOnShiftConfirmModalOpen, toggleOnShiftConfirmModal, stopNotificationFlow, resetVerificationTime } =
        useShiftVerification();
    const { stopEndOfShiftWarning, updateCurrentShiftEndTime } = useShiftAboutToEnd();
    const { message, resetSeed } = useRandomSuccessMessage('NOTIFICATION_FLOW');

    const [isOnCurrentShift, setIsOnCurrentShift] = useAtom(isOnCurrentShiftAtom);
    const [isAssignmentsModalOpen, toggleAssignmentsModal] = useAtom(isAssignmentsModalOpenAtom);
    const [isSuccessMessageModalOpen, toggleSuccessMessageModal] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { mutateAsync: saveChosenAssignments } = useSaveChosenCareLocations();
    const { mutateAsync: signOut } = useSignOut();

    const { locations, isLoading } = useLocations({
        shiftDay: props.shiftDay,
        shiftId: props.shiftId,
    });

    const shouldSkipAssignmentsModal = useMemo(() => locations.length === 1, [locations]);

    useEffect(() => {
        if (isOnShiftConfirmModalOpen) {
            resetSeed();
        }
    }, [isOnShiftConfirmModalOpen]);

    const handleConfirm = async () => {
        try {
            setIsSaving(true);
            if (shouldSkipAssignmentsModal) {
                await saveChosenAssignments([locations[0].id]);
                toggleSuccessMessageModal(true);
            } else {
                toggleAssignmentsModal();
            }
            toggleOnShiftConfirmModal();
            setIsOnCurrentShift(true);
        } finally {
            setIsSaving(false);
        }
    };

    const handleAssignmentSubmit = () => {
        toggleAssignmentsModal();
        updateCurrentShiftEndTime();
        toggleSuccessMessageModal(true);
    };

    const handleSignout = async () => {
        try {
            setIsSigningOut(true);
            await saveChosenAssignments([]);
            setIsOnCurrentShift(false);

            // resetting to trigger the notification flow when the next user logs in
            resetVerificationTime();

            await signOut({});
            toggleOnShiftConfirmModal();
        } finally {
            setIsSigningOut(false);
        }
    };

    const handleClose = async () => {
        try {
            setIsClosing(true);
            await saveChosenAssignments([]);
            setIsOnCurrentShift(false);
            stopNotificationFlow();
            stopEndOfShiftWarning();
        } finally {
            setIsClosing(false);
        }
    };

    return (
        <>
            <OnShiftConfirmationModal
                isOpen={isOnShiftConfirmModalOpen}
                onClose={async () => {
                    await handleClose();
                    toggleOnShiftConfirmModal();
                }}
                onConfirm={handleConfirm}
                onLogout={handleSignout}
                endOfShift={isOnCurrentShift}
                isLoading={isLoading}
                isClosing={isClosing}
                isSaving={isSaving}
                isSignoutLoading={isSigningOut}
            />
            <AssignmentsModal
                shiftDay={props.shiftDay}
                shiftId={props.shiftId}
                isOpen={isAssignmentsModalOpen}
                onClose={async () => {
                    await handleClose();
                    toggleAssignmentsModal();
                }}
                onSubmit={handleAssignmentSubmit}
            />
            <MotivationMessageModal
                isOpen={isSuccessMessageModalOpen}
                onContinue={() => {
                    toggleSuccessMessageModal(false);
                    stopNotificationFlow();
                }}
                title={message.title}
                description={message.description}
                buttonText={message.answer}
                icon={PiSmileyWink}
            />
        </>
    );
};
