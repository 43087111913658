import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import * as Sentry from '@sentry/react';
import * as OldLiveUpdates from 'live-updates-old';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useRef, useState } from 'react';

import { useLiveUpdateTimeoutThresholdQuery } from '~/api/queries/live-update';
import Loading from '~/components/Shared/Loading';

import NativeUpdateAvailable from './components/NativeUpdateAvailable';

const UpdatesWrapper = ({ children }: { children: React.ReactNode }) => {
    const posthog = usePostHog();

    const isNativePlatform = Capacitor.isNativePlatform();
    const { data: timeoutValue, error: timeoutError } = useLiveUpdateTimeoutThresholdQuery(isNativePlatform);

    const [showAppOutdatedModal, setShowAppOutdatedModal] = useState<boolean | null>(null);
    const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
    const [shouldReloadApp, _setShouldReloadApp] = useState<boolean | null>(null);
    const shouldReloadAppRef = useRef<boolean | null>(null);
    const timeoutTimer = useRef<NodeJS.Timeout | null>(null);

    const setShouldReloadApp = (value: boolean) => {
        shouldReloadAppRef.current = value;
        _setShouldReloadApp(value);
    };

    const isOldNativeApp = async () => {
        if (!isNativePlatform) throw new Error('Not a native platform');
        const { version } = await App.getInfo();
        return version === '2.0.0';
    };

    const sync = async (): Promise<OldLiveUpdates.SyncResult | LiveUpdates.SyncResult> => {
        let syncResult: OldLiveUpdates.SyncResult | LiveUpdates.SyncResult | null = null;

        if (await isOldNativeApp()) {
            syncResult = await OldLiveUpdates.sync();
        } else {
            syncResult = await LiveUpdates.sync((progress) => {
                setDownloadProgress(progress);
            });
        }

        // If we hit the timeout but the download completes later, we should still reload on resume
        setShouldReloadApp(syncResult.activeApplicationPathChanged);
        return syncResult;
    };

    const reload = async () => {
        if (await isOldNativeApp()) {
            return await OldLiveUpdates.reload();
        }

        return await LiveUpdates.reload();
    };

    const attemptImmediateUpdate = async (timeoutValue: number) => {
        const runTimeout = () => {
            return new Promise((_, reject) => {
                const timer = setTimeout(() => {
                    reject('Timeout hit');
                }, timeoutValue);
                timeoutTimer.current = timer;
            });
        };

        try {
            const syncResult = (await Promise.race([runTimeout(), sync()])) as
                | OldLiveUpdates.SyncResult
                | LiveUpdates.SyncResult;

            posthog.capture('live-updates:success', { syncResult });

            if (syncResult.activeApplicationPathChanged) {
                posthog.capture('live-updates:reload-on-start', { syncResult });
                await reload();
            }
        } catch (err) {
            if (err === 'Timeout hit') {
                posthog.capture('live-updates:timeout', { timeoutValue });
            } else {
                Sentry.captureException(err);
            }
            setShouldReloadApp(false);
        } finally {
            if (timeoutTimer.current) clearTimeout(timeoutTimer.current);
        }
    };

    // People often keep apps minimized without ever closing them
    // This makes sure we keep looking for and applying OTA updates
    const setupUpdateOnResume = () => {
        const syncOnResume = async () => {
            try {
                if (shouldReloadAppRef.current) {
                    posthog.capture('live-updates:reload-on-resume');
                    await reload();
                } else {
                    const syncResult = await sync();
                    posthog.capture('live-updates:on-resume', {
                        result: syncResult,
                    });
                }
            } catch (err) {
                posthog.capture('live-updates:on-resume-error', {
                    err,
                });
                setShouldReloadApp(false);
                Sentry.captureException(err);
            }
        };

        posthog.capture('live-updates:set-on-resume-listener');
        void App.addListener('resume', () => void syncOnResume());
    };

    const checkIsAppOutdated = async () => {
        if (!isNativePlatform) return setShowAppOutdatedModal(false);

        const { version } = await App.getInfo();

        // This is a temporary implementation for native version 2.
        // For version 3 onwards, we will use the @capawesome/capacitor-app-update plugin and check directly from the OS if an update is available on the store.
        if (version === '2.0.0') return setShowAppOutdatedModal(true);

        return setShowAppOutdatedModal(false);

        // TODO: Implement for V3 and above
    };

    useEffect(() => {
        void checkIsAppOutdated();
        void App.addListener('resume', () => {
            Math.random() <= 0.3 && void checkIsAppOutdated();
        });
    }, []);

    useEffect(() => {
        if (!isNativePlatform || process.env.REACT_APP_OTA_ENABLED === 'false' || timeoutError) {
            posthog.capture('live-updates:disabled', {
                isNativePlatform,
                timeoutError,
                REACT_APP_OTA_ENABLED: process.env.REACT_APP_OTA_ENABLED,
            });
            return setShouldReloadApp(false);
        }

        if (timeoutValue) void attemptImmediateUpdate(timeoutValue);

        setupUpdateOnResume();

        return () => {
            void App.removeAllListeners();
        };
    }, [timeoutValue, timeoutError]);

    if (isNativePlatform && showAppOutdatedModal) {
        return (
            <NativeUpdateAvailable
                onClose={() => {
                    setShowAppOutdatedModal(false);
                }}
            />
        );
    }

    if (isNativePlatform && (shouldReloadApp === null || showAppOutdatedModal === null)) {
        if (downloadProgress === null) {
            return <Loading label="Checking for updates..." />;
        }

        return <Loading label="Downloading update..." progress={downloadProgress} />;
    }

    return children;
};

export default UpdatesWrapper;
