import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataCollapsibleTable from '~/pages/OperationsV2/components/Module/Details/DataCollapsibleTable';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';

import { CommunityData } from './CommunityData';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'POTENTIAL_UNRECORDED_FALLS', 'ALL'>;

const Data = ({ data: { communityItems } }: { data: Data }) => {
    return (
        <DataContainer>
            <DataCollapsibleTable
                containerProps={{ sx: { width: '100%', overflowX: 'hidden' } }} // overflow X glitches when the table is collapsed
                columns={{
                    communityName: { label: 'Community', weight: 1, alignment: 'left' },
                    residentCount: {
                        label: 'Resident\nCount',
                        weight: 1,
                        alignment: 'right',
                    },
                }}
                renderCollapsedItem={(data) => <CommunityData data={data} />}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
