import { Box, Typography, styled, useTheme } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React, { useState } from 'react';

import TranscriptionTextField from '~/components/Shared/TranscriptionTextField';

import { additionalCommentAtom, shiftRatingAtom } from '../../state/atom';

const RatingOptionContainer = styled(Box)({
    alignItems: 'center',
    backgroundColor: '#E3E8EF',
    borderRadius: '8px',
    color: '#2C2D3B',
    display: 'flex',
    gap: '8px',
    height: '62px',
    padding: '16px',
});

const AdditionalCommentBoxStep = () => {
    const { palette } = useTheme();
    const [showActivateMicConfirmation, setShowActivateMicConfirmation] = useState(false);
    const shiftRating = useAtomValue(shiftRatingAtom);
    const [additionalComment, setAdditionalComment] = useAtom(additionalCommentAtom);

    const handleChange = (value: string) => {
        setAdditionalComment(value);
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <RatingOptionContainer>
                <img src={shiftRating?.icon} alt="shift-rating-icon" />
                <Typography fontWeight={400} fontSize={'14px'} lineHeight="16.8px">
                    {shiftRating?.label}
                </Typography>
            </RatingOptionContainer>
            <Box height="24px" />
            <Box flex={1} display="flex" flexDirection="column" gap="8px">
                <Typography variant="body1" color={palette.grey[900]} fontSize="14px" fontWeight={500}>
                    {'Tell us what happened (Optional)'}
                </Typography>
                <TranscriptionTextField
                    analyticsIdText="shift-rating-additional-comment-box"
                    hideDescriptionMessage
                    onChange={handleChange}
                    placeholder="Write here or use the mic to transcript your voice easily"
                    showActivateMicConfirmation={showActivateMicConfirmation}
                    text={additionalComment}
                    toggleShowActivateMicConfirmation={setShowActivateMicConfirmation}
                />
            </Box>
        </Box>
    );
};

export default AdditionalCommentBoxStep;
