import { Box, styled } from '@mui/material';
import { useMemo } from 'react';
import React from 'react';

import CustomDrawer from '~/components/Custom/CustomDrawer';

import Footer from './components/Footer';
import Header from './components/Header';
import useShiftRatingDialog from './hooks/useShiftRatingDialog';
import { ShiftRatingDialogSteps } from './shiftRatingDialogSteps';
import AdditionalCommentBoxStep from './steps/additionalCommentBox';
import ConfettiStep from './steps/confetti';
import SelectRatingStep from './steps/selectRating';

type ShiftRatingDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onComplete: () => void;
};

const PageContent = styled(Box)(() => ({
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
    padding: '16px',
}));

const ShiftRatingDialog = ({ isOpen, onClose, onComplete }: ShiftRatingDialogProps) => {
    const {
        goBackOneStep,
        goToNextStep,
        isFirstStep,
        isLastStep,
        isSubmitPending,
        onSubmit,
        progress,
        resetShiftRatingFlow,
        shiftRating,
        step,
    } = useShiftRatingDialog();

    const handleOnClose = () => {
        onClose();
        resetShiftRatingFlow();
    };

    const Content = useMemo(() => {
        switch (step) {
            case ShiftRatingDialogSteps.SELECT_RATING:
                return <SelectRatingStep />;
            case ShiftRatingDialogSteps.ADDITIONAL_COMMENT_BOX:
                return <AdditionalCommentBoxStep />;
            case ShiftRatingDialogSteps.CONFETTI:
                return <ConfettiStep />;
            default:
                throw new Error(`Unhandled shift rating step.`);
        }
    }, [step]);

    return (
        <CustomDrawer isOpen={isOpen} onClose={handleOnClose}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {!isLastStep && (
                    <Header
                        progress={progress}
                        onBack={goBackOneStep}
                        onClose={handleOnClose}
                        isFirstStep={isFirstStep}
                    />
                )}
                <PageContent sx={{ padding: '24px' }}>{Content}</PageContent>
                <Footer
                    isDisabled={!shiftRating}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    isLoading={isSubmitPending}
                    onAddShiftRating={onSubmit}
                    onBack={goBackOneStep}
                    onComplete={onComplete}
                    onNextStep={goToNextStep}
                    step={step}
                />
            </Box>
        </CustomDrawer>
    );
};

export default ShiftRatingDialog;
