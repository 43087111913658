import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PiSmiley } from 'react-icons/pi';

import CustomConfetti from '~/components/Custom/CustomConfetti';
import { Placeholder } from '~/components/Shared/Placeholder';

const message = {
    title: 'Thank you!',
    description: 'Your insight helps us understand how to make things better.',
};

const ConfettiStep = () => {
    const [startParty, setStartParty] = useState(false);

    useEffect(() => {
        setStartParty(true);
    }, []);

    return (
        <Box height="100%">
            <Placeholder description={message.description} title={message.title} icon={PiSmiley} type="secondary" />
            <CustomConfetti activate={startParty} stop={() => setStartParty(false)} />
        </Box>
    );
};

export default ConfettiStep;
