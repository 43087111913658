import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataTableNumberedCell from '~/pages/OperationsV2/components/Module/DataTableNumberedCell';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_DETAIL_AND_RESIDENT', 'ALL', 'ALL_RESIDENT'>[number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 1,
                    renderCell: (value, { rowIndex }) => <DataTableNumberedCell index={rowIndex + 1} value={value} />,
                },
                callCount: {
                    label: 'Call Count',
                },
            }}
            rows={items}
            containerProps={{ width: '100% !important' }}
        />
    </DataContainer>
);

export default Data;
