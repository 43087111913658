import { Palette, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import {
    AnyDetailsGroupingType,
    AnyDetailsType,
    AnyModuleType,
    AnySectionType,
    DetailsGroupingDataType,
    DetailsGroupingType,
    DetailsPayload,
    DetailsType,
    ModuleType,
    SectionDataType,
    SectionType,
} from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

import { formatMsToMinutes, formatPercentage, formatSign } from '../utils/format';

import levels from './levels';

type RenderFnOptions = {
    palette: Palette;
};

export type LevelDefinition<L extends Level> = Readonly<{
    level: L;
    modules: { [M in ModuleType<L>]: ModuleDefinition<L, M> };
}>;

export type ModuleDefinition<L extends Level, M extends ModuleType<L>> = Readonly<{
    type: M;
    label: string;
    sections: { [S in SectionType<L, M>]: SectionDefinition<L, M, S> };
    details?: { [D in DetailsType<L, M>]: DetailsDefinition<L, M, D> };
}>;

type SectionDataRenderFn<
    L extends Level,
    M extends ModuleType<L>,
    S extends SectionType<L, M>,
    NodeType extends ReactNode = ReactNode,
> = (data: SectionDataType<L, M, S>, options: RenderFnOptions) => NodeType;

export type SectionDefinition<L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>> = Readonly<{
    type: S;
    label: string;
    caption?: string | SectionDataRenderFn<L, M, S, string>;
    summary?: SectionDataRenderFn<L, M, S>;
    detailsPayload?: Extract<DetailsPayload, { moduleType: M }>;
    renderData: SectionDataRenderFn<L, M, S>;
}>;

export type DetailsDefinition<L extends Level, M extends ModuleType<L>, D extends DetailsType<L, M>> = Readonly<{
    type: D;
    label: string;
    caption?: string;
    groupings: { [G in DetailsGroupingType<L, M, D>]: DetailsGroupingDefinition<L, M, D, G> };
}>;

type DetailsGroupingDataRenderFn<
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
    NodeType extends ReactNode = ReactNode,
> = (data: { hasMore: boolean; data: DetailsGroupingDataType<L, M, D, G> }, options: RenderFnOptions) => NodeType;

export type DetailsGroupingDefinition<
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
> = Readonly<{
    type: G;
    label: string;
    overrideDetailsLabel?: string | DetailsGroupingDataRenderFn<L, M, D, G, string>;
    overrideDetailsCaption?: string | DetailsGroupingDataRenderFn<L, M, D, G, string>;
    allowSearch?: boolean;
    searchPlaceholder?: string;
    renderData: DetailsGroupingDataRenderFn<L, M, D, G>;
}>;

// Helper types for when we really don't care about/cannot get the specific type
export type AnyModuleDefinition = ModuleDefinition<Level, AnyModuleType>;
export type AnySectionDefinition = SectionDefinition<Level, AnyModuleType, AnySectionType>;
export type AnyDetailsDefinition = DetailsDefinition<Level, AnyModuleType, AnyDetailsType>;
export type AnyDetailsGroupingDefinition = DetailsGroupingDefinition<
    Level,
    AnyModuleType,
    AnyDetailsType,
    AnyDetailsGroupingType
>;
export type AnyDetailsGroupingDefinitionTextOverride =
    | string
    | DetailsGroupingDataRenderFn<Level, AnyModuleType, AnyDetailsType, AnyDetailsGroupingType, string>;

export const definitionsByLevel: { [L in Level]: LevelDefinition<L> } = {
    BRANCH: {
        level: 'BRANCH',
        modules: {
            CALL_BY_RESPONSE_TIME: {
                type: 'CALL_BY_RESPONSE_TIME',
                label: 'Call by Response Time',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        label: 'Avg. Response Time',
                        summary: ({ responseTimeMs }) => formatMsToMinutes(responseTimeMs),
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'SHIFT',
                        },
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTime items={items} />
                        ),
                    },
                    AVG_RESPONSE_TIME_BY_CARE_STAFF: {
                        type: 'AVG_RESPONSE_TIME_BY_CARE_STAFF',
                        label: 'Avg. Response Time by Care Staff',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'CARE_STAFF',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTimeByCareStaff
                                items={items}
                            />
                        ),
                    },
                    AVG_RESPONSE_TIME_BY_RESIDENT: {
                        type: 'AVG_RESPONSE_TIME_BY_RESIDENT',
                        label: 'Avg. Response Time by Resident',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'RESIDENT',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTimeByResident
                                items={items}
                            />
                        ),
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'LONGEST_RESPONSE_TIME',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.LongestResponseTime items={items} />
                        ),
                    },
                },
                details: {
                    AVG_RESPONSE_TIME_BY: {
                        type: 'AVG_RESPONSE_TIME_BY',
                        label: 'Avg. Response Time by',
                        groupings: {
                            SHIFT: {
                                type: 'SHIFT',
                                label: 'Shift',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.groupings.Shift
                                        items={data}
                                    />
                                ),
                            },
                            PERIOD: {
                                type: 'PERIOD',
                                label: 'Days',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.groupings.Period
                                        items={data}
                                    />
                                ),
                            },
                            CARE_STAFF: {
                                type: 'CARE_STAFF',
                                label: 'Care Staff',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.groupings.CareStaff
                                        items={data}
                                    />
                                ),
                            },
                            RESIDENT: {
                                type: 'RESIDENT',
                                label: 'Resident',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.groupings.Resident
                                        items={data}
                                    />
                                ),
                            },
                        },
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByResponseTime.details.LongestResponseTime.groupings.All
                                        items={data}
                                    />
                                ),
                            },
                        },
                    },
                },
            },
            CALL_BY_DETAIL_AND_RESIDENT: {
                type: 'CALL_BY_DETAIL_AND_RESIDENT',
                label: 'Call by Detail and Resident',
                sections: {
                    CALL_COUNT: {
                        type: 'CALL_COUNT',
                        label: 'Call Count',
                        summary: ({ totalCount }) => totalCount,
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL_AND_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'ALL_DETAIL',
                        },
                        renderData: (data) => (
                            <levels.Branch.modules.CallByDetailAndResident.sections.CallCount data={data} />
                        ),
                    },
                    EMERGENCY_CALLS: {
                        type: 'EMERGENCY_CALLS',
                        label: 'Emergency Calls',
                        summary: ({ count }) => count,
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL_AND_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'EMERGENCY',
                        },
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByDetailAndResident.sections.EmergencyCalls items={items} />
                        ),
                    },
                    NOT_FEELING_WELL_CALLS: {
                        type: 'NOT_FEELING_WELL_CALLS',
                        label: 'Not Feeling Well',
                        summary: ({ count }) => count,
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL_AND_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'NOT_FEELING_WELL',
                        },
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByDetailAndResident.sections.NotFeelingWellCalls items={items} />
                        ),
                    },
                    CARE_CHANGES: {
                        type: 'CARE_CHANGES',
                        label: 'Care Changes',
                        caption: 'Changes in call counts',
                        summary: ({ count }) => count,
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL_AND_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'CARE_CHANGES',
                        },
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByDetailAndResident.sections.CareChanges items={items} />
                        ),
                    },
                },
                details: {
                    ALL: {
                        type: 'ALL',
                        label: 'Loading...',
                        groupings: {
                            EMERGENCY: {
                                type: 'EMERGENCY',
                                label: 'Emergency',
                                overrideDetailsLabel: ({ data }) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.Emergency
                                        items={data}
                                    />
                                ),
                            },
                            NOT_FEELING_WELL: {
                                type: 'NOT_FEELING_WELL',
                                label: 'Not Feeling Well',
                                overrideDetailsLabel: ({ data }) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.NotFeelingWell
                                        items={data}
                                    />
                                ),
                            },
                            ALL_DETAIL: {
                                type: 'ALL_DETAIL',
                                label: 'All',
                                overrideDetailsLabel: ({ data }) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.AllDetail
                                        items={data}
                                    />
                                ),
                            },
                            TOP_10: {
                                type: 'TOP_10',
                                label: 'Top 10',
                                overrideDetailsLabel: 'Top 10 highest call counts',
                                overrideDetailsCaption: ({ data: { totalCount } }) =>
                                    `These Residents called a total of ${totalCount} times`,
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.Top10
                                        items={data.items}
                                    />
                                ),
                            },
                            CARE_CHANGES: {
                                type: 'CARE_CHANGES',
                                label: 'Care Changes',
                                overrideDetailsLabel: 'Care Changes',
                                overrideDetailsCaption: 'Change in call count',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.CareChanges
                                        items={data}
                                    />
                                ),
                            },
                            ALL_RESIDENT: {
                                type: 'ALL_RESIDENT',
                                label: 'All Residents',
                                overrideDetailsLabel: 'All Residents',
                                overrideDetailsCaption: 'Sorted by call count',
                                renderData: ({ data }) => (
                                    <levels.Branch.modules.CallByDetailAndResident.details.All.groupings.AllResidents
                                        items={data}
                                    />
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
    REGION: {
        level: 'REGION',
        modules: {
            RISK_AND_COMPLIANCE: {
                type: 'RISK_AND_COMPLIANCE',
                label: 'Risk and Compliance',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        label: 'Avg. Response Time',
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ responseTimeMs }) => formatMsToMinutes(responseTimeMs),
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'AVG_RESPONSE_TIME',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.AvgResponseTime items={items} />
                        ),
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'LONGEST_RESPONSE_TIME',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Region.modules.RiskAndCompliance.sections.LongestResponseTime items={items} />
                        ),
                    },
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        label: 'Call Count',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'TOTAL_CALLS',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Region.modules.RiskAndCompliance.sections.TotalCalls items={items} />
                        ),
                    },
                    INCIDENTS: {
                        type: 'INCIDENTS',
                        label: 'Incidents',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'INCIDENTS',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (data) => (
                            <levels.Region.modules.RiskAndCompliance.sections.Incidents data={data} />
                        ),
                    },
                    MEDICATION: {
                        type: 'MEDICATION',
                        label: 'Medication',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'MEDICATION',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ count }) => count,
                        renderData: (data) => (
                            <levels.Region.modules.RiskAndCompliance.sections.Medication data={data} />
                        ),
                    },
                    TRANSFERS: {
                        type: 'TRANSFERS',
                        label: 'Transfers',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'TRANSFERS',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ count }) => count,
                        renderData: (data) => (
                            <levels.Region.modules.RiskAndCompliance.sections.Transfers data={data} />
                        ),
                    },
                    SCHEDULED_DOCUMENTATION_RATE: {
                        type: 'SCHEDULED_DOCUMENTATION_RATE',
                        label: 'Scheduled Documentation Rate',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'DOCUMENTATION_RATE',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Percentage for ${regionName}`,
                        summary: ({ ratePercentage }) => formatPercentage(ratePercentage),
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.ScheduledDocumentationRate
                                items={items}
                            />
                        ),
                    },
                    ECALL_DOCUMENTATION_RATE: {
                        type: 'ECALL_DOCUMENTATION_RATE',
                        label: 'E-call Documentation Rate',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'DOCUMENTATION_RATE',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Percentage for ${regionName}`,
                        summary: ({ ratePercentage }) => formatPercentage(ratePercentage),
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.ECallDocumentationRate items={items} />
                        ),
                    },
                    OVERDUE_ASSESSMENTS: {
                        type: 'OVERDUE_ASSESSMENTS',
                        label: 'Overdue Assessments',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'OVERDUE_ASSESSMENTS_AND_INCOMPLETE_FALLS',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.OverdueAssessments items={items} />
                        ),
                    },
                    FALL_DOCUMENTATION_INCOMPLETE: {
                        type: 'FALL_DOCUMENTATION_INCOMPLETE',
                        label: 'Fall Documentation Incomplete',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'OVERDUE_ASSESSMENTS_AND_INCOMPLETE_FALLS',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.FallDocumentationIncomplete
                                items={items}
                            />
                        ),
                    },
                    POTENTIAL_UNRECORDED_FALLS: {
                        type: 'POTENTIAL_UNRECORDED_FALLS',
                        label: 'Potential Unrecorded Falls',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'RISK_AND_COMPLIANCE',
                            detailsType: 'POTENTIAL_UNRECORDED_FALLS',
                            detailsGroupingType: 'ALL',
                        },
                        summary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Region.modules.RiskAndCompliance.sections.PotentialUnrecordedFalls items={items} />
                        ),
                    },
                },
                details: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        label: 'Avg. Response Time',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.AvgResponseTime.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.LongestResponseTime.groupings.All
                                        items={data}
                                    />
                                ),
                            },
                        },
                    },
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        label: 'Call Count',
                        caption: 'Compared to previous period',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.TotalCalls.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    INCIDENTS: {
                        type: 'INCIDENTS',
                        label: 'Incidents',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.Incidents.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    MEDICATION: {
                        type: 'MEDICATION',
                        label: 'Medication',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.Medication.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    TRANSFERS: {
                        type: 'TRANSFERS',
                        label: 'Transfers',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.Transfers.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    DOCUMENTATION_RATE: {
                        type: 'DOCUMENTATION_RATE',
                        label: 'Documentation Rate',
                        caption: 'The information here is a comparison of the last two weeks',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.DocumentationRate.groupings.All
                                        items={data}
                                    />
                                ),
                            },
                        },
                    },
                    OVERDUE_ASSESSMENTS_AND_INCOMPLETE_FALLS: {
                        type: 'OVERDUE_ASSESSMENTS_AND_INCOMPLETE_FALLS',
                        label: 'Overdue Assessments & Fall Documentation Incomplete',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.OverdueAssessmentsAndIncompleteFalls.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    POTENTIAL_UNRECORDED_FALLS: {
                        type: 'POTENTIAL_UNRECORDED_FALLS',
                        label: 'Potential Unrecorded Falls',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.RiskAndCompliance.details.PotentialUnrecordedFalls.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                },
            },
            LABOR: {
                type: 'LABOR',
                label: 'Labor',
                sections: {
                    SCHEDULED_VS_BUDGETED_HOURS_BY_REGION: {
                        type: 'SCHEDULED_VS_BUDGETED_HOURS_BY_REGION',
                        label: 'Scheduled vs. Budgeted Hours by Region',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'LABOR',
                            detailsType: 'SCHEDULED_VS_BUDGETED_HOURS',
                            detailsGroupingType: 'ALL',
                        },
                        summary: (_items, { palette }) => (
                            <Typography
                                // eslint-disable-next-line no-constant-condition
                                color={(12707 - 12283 > 0 ? palette.error[700] : palette.success[500]) as string}
                                fontWeight={700}
                            >
                                {`${formatSign(-80, { showSign: 'always', signSeparator: ' ' })} hr`}
                            </Typography>
                        ),
                        renderData: (items) => (
                            <levels.Region.modules.Labor.sections.ScheduledVsBudgetedHoursByRegion items={items} />
                        ),
                    },
                    SCHEDULED_VS_BUDGETED_HOURS_BY_BRANCH: {
                        type: 'SCHEDULED_VS_BUDGETED_HOURS_BY_BRANCH',
                        label: 'Scheduled vs. Budgeted Hours by Community',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'LABOR',
                            detailsType: 'SCHEDULED_VS_BUDGETED_HOURS',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Region.modules.Labor.sections.ScheduledVsBudgetedHoursByBranch items={items} />
                        ),
                    },
                    OVERTIME_AND_AGENCY_SCHEDULED_BY_REGION: {
                        type: 'OVERTIME_AND_AGENCY_SCHEDULED_BY_REGION',
                        label: 'Overtime and Agency Scheduled by Region',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'LABOR',
                            detailsType: 'OVERTIME_AND_AGENCY_SCHEDULED',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: ({ items }) => (
                            <levels.Region.modules.Labor.sections.OvertimeAndAgencyScheduledByRegion items={items} />
                        ),
                    },
                    OVERTIME_AND_AGENCY_SCHEDULED_BY_BRANCH: {
                        type: 'OVERTIME_AND_AGENCY_SCHEDULED_BY_BRANCH',
                        label: 'Overtime and Agency Scheduled by Community',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'LABOR',
                            detailsType: 'OVERTIME_AND_AGENCY_SCHEDULED',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Region.modules.Labor.sections.OvertimeAndAgencyScheduledByBranch items={items} />
                        ),
                    },
                    HIRING_NEEDS: {
                        type: 'HIRING_NEEDS',
                        label: 'Hiring Needs',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'LABOR',
                            detailsType: 'HIRING_NEEDS',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Estimated count for ${regionName}`,
                        summary: ({ hiresNeededCount }) => `${hiresNeededCount} staff`,
                        renderData: ({ items }) => <levels.Region.modules.Labor.sections.HiringNeeds items={items} />,
                    },
                },
                details: {
                    SCHEDULED_VS_BUDGETED_HOURS: {
                        type: 'SCHEDULED_VS_BUDGETED_HOURS',
                        label: 'Scheduled vs. Budgeted Hours',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.Labor.details.ScheduledVsBudgetedHours.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    OVERTIME_AND_AGENCY_SCHEDULED: {
                        type: 'OVERTIME_AND_AGENCY_SCHEDULED',
                        label: 'Overtime and Agency Scheduled',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.Labor.details.OvertimeAndAgencyScheduled.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    HIRING_NEEDS: {
                        type: 'HIRING_NEEDS',
                        label: 'Hiring Needs',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.Labor.details.HiringNeeds.groupings.All data={data} />
                                ),
                            },
                        },
                    },
                },
            },
            CARE_REVENUE: {
                type: 'CARE_REVENUE',
                label: 'Care Revenue',
                sections: {
                    CARE_CHANGES: {
                        type: 'CARE_CHANGES',
                        label: 'Care Changes',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'CARE_REVENUE',
                            detailsType: 'RESIDENT_CHANGES',
                            detailsGroupingType: 'CARE_CHANGES',
                        },
                        caption: ({ regionName }) => `Change in call counts for ${regionName}`,
                        summary: ({ needsChangeCount }) => needsChangeCount,
                        renderData: ({ items }) => (
                            <levels.Region.modules.CareRevenue.sections.CareChanges items={items} />
                        ),
                    },
                    CARE_CHARGE_GAPS: {
                        type: 'CARE_CHARGE_GAPS',
                        label: 'Care Charge Gaps',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'CARE_REVENUE',
                            detailsType: 'RESIDENT_CHANGES',
                            detailsGroupingType: 'CARE_CHARGE_GAPS',
                        },
                        caption: ({ regionName }) => `Heavy Hitters with Low Care Level count for ${regionName}`,
                        summary: ({ potentialIncreaseCount }) => potentialIncreaseCount,
                        renderData: ({ items }) => (
                            <levels.Region.modules.CareRevenue.sections.CareChargeGaps items={items} />
                        ),
                    },
                    ON_DEMAND_CARE: {
                        type: 'ON_DEMAND_CARE',
                        label: 'On Demand Care',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'CARE_REVENUE',
                            detailsType: 'ON_DEMAND_CARE',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Resident count with Assist Level Increase for ${regionName}`,
                        summary: ({ demandCareCount }) => demandCareCount,
                        renderData: ({ items }) => (
                            <levels.Region.modules.CareRevenue.sections.OnDemandCare items={items} />
                        ),
                    },
                    CHANGE_OF_CONDITION: {
                        type: 'CHANGE_OF_CONDITION',
                        label: 'Change of Condition',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'CARE_REVENUE',
                            detailsType: 'CHANGE_OF_CONDITION',
                            detailsGroupingType: 'ALL',
                        },
                        caption: ({ regionName }) => `Count for ${regionName}`,
                        summary: ({ changeOfConditionCount }) => changeOfConditionCount,
                        renderData: ({ items }) => (
                            <levels.Region.modules.CareRevenue.sections.ChangeOfCondition items={items} />
                        ),
                    },
                    DISTRIBUTION_OF_LEVELS: {
                        type: 'DISTRIBUTION_OF_LEVELS',
                        label: 'Distribution of Levels',
                        detailsPayload: {
                            level: 'REGION',
                            moduleType: 'CARE_REVENUE',
                            detailsType: 'DISTRIBUTION_OF_LEVELS',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Region.modules.CareRevenue.sections.DistributionOfLevels items={items} />
                        ),
                    },
                },
                details: {
                    RESIDENT_CHANGES: {
                        type: 'RESIDENT_CHANGES',
                        label: 'Loading...',
                        caption: 'Loading...',
                        groupings: {
                            CARE_CHANGES: {
                                type: 'CARE_CHANGES',
                                label: 'Care Changes',
                                overrideDetailsLabel: 'Care Changes',
                                overrideDetailsCaption: 'This does not include on hospice residents',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.CareRevenue.details.ResidentChanges.groupings.CareChanges
                                        data={data}
                                    />
                                ),
                            },
                            CARE_CHARGE_GAPS: {
                                type: 'CARE_CHARGE_GAPS',
                                label: 'Residents with Care Charge Gap',
                                overrideDetailsLabel: 'Residents with Care Charge Gap',
                                overrideDetailsCaption: 'Heavy Hitters with Low Care Level',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.CareRevenue.details.ResidentChanges.groupings.CareChargeGaps
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    ON_DEMAND_CARE: {
                        type: 'ON_DEMAND_CARE',
                        label: 'On Demand Care',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.CareRevenue.details.OnDemandCare.groupings.All data={data} />
                                ),
                            },
                        },
                    },
                    CHANGE_OF_CONDITION: {
                        type: 'CHANGE_OF_CONDITION',
                        label: 'Change of Condition',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.CareRevenue.details.ChangeOfCondition.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                    DISTRIBUTION_OF_LEVELS: {
                        type: 'DISTRIBUTION_OF_LEVELS',
                        label: 'Distribution of Levels',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: ({ data }) => (
                                    <levels.Region.modules.CareRevenue.details.DistributionOfLevels.groupings.All
                                        data={data}
                                    />
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
    COMPANY: {
        level: 'COMPANY',
        modules: {},
    },
};
