import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { OperationsOverall } from '~/types/operationsV2/operationsOverall.d';

export const useGetOperationsOverall = (params: OperationsOverall.Params) => {
    const token = useToken();

    const validateParams =
        params.level === 'REGION'
            ? !!params.regionId
            : params.level === 'BRANCH'
              ? !!params.branchId
              : !!params.companyId;

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params,
        };

        const { data } = await api.get<OperationsOverall.Response>('/analytics/overall', options);

        return data.response;
    }, [token, params]);

    return useErrorHandledQuery({
        queryKey: ['operations-overall-stats', params],
        queryFn,
        enabled: !!params && validateParams,
        staleTime: 300000,
    });
};
