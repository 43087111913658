import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '~/api/common';
import {
    CreateCreateShiftRatingResponse,
    CreateShiftRatingDataType,
    CreateShiftRatingMutationType,
    ShiftRatingParams,
    ShiftRatingResponse,
} from '~/types/shiftRating';

export const useCreateShiftRatingMutation = ({ userId, shiftId, shiftDayDate }: CreateShiftRatingMutationType) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (shiftRatingData: CreateShiftRatingDataType) => {
            const options = { headers: { Authorization: token }, params: { branchId } };

            const { data } = await api.post<CreateCreateShiftRatingResponse>(
                `/shift-rating`,
                { ...shiftRatingData, userId, shiftId, shiftDayDate },
                options
            );

            return data.response;
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({
            queryKey: ['shift-rating', userId, shiftId, shiftDayDate],
            exact: true,
        });
    }, [userId, shiftId, shiftDayDate]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};

export const useCanCreateShiftRatingQuery = (
    { userId, shiftId, shiftDayDate, inTime }: ShiftRatingParams,
    enabled: boolean
) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                branchId,
                userId,
                shiftId,
                shiftDayDate,
            },
        };

        const { data } = await api.get<{ response: ShiftRatingResponse }>('/shift-rating', options);

        return data.response;
    }, [userId, shiftId, shiftDayDate, token]);

    return useErrorHandledQuery({
        queryKey: ['shift-rating', userId, shiftId, shiftDayDate],
        queryFn,
        enabled: enabled && inTime && !!userId && !!shiftId && !!shiftDayDate && !!branchId,
    });
};
