import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ShiftOption, getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchId } from '~/api/common';
import { useBranchShifts } from '~/api/queries/branch';
import { useCanCreateShiftRatingQuery } from '~/api/queries/shiftRating/shiftRating';
import { SHIFT_RATING_DIALOG_CLOSED } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

import { isShiftRatingDialogOpenAtom } from '../state/atom';

function verifyInTimeToAddShiftRating(timezone: string, shifts: ShiftOption[]) {
    const { shift, shiftDayDate } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const shiftStart = DateTime.fromISO(shift.startTimeInclusive);
    const shiftEnd = DateTime.fromISO(shift.endTimeExclusive);
    const now = DateTime.now();

    // User can rate in the last 15 minutes of the shift
    const isWithinLast15MinutesOfShift = now >= shiftEnd.minus({ minutes: 15 }) && now <= shiftEnd;
    if (isWithinLast15MinutesOfShift) {
        return { inTime: true, shiftToRate: shift, shiftDayDate };
    }

    // User can rate up to 10 minutes after the shift ends
    const isWithinFirst10MinutesOfShift = now <= shiftStart.plus({ minutes: 10 });
    if (isWithinFirst10MinutesOfShift) {
        // Get the previous shift
        const [previousShift] = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);
        const shiftToRate = previousShift.shift;
        const shiftToRateDayDate = previousShift.shiftDayDate;
        return { inTime: true, shiftToRate, shiftDayDate: shiftToRateDayDate };
    }

    return { inTime: false, shiftToRate: shift, shiftDayDate };
}

const verifyHasDialogBeenClosed = (userId: number, shiftId: number, shiftDayDate: string) => {
    const storedDialogData = JSON.parse((localStorage.getItem(SHIFT_RATING_DIALOG_CLOSED) as string) ?? '{}') as {
        userId: number;
        shiftId: number;
        shiftDayDate: string;
    };

    return (
        storedDialogData.userId === userId &&
        storedDialogData.shiftId === shiftId &&
        storedDialogData.shiftDayDate === shiftDayDate
    );
};

export default function useShiftRatingFlow() {
    const posthog = usePostHog();
    const flagEnabled = useFeatureFlagEnabled('shift-rating');
    const [isShiftRatingDialogOpen, setIsShiftRatingDialogOpen] = useAtom(isShiftRatingDialogOpenAtom);
    const hasShownDialog = useRef(false);

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { timezone } = useSelector((state: ReduxStore) => state.session);
    const branchId = useBranchId();
    const shifts = useBranchShifts(branchId);
    const { inTime, shiftToRate, shiftDayDate } = verifyInTimeToAddShiftRating(timezone, shifts);

    const hasDialogBeenClosed = verifyHasDialogBeenClosed(userId, Number(shiftToRate.id), shiftDayDate);

    const { data: shiftRatingQueryData } = useCanCreateShiftRatingQuery(
        { userId, shiftId: Number(shiftToRate.id), shiftDayDate, inTime },
        !!flagEnabled && !hasDialogBeenClosed
    );

    useEffect(() => {
        if (
            shiftRatingQueryData?.canCreateShiftRating &&
            !isShiftRatingDialogOpen &&
            !hasShownDialog.current &&
            !hasDialogBeenClosed
        ) {
            setIsShiftRatingDialogOpen(true);
            hasShownDialog.current = true;
        }
    }, [shiftRatingQueryData, isShiftRatingDialogOpen, hasDialogBeenClosed]);

    const handleShiftRatingDialogClose = () => {
        setIsShiftRatingDialogOpen(false);
        const closeDialogData = { userId, shiftId: Number(shiftToRate.id), shiftDayDate };
        posthog.capture('shift_rating_dialog:close', closeDialogData);
        localStorage.setItem(SHIFT_RATING_DIALOG_CLOSED, JSON.stringify(closeDialogData));
    };

    const handleShiftRatingDialogComplete = () => {
        setIsShiftRatingDialogOpen(false);
    };

    return {
        handleShiftRatingDialogClose,
        handleShiftRatingDialogComplete,
        isEnabled: flagEnabled ?? false,
        isShiftRatingDialogOpen,
    };
}
