import { Divider, Stack, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import SeeMoreButton from '~/pages/OperationsV2/components/Module/SeeMore';
import ShowMoreButton from '~/pages/OperationsV2/components/Module/ShowMore';

import { FallNoteBottomSheet } from './FallNoteBottomSheet';

type Data = DetailsGroupingDataType<
    'REGION',
    'RISK_AND_COMPLIANCE',
    'POTENTIAL_UNRECORDED_FALLS',
    'ALL'
>['communityItems'][number];

const DataContainer = styled(Stack)(({ theme }) => ({
    borderRadius: '16px',
    backgroundColor: theme.palette.secondary[100],
    padding: '24px',
    gap: '16px',
}));

const NoteContainer = styled(Stack)(({ theme }) => ({
    borderRadius: '12px',
    backgroundColor: theme.palette.secondary[500],
    padding: '24px',
    gap: '12px',
}));

type FallNote = Data['details'][number]['fallRelatedNotes'][number];

interface FallNotesProps {
    data: FallNote;
    onSeeMoreClick: (note: FallNote) => void;
}

const FallNotes = ({ data, onSeeMoreClick }: FallNotesProps) => {
    const handleClick = () => {
        onSeeMoreClick(data);
    };

    return (
        <NoteContainer>
            <Stack direction="row" justifyContent="space-between">
                {/* Dec 28, 2024 (Mon) */}
                <Typography variant="caption" sx={({ palette }) => ({ color: palette.grey[700] })}>
                    {DateTime.fromISO(data.timestamp).toFormat('MMM d, yyyy (ccc)')}
                </Typography>
                {/* 3:15 am */}
                <Typography variant="caption" sx={({ palette }) => ({ color: palette.grey[700] })}>
                    {DateTime.fromISO(data.timestamp).toFormat('h:mm a')}
                </Typography>
            </Stack>
            <Typography
                variant="caption"
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                }}
            >
                {data.note}
            </Typography>
            <SeeMoreButton onClick={handleClick} />
        </NoteContainer>
    );
};

type ResidentData = Data['details'][number];

interface ResidentDataProps {
    data: ResidentData;
    selectedItem: number | null;
    itemIndex: number;
    onMoreDetailsClick: (itemIndex: number) => void;
    onSeeMoreClick: (note: { note: FallNote; residentData: ResidentData }) => void;
}

const ResidentData = ({ data, selectedItem, itemIndex, onMoreDetailsClick, onSeeMoreClick }: ResidentDataProps) => {
    const isShowingMore = selectedItem === itemIndex;

    const handleSeeMoreClick = (note: FallNote) => {
        onSeeMoreClick({ residentData: data, note });
    };

    const renderCollapsedVersion = () => {
        return (
            <DataContainer>
                <Stack direction="row" justifyContent="space-between" marginBottom="8px">
                    <Typography variant="body1" fontWeight={700}>
                        {data.residentName}
                    </Typography>
                    <ShowMoreButton
                        title={isShowingMore ? 'Less details' : 'More details'}
                        onClick={() => onMoreDetailsClick(itemIndex)}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">EHR fall count</Typography>
                    <Typography variant="body2">{data.ehrFallCount}</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">Fall related notes</Typography>
                    <Typography variant="body2">{data.fallRelatedNotesCount}</Typography>
                </Stack>
            </DataContainer>
        );
    };

    const renderExpandedVersion = () => {
        return (
            <DataContainer sx={{ gap: '24px' }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" fontWeight={700}>
                        {data.residentName}
                    </Typography>
                    <ShowMoreButton
                        title={isShowingMore ? 'Less details' : 'More details'}
                        onClick={() => onMoreDetailsClick(itemIndex)}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">EHR fall count</Typography>
                    <Typography variant="body2">{data.ehrFallCount}</Typography>
                </Stack>
                <Stack gap="12px">
                    {data.ehrFallTimestamps.map((timestamp, index) => (
                        <React.Fragment key={index}>
                            <Typography variant="caption">
                                {/* Jan 12(Mon), 12:13 AM */}
                                {DateTime.fromISO(timestamp).toFormat('MMM d(ccc), h:mm a')}
                            </Typography>
                            {index !== data.ehrFallTimestamps.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </Stack>
                <Divider color="gray.900" />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">Fall related notes</Typography>
                    <Typography variant="body2">{data.fallRelatedNotesCount}</Typography>
                </Stack>
                <Stack gap="8px">
                    {data.fallRelatedNotes.map((note, index) => (
                        <FallNotes key={index} data={note} onSeeMoreClick={handleSeeMoreClick} />
                    ))}
                </Stack>
            </DataContainer>
        );
    };

    return isShowingMore ? renderExpandedVersion() : renderCollapsedVersion();
};

export const CommunityData = ({ data }: { data: Data }) => {
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    const [isFallNoteBottomSheetOpen, setIsFallNoteBottomSheetOpen] = useState(false);
    const [selectedFallNoteData, setSelectedFallNoteData] = useState<{
        note: FallNote;
        residentData: ResidentData;
    } | null>(null);

    const handleSeeMoreClick = (note: { note: FallNote; residentData: ResidentData }) => {
        setSelectedFallNoteData(note);
        setIsFallNoteBottomSheetOpen(true);
    };

    const handleCloseFallNoteBottomSheet = () => {
        setIsFallNoteBottomSheetOpen(false);
        setSelectedFallNoteData(null);
    };

    return (
        <Stack gap="12px" marginTop="24px">
            {data.details.map((detail, index) => {
                return (
                    <ResidentData
                        key={index}
                        data={detail}
                        selectedItem={selectedItem}
                        itemIndex={index}
                        onMoreDetailsClick={(itemIndex) =>
                            setSelectedItem(selectedItem === itemIndex ? null : itemIndex)
                        }
                        onSeeMoreClick={handleSeeMoreClick}
                    />
                );
            })}
            <FallNoteBottomSheet
                isOpen={isFallNoteBottomSheetOpen}
                onClose={handleCloseFallNoteBottomSheet}
                title={selectedFallNoteData?.residentData.residentName ?? ''}
                caption={data.communityName}
                note={selectedFallNoteData?.note ?? null}
            />
        </Stack>
    );
};
