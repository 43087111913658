import { Divider, Stack, Typography } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { CustomBottomSheet } from '~/components/Custom/CustomBottomSheet';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'LONGEST_RESPONSE_TIME', 'ALL'>[number];

const NotesDetails = ({ item }: { item: Item | null }) => {
    if (!item) {
        return null;
    }

    return (
        <>
            <Stack gap="12px">
                <Typography variant="body1" lineHeight="12px">
                    {item.residentName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    {item.staffName}
                </Typography>
                <Typography variant="body1" lineHeight="12px">
                    Response Time: {formatMsToMinutes(item.responseTimeInMs)}
                </Typography>
            </Stack>
            {/* TODO: implement option to add notes */}
            {item.managerNotes && (
                <>
                    <Divider />
                    <Stack gap="12px">
                        <Typography variant="body2" lineHeight="12px">
                            {DateTime.fromISO(item.managerNotes.allNotes[0].timestamp).toFormat('h:mm a, MMM d')}
                        </Typography>
                        <Typography variant="body1">{item.managerNotes.allNotes[0].text}</Typography>
                    </Stack>
                </>
            )}
        </>
    );
};

const Data = ({ items }: { items: Item[] }) => {
    const [isNotesBottomSheetOpen, setIsNotesBottomSheetOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);

    const toggleNotesBottomSheet = (row: Item | null) => {
        setSelectedItem(row);
        setIsNotesBottomSheetOpen(!isNotesBottomSheetOpen);
    };

    return (
        <>
            <DataContainer>
                <DataTable
                    columns={{
                        responseTimeInMs: { label: 'Response Time', renderCell: (value) => formatMsToMinutes(value) },
                        residentName: {
                            label: 'Resident',
                            renderCell: (value, { row }) => (
                                <Stack gap="2px">
                                    <Typography>{value}</Typography>
                                    <Typography variant="caption">{row.branchName}</Typography>
                                </Stack>
                            ),
                        },
                        staffName: { label: 'Staff' },
                        timestamp: {
                            label: 'Timestamp',
                            renderCell: (value) => DateTime.fromISO(value).toFormat('h:mm a, MMM d'),
                        },
                        managerNotes: {
                            label: 'Manager Notes',
                            allowSort: false,
                            renderCell: (value, { row }) =>
                                value?.allNotes?.length ? (
                                    <Notes
                                        notes={value.allNotes.map((note) => note.text).join('\n')}
                                        maxWidth="200px"
                                        onSeeMoreClick={() => toggleNotesBottomSheet(row)}
                                    />
                                ) : (
                                    <AddNotesButton onAddNote={() => toggleNotesBottomSheet(row)} />
                                ),
                        },
                    }}
                    rows={items}
                />
            </DataContainer>
            <CustomBottomSheet
                isOpen={isNotesBottomSheetOpen}
                onClose={() => toggleNotesBottomSheet(null)}
                title="Manager Notes"
                Icon={Note}
            >
                <NotesDetails item={selectedItem} />
            </CustomBottomSheet>
        </>
    );
};

export default Data;
